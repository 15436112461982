import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import { useSelectDate } from '../../../hooks/useSelectDate';
import DateSelector from '../../../components/mco/components/DateSelector';
import SimpleCards from './reports-components/SimpleCards';
import SimpleTable from './reports-components/SimpleTable';

const SimpleReports = () => {

  const [
    currentMonth,
    handleCurrentMonth,
    selectValues
  ] = useSelectDate();

  return (
    <React.Fragment>
    <Helmet title="Reportes SIMPLE" />
    <Container fluid className="p-0 mco-fade-in">
      <Row className="d-flex justify-content-between">
        <Col xs="auto" className="d-none d-sm-block fs-1">
          <h3>
             Reporte SIMPLE
          </h3>
        </Col>
        <DateSelector
          selectValues={selectValues}
          handleCurrentMonth={handleCurrentMonth}
        />
      </Row>
      <Row>
        <SimpleCards period={currentMonth} />
      </Row>
      <Row>
        <SimpleTable period={currentMonth} />
      </Row>
    </Container>
    </React.Fragment>
  )
}

export default SimpleReports