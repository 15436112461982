import { Fragment, useCallback, useEffect, useState } from 'react';
import DateSelector from '../../../../components/mco/components/DateSelector';
import { useSelectDate } from '../../../../hooks/useSelectDate';
import { Helmet } from "react-helmet-async";
import {
  Container,
  Col,
  Row,
} from "react-bootstrap";
import { MCO_CONFIGS } from "../../../../constants";
import moment from "moment/min/moment-with-locales";
import McoPaginatedTable from '../../../../components/mco/components/McoPaginatedTable';
import useReportBusiness from '../../../../hooks/useReportBusiness';
import { useSelector } from 'react-redux';

const BusinessReportsTable = () => {
	//default sort
	const [ sort  , setSort] = useState( { accessor:null , direction:null} );

	const { show } = useSelector(state => state.reports)

	const { locale } = MCO_CONFIGS;

	const [
		currentMonth,
		handleCurrentMonth,
		selectValues
	] = useSelectDate();

  	const  {
		getReport,
		loading,
		pageCount,
		businessData,
		dataProcessed,
		exportLoading,
	 	getAllBusinessReport
	} = useReportBusiness();
	 
	useEffect(() => {
		getAllBusinessReport(currentMonth);
	}, [ currentMonth ]);

	const fetchData = useCallback( ({ pageIndex, state })  => {
		
 		getReport({
			orderId: state.type === "Orden ID" ? state.values : undefined,
			sku: state.type === "Sku" ? state.values : undefined,
			distId: state.type === "Dist ID" ? state.values : undefined,
			productName: state.type === "Producto" ? state.values : undefined,
			corporateName: state.type === "Corporativo" ? state.values : undefined,
			date: currentMonth,
			limit:show,
			currentPage: pageIndex+1,
			type: state.type === "Tipo" ? state.values : undefined,
			orderBy: sort.accessor,
			sortDirection: sort.direction,
		}) 

	}, [sort, currentMonth, show] );

	 
	
	  
	const columnHeaderClick = (column) => {

		switch (column.sortDirection) {
		case 'none':
			setSort( {accessor: column.id, direction: 'ASC'} );
			break;
		case 'ASC':
			setSort( {accessor: column.id, direction: 'DESC'} );
			break;
		case 'DESC':
			setSort( {accessor: column.id, direction: 'ASC'} );        
			break;
		default:
		}
	};

  const businessReportsColumns = [
		{
			Header: "Fecha",
			accessor: "order_date",
			width:250,
			className: "text-center",
			headerClassName:"text-center",
			sortType: 'basic',
			sortDirection: sort.accessor === 'order_date' ? sort.direction : 'none',
			Cell: (props) => {
				return props.value? moment(props.value).format("DD/MM/YYYY") : '-';
			}
		},
		{
			Header: "Orden ID",
			accessor: "order_id",
			width:250,
			className: "text-center",
			headerClassName:"text-center",
			sortType: 'basic',
			sortDirection: sort.accessor === 'order_id' ? sort.direction : 'none',
		},
		{
			Header: "Distribuidor",
			accessor: "dist_name",
			className: "col-1",
			headerClassName:"col-1",
		},		
		{
			Header: "Dist ID",
			accessor: "dist_id",
			className: "text-center",
			headerClassName:"text-center",
			enableColumnFilter:true,
			Cell: (props) => { 
				return props.value 
			},
		},
		{
			Header: "Sku",
			accessor: "sku",
			className: "text-center",
			headerClassName: "text-center",
			sortType: 'basic',
			sortDirection: sort.accessor === 'sku' ? sort.direction : 'none',
		},
		{
			Header: "Producto",
			accessor: "product_name",
			headerClassName: "col-2",
			sortType: 'basic',
			sortDirection: sort.accessor === 'product_name' ? sort.direction : 'none'
		},
		{
			Header: "Cant",
			Filter: false,
			accessor: "quantity",
			with: 295,
			className: "text-right",
			headerClassName: "text-center py-4",
			sortType: 'basic',
			sortDirection: sort.accessor === 'quantity' ? sort.direction : 'none'
		},
		{
			Header: "Pcio. Lista",
			Filter: false,
			accessor: "unit_price_list",
			className: "text-right",
			headerClassName: "text-center py-4",
			sortType: 'basic',
			sortDirection: sort.accessor === 'unit_price_list' ? sort.direction : 'none',
			Cell: (props) => {
				return "$" + ((props.value)? parseFloat( props.value ).toLocaleString(locale, { maximumFractionDigits: 1, minimumFractionDigits: 1 }) : 0 );
			}
		},	 
		{
			Header: "Pcio Desc",
			Filter: false,
			accessor: "unit_discount_price",
			className: "text-right",
			headerClassName:"text-center py-4",
			sortType: 'basic',
			sortDirection: sort.accessor === 'unit_discount_price' ? sort.direction : 'none',
			Cell: (props) => {
				return "$" + ((props.value)? parseFloat( props.value ).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0 );
			}
		},
		{
			Header: "Subtotal",
			Filter:false,
			accessor:"subtotal_discounts_price",
			className: "text-right",
			headerClassName:"text-center py-4",
			sortType: 'basic',
			sortDirection: sort.accessor === 'subtotal_discounts_price' ? sort.direction : 'none',
			Cell: (props) => {
				return "$" + ((props.value) ? parseFloat( props.value ).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0 );
			}
		},		 
		{
			Header: "Corporativo",
			accessor: "corporate_name",
			className: "text-left",
			headerClassName:"text-center py-4",
			sortDirection: sort.accessor === 'corporate_name' ? sort.direction : 'none',
			Cell: (props) => {
				return (props.value?  props.value : " - ") ;
			}
		},
		{
			Header: "Desc. Corporativo",
			Filter:false,
			accessor:"corporate_discount",
			className: "text-right",
			headerClassName:"text-center py-4",
			sortType: 'basic',
			sortDirection: sort.accessor === 'corporate_discount' ? sort.direction : 'none',
			Cell: (props) => {
				return "$" + ((props.value) ? parseFloat( props.value ).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0 );
			}
		},	
		{
			Header: "Otros Desc",
			Filter: false,
			accessor: "subtotal_other_discounts",
			className: "text-right",
			headerClassName:"text-right py-4",
			sortType: 'basic',
			sortDirection: sort.accessor === 'subtotal_other_discounts' ? sort.direction : 'none',
			Cell: (props) => {
				return "$" + ((props.value)? parseFloat( props.value ).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0 );
			}
		},		 
		{
			Header: "Total Desc",
			Filter: false,
			accessor: "total_discounts",
			className: "text-right",
			headerClassName:"text-right py-4",
			sortType: 'basic',
			sortDirection: sort.accessor === 'total_discounts' ? sort.direction : 'none',
			Cell: (props) => {
				return "$" + ((props.value)? parseFloat( props.value ).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0 );
			}
		},		 
		{
			Header: "Total Neto",
			Filter: false,
			accessor: "net_total",
			className: "text-right",
			headerClassName:"text-center py-4",
			sortType: 'basic',
			sortDirection: sort.accessor === 'net_total' ? sort.direction : 'none',
			Cell: (props) => {
				return "$" + ((props.value)? parseFloat( props.value ).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0 );
			}
		},  
		{
			Header: "Tipo",
			accessor:"type",
			sortType: 'basic', 
			sortDirection: sort.accessor === 'type' ? sort.direction : 'none',
			className: "text-center",
			headerClassName:"text-center",
		}	
	];

  return (
    <Fragment>
      <Helmet title="Reporte Comercial" />
		<Container fluid className="p-0" >
			<Row className="d-flex justify-content-between">
				<Col xs="auto" className="d-none d-sm-block fs-1">
          <h3>
             Reporte Comercial
          </h3>
       	</Col>
		<DateSelector 
          selectValues={selectValues}
          handleCurrentMonth={handleCurrentMonth}
        />
			</Row>
				<Row className="mb-4 mco-fade-in">
					<McoPaginatedTable 
						columns={businessReportsColumns}
						dataForExport={dataProcessed}
						pageCount={pageCount}
						fetchData={fetchData}
						data={businessData}
						customPageSize={25}
						onHeaderClick={columnHeaderClick}
						loading={loading}
						title={`Descuentos de ${moment(currentMonth).locale("es").format("MMMM YY")}`}
						fileName={"reporte_comercial"}
						exportLoading={exportLoading}
					/>
				</Row>
		</Container>
    
    </Fragment>
  )
}

export default BusinessReportsTable;