import { createContext, useEffect, Fragment } from "react";

import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";
import { Container, Col, Row } from "react-bootstrap";

import { MCO_CONFIGS } from "../../../../constants";
import useReports from "../../../../hooks/useReports";
import { useBenefits } from "../../../../hooks/useBenefits";
import { useSelectDate } from "../../../../hooks/useSelectDate";
import DateSelector from "../../../../components/mco/components/DateSelector";


export const BenefitsContext = createContext({});
const { Provider } = BenefitsContext;

const locations = [
  {
    name: 'Corporativos',
    path: '/benefits/corporatives'
  },
  {
    name: 'Masivos',
    path: '/benefits/massives'
  },
  {
    name: 'Bancarios',
    path: '/benefits/banking-benefits'
  },
  {
    name: 'Resumen',
    path: '/benefits/overview'
  },
  {
    name: 'Detalles',
    path: '/benefits/details'
  }
];

export const BenefitsLayout = ({children}) => {

  const { locale } = MCO_CONFIGS;

  const { pathname } = useLocation();

  const { subGroup }= useParams();


  const [
    currentMonth,
		handleCurrentMonth,
		selectValues
  ] = useSelectDate();
 
  const {
    loading,
    getCoupons,
    couponsData,
    exportableCoupons,
  } = useReports();

  
  const {
    loading: isLoading,
    groups,
    subGroups,
    prevGroups,
    groupDistributors,
    couponsGroupDistris,
    reportCouponsGrouped,
    reportCouponsSubGrouped,
  } = useBenefits();


  return (
     <Provider value={{
      locale,
      groups,
      isLoading,
      loading,
      subGroups,
      prevGroups,
      couponsData,
      currentMonth,
      groupDistributors,
      exportableCoupons,
      getCoupons,
      couponsGroupDistris,
      reportCouponsGrouped,
      reportCouponsSubGrouped,
    }}>
       <Fragment>
        <Helmet title="Cupones"/>
          <Row className="d-flex justify-content-between">
            <Col xs="auto" className="d-none d-sm-block  ">
                {
                  subGroup ?
                  (
                    <>
                      {
                        pathname.split('/')[2] === 'massives'?
                        ( <h3>Detalle Beneficio  Masivo</h3> ):
                        ( <h3>Detalle Beneficio  Bancario</h3> )
                      }
                    </>
                  ):(
                    <h3>
                      Beneficios {'  '}
                      {
                        locations.map( e => (
                          e.path === pathname &&  e.name 
                        ))
                      }
                    </h3>
                  )
                }
            </Col>
            {
              !subGroup &&
              <DateSelector 
                selectValues={selectValues}
                handleCurrentMonth={handleCurrentMonth}
              />
            }
          </Row>
          <Container fluid className="p-0 mco-fade-in">
            <Row>
              <Col lg="12">
                { children }
              </Col>
            </Row>
          </Container>
      </Fragment>
    </Provider>
  )
}
