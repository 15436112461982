import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";

function SignIn() {

  const { signIn } = useAuth();

  return (
    <Formik
      initialValues={{
        email: "", // "demo@bootlab.io"
        password: "", // "unsafepassword"
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Debe ingresar un correo electrónico válido")
          .max(255)
          .required("Correo electrónico requerido"),
        password: Yup.string().max(255).required("Contraseña requerida"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn(values.email, values.password);

        } catch (error) {
          let message = error.message || "Algo está mal!";
          message = ( message === "Unauthorized" )? "¡Usuario o clave incorrectos!" : message;
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}

          <Form.Group className="mb-3 mt-5">
            <Form.Label>Correo electrónico</Form.Label>
            <Form.Control
              size="lg"
              type="email"
              name="email"
              placeholder="Ingrese su correo electrónico"
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              size="lg"
              type="password"
              name="password"
              placeholder="Ingrese su contraseña"
              value={values.password}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            )}
            <small>
              {/*<Link to="/auth/reset-password">¿Olvidó su Contraseña?</Link>*/}
            </small>
          </Form.Group>

          <div>
            <Form.Check
              type="checkbox"
              id="rememberMe"
              label="Recordarme"
              defaultChecked
            />
          </div>

          <div className="text-center mt-3">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              Iniciar Sesión
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SignIn;
