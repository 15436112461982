import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";

import SignIn from "../../components/auth/SignIn";
//import avatar from "../../assets/img/avatars/avatar.jpg";
import avatar from "../../assets/img/brands/logo-aec.png";

const SignInPage = () => (
  <React.Fragment>
    <Helmet title="Iniciar Sesión" />
    <div className="text-center mt-4">
      <h2>¡Bienvenid@!</h2>
      <p className="lead">Iniciar sesión para continuar</p>
    </div>

    <Card>
      <Card.Body>
        <div className="m-sm-4">
          <div className="text-center">
            <img
              src={avatar}
              alt="Iniciar Sesión"
              className="img-fluid"
              width="132"
              height="132"
            />
          </div>
          <SignIn />
        </div>
      </Card.Body>
    </Card>
  </React.Fragment>
);

export default SignInPage;
