import { Fragment, useContext } from 'react'
import { Row, Col, Card } from "react-bootstrap";
import { useSegments } from '../../../hooks/useSegments';
import { SegmentContext } from './layouts/SegmentLayout';
import SegmentsUnitsBarChart from './charts/SegmentsUnitsBarChart';
import { SegmentsAmountBarChart }  from './charts/SegmentsAmountBarChart';
import { SuspensivePointsLoader } from '../../../components/mco/components/McoLoaders';

export const SegmentsOverview = () => {

const { currentMonth } = useContext(SegmentContext);
const { bySegment, canvasHeight, loading } = useSegments(currentMonth);

  return (

    <Fragment>
      {
        loading ?
        (
          <SuspensivePointsLoader title="de segmentos" />
        ):(
          bySegment.length > 0 ?
            (
              <Card>
                <Card.Body className='mt-3'>
                  <Row className='d-flex justify-content-start' >
                    <Col lg={6} className="zindex-40" >
                      <SegmentsAmountBarChart
                        bySegmentsAmount={bySegment}
                        loading={loading }
                        canvasHeight={canvasHeight}
                        
                      />
                    </Col>
                    <Col lg={6}>
                      <SegmentsUnitsBarChart
                        bySegmentsUnits={bySegment}
                        loading={ loading }
                        canvasHeight={canvasHeight}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>):(
              <Card.Body className="d-flex align-items-center mb-2 me-2">
                <div className="d-flex mco-fade-in justify-content-center align-items-center mx-auto w-75 mb-2">
                  <div className="py-3 px-4 badge-soft-danger rounded-lg  badge">
                    <span className="fs-4">
                        No hay datos para mostrar
                    </span>
                  </div>
                </div>
              </Card.Body>
            ) 
        )
      }
    </Fragment>
  )
}

