import moment from "moment/min/moment-with-locales";
import { useState, useEffect } from 'react'
import McoToken from '../components/McoToken'
import { OpenAPI, OrdersService } from '../services/openapi'
import usePalette from './usePalette';
import {
  faCandyCane,
  faTh,
  faQuestion,
  faCookieBite,
  faBoxes,
  faLeaf,
  faMortarPestle
} from "@fortawesome/free-solid-svg-icons";
import { getPercent } from "../components/mco/helpers/getPercent";


const { ordersByBusiness } = OrdersService;

const useBusiness = (date) => {

  const [ byPrevBusiness, setByPrevBusiness ] = useState([]);
  const [ byBusiness, setByBusiness ] = useState([]);
  const [ totals, setTotals ] = useState({});
  const [ loading, setLoading ] = useState(true);
  const [ prevMonth, setPrevMonth ] = useState();
  const palette = usePalette();

  const dataStructure = [
    {
      key: 'Golosinas',
      color: palette.danger,
      icon: faCandyCane,
      colorRGBA:'#e84630',
      colorRGBA2:'rgba(254, 39, 17, 0.2)',
    },
    {
      key: 'Chocolates',
      color: palette.brown,
      icon: faTh,
      colorRGBA:'rgba(97, 50, 49, 0.7)',
      colorRGBA2:'rgba(97, 50, 49, 0.3)'
    },
    {
      key: 'Harinas',
      color: palette.warning,
      icon:faCookieBite,
      colorRGBA: 'rgba(255, 206, 86, 0.8)',
      colorRGBA2:'rgba(255, 206, 86, 0.3)'
    },
    {
      key: 'Alimentos',
      color: palette.primary,
      icon: faMortarPestle,
      colorRGBA: '#038ee6',
      colorRGBA2:'rgba(63, 128, 234, 0.3)',
    },
    {
      key: 'Combo',
      color: palette.strong_green,
      icon: faBoxes,
      colorRGBA: '#2dbe76',
      colorRGBA2:'rgba(32, 201, 151, 0.4)'
      
    },
    {
      key: 'Agroindust',
      color: palette.success,
      icon: faLeaf,
      colorRGBA: 'rgba(75, 191, 115, 0.6)',
      colorRGBA2:'rgba(75, 191, 115, 0.4)'
    },
    {
      key: 'sin negocio',
      color: palette["gray-400"],
      icon: faQuestion,
      colorRGBA: palette["gray-200"],
      colorRGBA2:palette["gray-200"]
    },
    {
      key: 'sin segmento',
      color: palette.orange,
      icon: faQuestion,
      colorRGBA: palette.orange ,
      colorRGBA2:palette.orange 
    },
  
  ];
  

  const subtractMonthString = (month) => {

    let subtract = moment(month).subtract(1, 'months');

    const currMonthName = (moment(subtract).locale("es").format('MMMM'));

    let prevMonthName = (moment(month)
    .subtract(1, 'months').locale("es")
    .format('MMMM'));

    let prevDate = moment(subtract).format('YYYY-MM');

    return{
      prevMonthName,
      currMonthName,
      prevDate,
    }

  }

  const sortArrays = (array = []) => {

    return (array.filter(business => parseFloat(business["total-item-amount"])).sort( (segmentA, segmentB) => parseFloat(segmentB["total-item-amount"]) - parseFloat(segmentA["total-item-amount"])));

  }

  const businessList = async (date) => {
    OpenAPI.TOKEN = McoToken();
    
    //GET ORDERS BY BUSINESS
    await ordersByBusiness(
      date
    ).then(({data}) => {

      if(data){
        
        let business = [];
        let dataByBusiness = [];
          
        let sortBusiness = sortArrays(data);

        const TOTAL_AMOUNT = sortBusiness.map( amounts => parseFloat( amounts["total-item-amount"]))
        .reduce((acc, curr) => acc + curr, 0); // amount

        const TOTAL_UNITS = sortBusiness.map( units => parseFloat( units["total-units-sold"]))
        .reduce((acc, curr) => acc + curr, 0);

        setTotals({
          amount:TOTAL_AMOUNT,
          units: TOTAL_UNITS
        })
      
        sortBusiness.forEach( e => {
          if(e.business) {
            !business.includes(e.business) && 
            business.push(e.business);
            business.forEach( business => {
              if(!dataByBusiness[business]){
                dataByBusiness[business] = {
                  business: business,
                  total_amount: e['total-item-amount']? ( e['total-item-amount'] ) : 0,
                  total_orders: e['total-orders']? ( e['total-orders'] ) : 0,
                  percents: parseFloat((e["total-item-amount"] / TOTAL_AMOUNT * 100).toFixed(1)),
                  total_units_sold: e["total-units-sold"]? (e["total-units-sold"]) : 0,
                  color: dataStructure.filter( ({key}) => key === business),
                  percent_amount: e['total-item-amount']? ( getPercent( Number(e['total-item-amount']), TOTAL_AMOUNT ) ) : 0,
                  percent_units: e["total-units-sold"]? ( getPercent( Number(e["total-units-sold"]), TOTAL_UNITS )  ) : 0,
                }
              }
            })
          }else {
            return;
          }
        });

        setByBusiness(Object.values(dataByBusiness));
        setLoading(false);
      }else{
        setTotals({})
        setByBusiness([]);
        setLoading(false);
      }
    })
    .catch(err => {
      console.log(err);
    });
  }

  const prevBusinessList = async(date) => {

    const { prevDate, prevMonthName } = subtractMonthString(date);
    
    //?GET ORDERS BY BUSINESS PREV MONTH
    await ordersByBusiness(
      prevDate
    ).then(({data}) => {

      if(data){

        let business = [];
        let dataByBusiness = [];

        let sortBusiness = sortArrays(data);

        const TOTAL = sortBusiness.map( amounts => parseFloat( amounts["total-item-amount"])).reduce((acc, curr) => acc + curr, 0);
      
        sortBusiness.forEach( e => {
          if(e.business) {
            !business.includes(e.business) && 
            business.push(e.business);
            business.forEach( business => {
              if(!dataByBusiness[business]){
                dataByBusiness[business] = {
                  business: business,
                  total_amount: ( e['total-item-amount'] ),
                  total_orders: ( e['total-orders'] ),
                  percents: parseFloat((e['total-item-amount'] / TOTAL * 100).toFixed(1)),
                  total_units_sold: (e["total-units-sold"]),
                  color: dataStructure.filter( ({key}) => key === business),
                }
              }
            })
          }

        });
        setByPrevBusiness({
          prevBusiness: Object.values(dataByBusiness),
          prevMonthName
        });
        setLoading(false);
      }else{
        setByPrevBusiness({
          prevBusiness: [],
          prevMonthName: 'Hubo un error'
        });
        setLoading(false);
      }
    })
    .catch(err => {
      console.log(err);
    });
  }  

  useEffect(() => {
    businessList(date);
    prevBusinessList(date);
  }, [ date ]);

  return {
    byPrevBusiness,
    byBusiness,
    prevMonth,
    loading,
    totals,
  }
}

export default useBusiness