import { Fragment, useEffect } from 'react'
import useReports from '../../../hooks/useReports'
import  MixBoxesReportTable  from './reports-components/MixBoxesReportTable'



export const MixBoxesReport = () => {

  const { getMixBoxes, mixBoxes, loading } =  useReports();

  useEffect(() => {
    getMixBoxes()
  }, []);


  return (
    <Fragment>
      <h3 className='mb-3'> Reporte Cajas Surtidas </h3>
      <MixBoxesReportTable
        data={mixBoxes}
        loading={loading}
      />
    </Fragment>
  )
}
