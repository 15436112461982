import { Settings } from "react-feather";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import minimalartAvatar from "../../assets/img/avatars/minimalart.png";


const NavbarUser = () => {

  const { signOut } = useAuth();

  const { user } = useSelector(state => state.jwt );

  const handleLogout = async (e) => {
    e.preventDefault();
    await signOut();
  }

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <img
            src={minimalartAvatar}
            className="avatar img-fluid rounded-circle me-1"
            alt="Minimalart"
          />
          <span className="text-dark ms-2 me-1">{user ? user.name : 'Minimalart'}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end" className="mt-2" >
        <Dropdown.Item onClick={handleLogout} >Cerrar Sesión </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
