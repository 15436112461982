/**_manual page */
import React from "react";
import { Helmet } from "react-helmet-async"; 
import { Col, Container, Row } from "react-bootstrap";

const McoInConstructionPage = () => {
  return (<React.Fragment>
    <Helmet title="En desarrollo" />
    <Container fluid className="p-0">
      <Row>
        <Col lg="12" className="text-center">
          <h1 className="mt-7">En desarrollo...</h1>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
)};

export default McoInConstructionPage;
