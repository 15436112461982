
import { useState } from 'react';

import moment from "moment/min/moment-with-locales";

import { MCO_CONFIGS } from '../constants';
import McoToken from '../components/McoToken';
import { OpenAPI, ReportsService } from '../services/openapi';


const {
  getReportCoupons,
  getBoxesMixReport,
  getReportUsersMailList,
  getReportProductsSimple,
  getReportProductsSimpleDetails,
} = ReportsService;


const useReports = () => {
 
  const { locale } = MCO_CONFIGS;
  
  const [ mixBoxes, setMixBoxes ] = useState([]);
  const [ pageCount, setPageCount ] = useState( 0 );
  const [ loading, setLoading ] = useState( false );
  const [ usersMails, setUsersMails ] = useState([]);

  const [ simpleData, setSimpleData ] = useState([]);
  const [ couponsData, setCouponsData ] = useState([]);
  const [ dataProcessed, setDataProcessed ] = useState([]);

  const [ exportLoading, setExportLoading ] = useState( true );
  const [ exportableCoupons, setExportableCoupons ] = useState([]);


  const [ simpleDataDetails, setSimpleDataDetails ] = useState([]);
  const [ simpleDataDetailsExport, setSimpleDataDetailsExport ] = useState([]);

  const getSimpleDataDetails = async( period ) => {

    setLoading( true );
    OpenAPI.TOKEN = McoToken();

    try {

      const simpleReportsDetails: getReportProductsSimple[] = await getReportProductsSimpleDetails( period );
      let processed = Object.values( simpleReportsDetails );
      setSimpleDataDetails( processed );
      setLoading(false);
      return processed;

    } catch (error) {
      setLoading( false );
      console.log(error);
    }
  };

  const getAllSimpleDataDetails = async( period ) => {

    setLoading( true );
    OpenAPI.TOKEN = McoToken();

    try {

      const simpleReportsDetails: getReportProductsSimple[] = await getReportProductsSimpleDetails( period );
      let values = Object.values( simpleReportsDetails );

      if( values ){

        const data_processed = values.map( ( e ) => {
          return {
            "MES": e.period? (moment( e.period ).format("YYYY-MM")) : ' - ',
            "SKU" : ( e.sku ),
            "PRODUCTO" : ( e.product_name ),
            "COMPRADORES" : ( e.total_users_buy ),
            "ORDENES" : ( e.total_orders_count ),
            "CANTIDAD PROD" : ( e.product_quantity ),
            "PRECIO LISTA" : ( e.total_net_revenue )?  parseFloat(e.total_net_revenue).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0,
          };
        });

        setSimpleDataDetailsExport( data_processed );
        setLoading( false );
      }else {
        setSimpleDataDetailsExport( [] );
        setLoading( false );
      }

      return simpleReportsDetails;

    } catch (error) {
      setLoading( false );
      console.log(error);
    }
  };

  const getSimpleData = async( period ) => {

    setLoading( true );
    OpenAPI.TOKEN = McoToken();

    try {

      const simpleReports: getReportProductsSimple[] = await getReportProductsSimple( period );
      setSimpleData( simpleReports );
      setLoading(false);
      return simpleReports;

    } catch (error) {
      setLoading( false );
      console.log(error);
    }
  };

  const getUsersMail = async({
    role,// role: string,
    name,// name?: string,
    surname,// surname?: string,
    email,// email?: string,
    mainCity,// mainCity?: string,
    mainState,// mainState?: string,
    allowReceiveComunications,// allowReceiveComunications: 0 | 1 = 0,
    allowReceiveSamples,// allowReceiveSamples: 0 | 1 = 0,
    perPage,// perPage?: any,
    page,// page?: any,
    orderBy='allow_receive_comunications'// orderBy: 'user_id' | 'name' | 'surname' | 'email' | 'role' | 'date_registered' | 'main_city' | 'main_state' | 'allow_receive_comunications' | 'allow_receive_samples' = 'date_registered',
  }) => {

    setLoading(true);
    
    OpenAPI.TOKEN = McoToken();
  
    await getReportUsersMailList(
      role,
      name,
      surname,
      email,
      mainCity,
      mainState,
      allowReceiveComunications,
      0,
      perPage,
      page,
      0,
      orderBy
    ).then((resp) => {

      if(resp){
    
        setUsersMails(resp.data);
        setPageCount(resp.last_page);
        setLoading(false);
      }

    })
    .catch( error => {

      setUsersMails([]);
      setLoading(false);
      console.log(error);
    });

  }

  const processData = ( arr=[] ) => {

    const data_processed = arr.map((e) => {
      return {

        "APELLIDO" : ( e.surname ),

        "NOMBRE" : ( e.name ),

        "PROVINCIA" : ( e.main_state ),

        "CIUDAD" : ( e.main_city ),

        "RECIBE MAILS" : ( Boolean(e.allow_receive_comunications )? 'SI' : 'NO'),

        "EMAIL" : ( e.email )? e.email : ' - ',
      };

    });

    return data_processed;
  }

  const getUserEmailsToExport = async() => {

    OpenAPI.TOKEN = McoToken();

    await getReportUsersMailList(
      'empleado_arcor',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      0,
      undefined,
      undefined,
      1,
      'allow_receive_comunications'
    )
    .then( resp => {

      if(resp){
        setDataProcessed(processData(resp));
        setExportLoading(false);
      }
    }).catch( error => {

      setDataProcessed([]);
      setLoading(false);
      console.log(error);
    })
    
  }

  const getCoupons = async( date, sub_group ) => {

    setLoading(true);
    OpenAPI.TOKEN = McoToken();

    try {
      const coupons = await getReportCoupons(
       undefined,// orderId?: number,
       undefined,// discountName?: string,
       undefined,// promo_name?: string,
       undefined,// distId?: string,
       sub_group,//subgroup
       undefined,// orderBy: 'period' | 'order_id' | 'date' | 'dist_id' | 'dist_name' | 'discount_name' | 'promo_name' | 'discount_value' = 'date',
       10000,// perPage?: any,
       1,// page?: any,
       0,// _export: 0 | 1 = 0,
       date// startDate?: any,
      );



      if (coupons) {

        // Datos para la Tabla
        setCouponsData(coupons.data);

        const data_processed = coupons.data.map((e) => {
          return {

            "ORDEN ID" : ( e.order_id ),

            "FECHA" : e.date? (moment( e.date ).format("DD/MM/YYYY")) : ' - ',

            "DIST ID" : ( e.dist_id ),

            "DISTRIBUIDOR" : ( e.dist_name ),

            "GRUPO" : ( e.group ).trim(),

            "SUBGRUPO" : ( e.sub_group === 'others'? 'OTROS' :  (e.sub_group).trim() ),

            "CUPON/DESC" : ( e.promo_name!== null? e.promo_name : e.discount_name ),

            "DESCUENTO" : ( e.discount_value )? parseFloat(e.discount_value).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0,

          };
        });

        setExportableCoupons( data_processed );
        setLoading(false);

      }

    } catch (error) {
      setCouponsData([]);
      setLoading(false);
      console.log(error);
    }
  }

  const getMixBoxes = async() => {
    setLoading(true);
    OpenAPI.TOKEN = McoToken();
    // distId,
    // promoName,
    // status,
    // orderBy,
    // order,

    await getBoxesMixReport(
      null,
      null,
      null,
      null,
      null
    )
    .then( resp => {
      setMixBoxes(resp);
      setLoading(false);
    })
    .catch( error => {
      console.log(error);
      setMixBoxes([]);
    })
  }


  return {
    loading,
    mixBoxes,
    pageCount,
    simpleData,
    getCoupons,
    usersMails,
    getMixBoxes,
    couponsData,
    getUsersMail,
    dataProcessed,
    exportLoading,
    getSimpleData,
    simpleDataDetails,
    exportableCoupons,
    getSimpleDataDetails,
    getUserEmailsToExport,
    simpleDataDetailsExport,
    getAllSimpleDataDetails,
  }
}

export default useReports;