import React, { useContext, useEffect, useRef } from "react";

import { Row, Col, Spinner } from "react-bootstrap";

import { BenefitsContext } from "./layout/BenefitsLayout";
import BenefitsCards from "./benefits-components/BenefitsCards";
import useEmployees from "../../../hooks/useEmployees";



const CorporativesBenefits = () => {

  const { currentMonth } = useContext(BenefitsContext);

  const { 
    isLoading,
    customerCompanies,
    fetchNewBuyersEmployees
  } = useEmployees();

  const isMounted = useRef(true);
  
  useEffect(() => {
    fetchNewBuyersEmployees(currentMonth);
    return () => {
      isMounted.current = false;
    };
  }, [currentMonth]);
  
  // Alfabetic order by role
  if (customerCompanies && customerCompanies.length > 0) {
    customerCompanies.sort( (a, b) => {
      const startsWithNumberA = /^\d/.test(a.role);
      const startsWithNumberB = /^\d/.test(b.role);
  
      if (startsWithNumberA && startsWithNumberB) {
        return a.role.localeCompare(b.role);
      }
      if (startsWithNumberA) {
        return 1;
      }
      if (startsWithNumberB) {
        return -1;
      }
  
      return a.role.localeCompare(b.role);
    });
    
  }

  return (
    <React.Fragment>
      {
        ( isLoading && !customerCompanies.length > 0 )?
        (
          <Row className="mb-4">
            <Col sm="12" className="text-center">
              <Spinner animation="border" variant="secondary" className="me-2 border-3 mx-auto" />
            </Col>
          </Row>
        ):
        (
          <Row className="mb-4">
          {
            customerCompanies.map( ( company, id ) =>
            
              (company.role !== "Customer")?
              (<Col key={id} sm="4" className="animate fadeIn">
                
                <BenefitsCards
                  company={ company }
                  prefix=""
                  format={true}
                  is_loading={isLoading}
                  currentMonth={currentMonth}
                  />
              </Col>) : ""
            )
          }
          </Row>
        ) 
      }
    </React.Fragment>
  );
};

export default CorporativesBenefits;
