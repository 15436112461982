import { Fragment, useContext, useEffect } from 'react';

import moment from "moment/min/moment-with-locales";
import { useTranslation } from 'react-i18next';

import { BenefitsContext } from '../layout/BenefitsLayout';
import { SuspensivePointsLoader } from '../../../../components/mco/components/McoLoaders';
import McoAutoPaginatedTable from '../../../../components/mco/components/McoAutoPaginatedTable';


const CouponsReportTable = () => {

  const {
    locale,
    loading,
    couponsData,
    currentMonth,
    exportableCoupons,
    getCoupons
  } = useContext(BenefitsContext);

  useEffect(() => {
    let isMounted = true;
      getCoupons(currentMonth)
    return () => isMounted = false;
  }, [currentMonth])
  

  const [t] = useTranslation();

  const couponsColumns = [
    {
      Header: "Orden ID",
			accessor: "order_id",
			className: "col-1 text-center",
			headerClassName:"text-center",
		},
    
    {
      Header: "Fecha",
      accessor: "date",
      className: "col-1 text-center",
      headerClassName:"text-center",
      Cell: (props) => {
        return props.value? moment(props.value).format("DD/MM/YYYY") : '-';
      }
    },
    {
      Header: "Dist ID",
      accessor: "dist_id",
      className: "col-1 text-center",
      headerClassName:"text-center",
      enableColumnFilter:true,
      Cell: (props) => { 
        return props.value 
      },
    },
		{
			Header: "Distribuidor",
			accessor: "dist_name",
			className: "col-2 text-left",
			headerClassName:"text-left",
		},
    {
			Header: "Grupo",
			accessor: "group",
			className: "col-2 text-left",
			headerClassName:"text-left",
		},	
    {
			Header: "Subgrupo",
			accessor: "sub_group",
			className: "col-2 text-left",
			headerClassName:"text-left",
      Cell: props => t(props.value)
      
		},		
		{
			Header: "Cupon/Desc", 
			accessor: (data) => data.discount_name || data.promo_name,
			className: "col-2 text-center",
			headerClassName: "text-center",
		},
		{
			Header: "Descuento",
			Filter: false,
			accessor: "discount_value",
			className: "text-right col-1",
			headerClassName:"text-right py-4",
			Cell: (props) => {
				return "$" + ((props.value)? parseFloat( props.value ).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0 );
			}
		},
	
	];

  return (
      <Fragment>
        {
          loading ?
          <SuspensivePointsLoader title='cupones' />
          : (
            <McoAutoPaginatedTable 
              loading={loading}
              data={couponsData}
              customPageSize={25}
              columns={couponsColumns}
              dataForExport={exportableCoupons}
              fileName={"reporte_de_cupones"}
              title={`Cupones de ${moment(currentMonth).locale("es").format("MMMM YY")}`}
            />
          )
        }
      </Fragment>
  )
}

export default CouponsReportTable;