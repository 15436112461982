import { useState } from 'react'
import { MCO_CONFIGS } from '../constants';
import McoToken from '../components/McoToken'
import moment from 'moment/min/moment-with-locales';
import { OpenAPI, CustomersService, OrdersService } from '../services/openapi'


const { newBuyersEmployees } = CustomersService;
const { getOdersByRole } = OrdersService;

//* PARAMS (newBuyersEmployees)
// email?: string,
// name?: string,
// surname?: string,
// employeeId?: string,
// company?: string,
// orderBy: 'user_id' | 'email' | 'name' | 'surname' | 'employee_id' | 'company' = 'company',
// perPage?: any,
// page?: any,
// _export: 0 | 1 = 0,

//* PARAMS (getOdersByRole)
// perPage?: any,
// status: 'FALLIDO' | 'REEMBOLSADO' | 'COMPLETADO' | 'LISTO PARA ENTREGAR' | 'EN ARMADO' | 'PROCESANDO' | 'CANCELADO' | 'PENDIENTE' | 'EN ESPERA' | 'SOLIC. CANCELACIÓN' | 'SOLIC. DEVOLUCIÓN' | 'DEVUELTO' = 'COMPLETADO',
// startDate?: any,
// page?: any,
// _export: 0 | 1 = 0,
// roleName?: any,

const useEmployees = () => {

	const { locale } = MCO_CONFIGS;
	const [ isLoading, setIsLoading ] = useState(true);
	const [ metaDetails, setMetaDetails ] = useState([]);
	const [ orderDetails, setOrderDetails ] = useState([]);
	const [ dataProcessed, setDataProcessed ] = useState([]);
	const [ customerCompanies, setCustomerCompanies ] = useState([]);

	const fetchNewBuyersEmployees = async(date) => {
			OpenAPI.TOKEN = McoToken();
			setIsLoading( true );
			try {
				await newBuyersEmployees(
						date
				).then( ( {data} ) => {

						let companiesData = [];

						data.forEach(({
							role,
							total_users,
							total_amount,
							total_orders,
							original_role,
							subtotal_amount,
							total_discounted,
							distinct_total_orders
						})=> {
							companiesData.push({
								role,
								original_role,
								total_users: total_users? total_users : 0,
								total_amount: total_amount? JSON.parse(total_amount) : 0,
								total_orders: total_orders? JSON.parse(total_orders) : 0,
								subtotal_amount: subtotal_amount? JSON.parse(subtotal_amount) : 0,
								total_discounted: total_discounted? JSON.parse(total_discounted) : 0,
								distinct_total_orders: distinct_total_orders? JSON.parse(distinct_total_orders) : 0
							})
						});

						setCustomerCompanies( companiesData );
						setIsLoading( false );

				}).catch((error) => {
					console.log(error);
					setCustomerCompanies([]);
					setIsLoading(false);

				});
					
			} catch (error) {
				setCustomerCompanies([]);
				setIsLoading(false);
			}
	}

	//OrdersBy Role
	const getDataOrders = async ({date, employee}) => {

		setIsLoading( true );
		OpenAPI.TOKEN = McoToken();

			try{
				const orders = await getOdersByRole(
						10000,
						null, //status
						date,
						1,
						0,
						employee
				);

				if (orders.success) {
				
					setMetaDetails(orders.meta);
					setOrderDetails(orders.data);
					setIsLoading(false)
					return orders;

				} else {
					setMetaDetails([]);
					setOrderDetails([]);
					setIsLoading(false);
				} 

			}catch (error){
				setOrderDetails([]);
				setIsLoading(false);
				throw new Error(error);
			}
	};

	const exportDataOrders = async (date, employee) => {
		setIsLoading( true );

		OpenAPI.TOKEN = McoToken();
		try{
				const orders = await getOdersByRole(
						100000,
						null,
						date,
						1,
						0,
						employee
				);

				if(orders.success){

				const data_processed = orders.data.map((e) => {
					return {

						"ORDEN ID" : ( e.order_id ),
						
						"FECHA" : moment( e.date ).locale('es').format( "DD-MM-YYYY" ),
	
						"NOMBRE" : ( e.name ),
	
						"APELLIDO" : ( e.surname ),

						"DIST ID" : e.dist_id,

						"DISTRIBUIDOR": e.dist_name,
						
						"BRUTO" : ( e.order_subtotal )? '$ ' + parseFloat(e.order_subtotal).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0,
						
						"DESCUENTO" : ( e.discount )? '$ ' + parseFloat(e.discount).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0,
	
						"NETO": ( e.order_total )? '$ ' + parseFloat(e.order_total).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0,
	
						"ESTADO": ( e.status ),

					};
				});
				
				setDataProcessed(data_processed);
				setIsLoading(false);

				}else {
					setDataProcessed([]);
					setIsLoading(false);
				}
				return orders;
		}catch (error){
				setDataProcessed([]);
				setIsLoading(false);
				throw new Error(error);
		}
	};

  return {
    fetchNewBuyersEmployees,
    customerCompanies,
		exportDataOrders,
		dataProcessed,
		getDataOrders,
		orderDetails,
		metaDetails,
    isLoading,
  }
}

export default useEmployees