import McoAutoTable from "./McoAutoTable";
import React, { createContext} from "react";
import { ExportCSVButton } from "./ExportCSVButton";
import { Button, Card,Form, Spinner } from "react-bootstrap";
import McoAutoControlPage from "./McoAutoControlPage";
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { OverlayTooltip } from "./OverlayTooltip";


  export const McoAutoTableContext = createContext({});
  const {Provider} = McoAutoTableContext;

/**Global FIlter */
  
  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter }, }) {

    return (
      <Form.Control
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        onClick={ (e) => {
          e.stopPropagation();
        }}
        placeholder={`Buscar...`}
        className="mt-2"
      />
    );
  }
    	
/**End Global Filter */

const McoAutoPaginatedTable = ({ 
  columns,
  data,
  is_loading,
  title = "",
  subtitle = "",
  customPageSize,
  dataForExport=[],
  fileName,
  controlPageUp=true,
  controlPageDown=true,
  exportButton=true,
  hover=false,
  addUserButton=false,
  handleClick,
}) => {


	const filterTypes = React.useMemo(
		() => ({
		  // Or, override the default text filter to use
		  // "startWith"
		  text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
          ? String(rowValue)
            .toLowerCase()
            .startsWith(String(filterValue).toLowerCase())
          : true;
        });
		  },
		}),[]
	  );
	
	  const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),[]
    );

    const {
      rows,
      page,
      gotoPage,
      nextPage,
      pageCount,
      prepareRow,
      canNextPage,
      pageOptions,
      setPageSize,
      previousPage,
      headerGroups, 
      getTableProps, 
      canPreviousPage,
      getTableBodyProps, 
      state: { pageIndex, pageSize },
    
    } = useTable({
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes, 
      initialState: { pageIndex: 0, pageSize: customPageSize  },
    },  
      useFilters,
      useGlobalFilter, 
      useSortBy,
      usePagination
    );


	return (
    <Provider value={{
      rows,
      page,
      hover,
      nextPage,
      pageSize,
      gotoPage,
      pageCount,
      pageIndex,
      prepareRow,
      setPageSize,
      pageOptions,
      canNextPage,
      previousPage,
      headerGroups,
      getTableProps,
      customPageSize,
      canPreviousPage,
      getTableBodyProps,
    }}>
      <Card className='p-3'>
        <Card.Header>
          {
            exportButton &&
            (  <ExportCSVButton data={dataForExport} fileName={fileName}/> )
          }
          {
            addUserButton &&
            ( 
              <OverlayTooltip
                description="Nuevo usuario"
              >
                <div className="card-actions float-end d-flex flex-column zindex-sticky" >
                  <Button
                    className="bg-white shadow-lg mco-raise mco-draw-border mco-ease-out border border-muted p-3 px-4"
                    onClick={ () => handleClick() }
                  >
                    <FontAwesomeIcon className="text-success fs-4 ease-out" icon={faUserPlus} />
                  </Button> 
                </div>
              </OverlayTooltip>
            )
          }
          <Card.Title tag="h5">
            { title } {
              is_loading && 
              (<Spinner animation="grow"variant="dark"type="grow"size="sm"className="mx-3 cursor-pointer"/>)
            }
          </Card.Title>
          <h6 className="card-subtitle text-muted">
            {subtitle}
          </h6>
        </Card.Header>
          {
            controlPageUp &&
            ( <McoAutoControlPage /> )
          }
          <McoAutoTable/>
          {
            controlPageDown &&
            ( <McoAutoControlPage/> )
          }
          
          
      </Card>
    </Provider>
	);
};

 
export default McoAutoPaginatedTable;