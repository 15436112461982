import Chart from "react-apexcharts";
import { Card, Spinner } from "react-bootstrap";
import moment from 'moment/min/moment-with-locales';
import { useSelectDate } from "../../../hooks/useSelectDate";

const McoPieChart = ({ title = "", subtitle = "", data, loading = true }) => {
  const [ currentMonth ] = useSelectDate();
  data = data.sort( (a, b) => b.total_registered - a.total_registered );
  const options = {
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
      position: "bottom"
    },
    colors: [
      "#3366d6",
      "#4285f4",
      "#4285f4",
      "#4285f4",
      "#4285f4",
      "#4285f4",
      "#4285f4",
      "#71a3f7",
      "#71a3f7",
      "#71a3f7",
      "#71a3f7",
      "#71a3f7",
      "#a0c2fa",
      "#a0c2fa",
      "#a0c2fa",
      "#a0c2fa",
      "#a0c2fa",
      "#d0e0fc",
      "#d0e0fc",
      "#d0e0fc",
      "#d0e0fc",
      "#d0e0fc",
      "#d0e0fc",
      "#d0e0fc",
      "#d0e0fc",
      "#d0e0fc",
    ],
    labels: data.map((e) => e.state || "" ),
  };

  return (
    <Card className="w-100">
      <Card.Header>
        <Card.Title tag="h5" className="mb-1">
          {title}
          { (loading) ?
              (<Spinner
                animation="grow"
                variant="dark"
                type="grow"
                size="sm"
                className="mx-2"
              />) : "" }
        </Card.Title>
        <h6 className="card-subtitle text-muted">
          { subtitle }
        </h6>
      </Card.Header>
      <Card.Body>
        <div className="chart">
          {data.length === 0 ? (
              <p className="w-100 text-center">
                Aún no hay data procesada para el mes de 
                <strong className="text-capitalize"> 
                { moment(currentMonth).locale("es").format("MMMM") }
                </strong>
              </p>
            ): 
            (
              <Chart 
                options={options}
                series={data.map((e) => e.total_registered || 0)}
                type="donut"
                height="500"
              />
            )
          }
        </div>
      </Card.Body>
    </Card>
  );
};

export default McoPieChart;
