import React, { useEffect, useState } from "react";
//import { VectorMap } from "react-jvectormap";
import { VectorMap } from "@react-jvectormap/core";
import { arMill } from '@react-jvectormap/argentina';

import { Card, Dropdown, Spinner } from "react-bootstrap";

import { MoreHorizontal } from "react-feather";

import usePalette from "../../../hooks/usePalette";
import { locations } from "./locations";

function McoArgentinaMap({ title, subtitle = "", data, loading = true }) {

  const [ markers, setMarkers ] = useState( [] );

  const [ isShowMap, setIsShowMap ] = useState( true );

  const palette = usePalette();
  
  let newData = [];

    
  useEffect( () => {
    setIsShowMap( false );
    
    data.map( e => {
    locations.map( k => {
        if(e.state === k.name){
          newData.push ({
            state_code: k["iso_id"],
            latitude: k["latitude"],
            state: k["name"],
            registered: e.total_registered,
            latLng: k["latLng"]
          })
        }
        
      })
    })

    setMarkers( newData );

    setIsShowMap(true);

  }, [ data ]);

  const options = {
    //map: "ar_mill",
    normalizeFunction: "polynomial",
    hoverOpacity: 0.7,
    hoverColor: false,
    zoomOnScroll: false,
    regionStyle: {
      initial: {
        fill: palette["gray-200"],
        stroke: palette.primary,
        "stroke-width": .4,
        "stroke-opacity": 1
      },
    },
    containerStyle: {
      width: "100%",
      height: "100%",
    },
    markerLabelStyle: {
      initial: {
        'font-family': 'Poppins',
        'font-size': '10',
        'font-weight': '400',
        cursor: 'default',
        fill: 'black'
      },
    },
    markerStyle: {
      initial: {
        r: 6,
        fill: palette.primary,
        "fill-opacity": 0.9,
        stroke: palette.white,
        "stroke-width": 2,
        "stroke-opacity": 0.4,
      },
      hover: {
        r: 7,
        fill: palette.primary,
        "fill-opacity": 0.9,
        stroke: palette.primary,
        "stroke-width": 7,
        "stroke-opacity": 0.4,
      },
    },
    backgroundColor: "transparent",
    labels: {
      markers: {
        render: function( index ) {

          if( data && index ){
            let total_registered = (typeof data[index] === 'undefined')? 0 : data[index].total_registered;
            return total_registered;
          }
        }
      }
    }
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <div className="card-actions float-end d-none">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Action</Dropdown.Item>
              <Dropdown.Item>Another Action</Dropdown.Item>
              <Dropdown.Item>Something else here</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Card.Title tag="h5" className="mb-1">
          { title }
          { (loading) ?
            (<Spinner
              animation="grow"
              variant="dark"
              type="grow"
              size="sm"
              className="mx-2"
            />) : "" }
        </Card.Title>
        <h6 className="card-subtitle text-muted">
          { subtitle }
        </h6>
      </Card.Header>
      <Card.Body className="p-2">
        <div className="map-container" style={{ height: 500 }}>

          { isShowMap && !loading ?
            (<VectorMap
              map={arMill}
              markers={markers}
              {...options}
            />) : (<div className="w-100 text-center mt-5"><Spinner
              animation="grow"
              variant="dark"
              type="grow"
              size="sm"
              className="mx-auto"
            /></div>) }
        </div>
      </Card.Body>
    </Card>
  );
}

export default McoArgentinaMap;
