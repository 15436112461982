import { Fragment } from 'react'
import CouponsReportTable from './benefits-components/CouponsReportTable'


export const BenefitsDetails = () => {
  return (
    <Fragment>
      <CouponsReportTable />
    </Fragment>
  )
}
