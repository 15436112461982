/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductsStocksService {

    /**
     * Get Products List
     * This can only be done by the logged in user
     * @param sku sku
     * @param name name
     * @param status status
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @param orderBy
     * @param order
     * @returns any Success
     * @throws ApiError
     */
    public static getProductsList(
        sku?: string,
        name?: string,
        status: 'publish' | 'draft' = 'publish',
        startDate?: any,
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
        orderBy: 'date' | 'sku' | 'product_id' | 'name' | 'category' | 'url' | 'price' | 'business' | 'segment' | 'status' | 'units_sold' | 'total_orders' | 'total_amount' | 'dists_has_stock' | 'dists_has_not_stock' = 'product_id',
        order: 'DESC' | 'ASC' = 'DESC',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products',
            query: {
                'sku': sku,
                'name': name,
                'status': status,
                'start_date': startDate,
                'per_page': perPage,
                'page': page,
                'export': _export,
                'order_by': orderBy,
                'order': order,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get Products by category
     * This can only be done by the logged in user
     * @param sku sku
     * @param product product
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @param categoryId category_id
     * @returns any Success
     * @throws ApiError
     */
    public static getProductsByCategory(
        sku?: number,
        product?: string,
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
        categoryId?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/cat/{category_id}',
            path: {
                'category_id': categoryId,
            },
            query: {
                'sku': sku,
                'product': product,
                'per_page': perPage,
                'page': page,
                'export': _export,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get Ranking Products Without Stock
     * This can only be done by the logged in user
     * @param sku sku
     * @param product product
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @returns any Success
     * @throws ApiError
     */
    public static getRankingProductsWithoutStock(
        sku?: number,
        product?: string,
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/ranking-products-without-stock',
            query: {
                'sku': sku,
                'product': product,
                'per_page': perPage,
                'page': page,
                'export': _export,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get Distributors Without Stock
     * This can only be done by the logged in user
     * @param notDistIds not_dist_ids
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @returns any Success
     * @throws ApiError
     */
    public static getDistributorsWithoutStock(
        notDistIds?: any,
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/distributors-without-stock',
            query: {
                'not_dist_ids': notDistIds,
                'per_page': perPage,
                'page': page,
                'export': _export,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get Quantity & Total Selled
     * This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static getQuantityAndTotalSelled(
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/qty-and-total-selled',
            query: {
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get ranking products selled and total amount between a period of time
     * This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @param orderBy Order by ASC to DESC param
     * @returns any Success
     * @throws ApiError
     */
    public static getTotalSelledPerProduct(
        startDate?: any,
        orderBy: 'total_amount' | 'total_orders' | 'product' = 'total_amount',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/get-ranking-products-selled',
            query: {
                'start_date': startDate,
                'orderBy': orderBy,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

}