import React, { useEffect, useState } from "react";

import { Col, Container, Row, Breadcrumb } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import McoToken from '../../../../components/McoToken'
import { OpenAPI, CustomersService } from '../../../../services/openapi'
import { SuspensivePointsLoader } from "../../../../components/mco/components/McoLoaders";
import McoAutoPaginatedTable from "../../../../components/mco/components/McoAutoPaginatedTable";
import moment from "moment/min/moment-with-locales";

const { getRegisteredCustomerByrole } = CustomersService;

const McoEmployeesList = () => {
  // Get url data
  const { employee_name, role } = useParams();

  const [employeesList, setEmployeesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataProcessed, setDataProcessed] = useState([]);

  OpenAPI.TOKEN = McoToken();

  const tableColumns = [
    {
      Header: "Fecha de Alta",
      accessor: "date_registered",
      filter: true,
      headerClassName: "col-1 text-left",
      className: " col-1 text-left ",
      sortBy: [
        {
          id: 'date_registered',
          desc: true
        }
      ],
      Cell: props => {
        return props.value ?
          moment(props.value).format("DD/MM/YYYY") : '-';
      }
    },
    {
      Header: "Nombre",
      accessor: "name",
      headerClassName: "col-1 text-left",
      className: " col-1 text-left ",
    },
    {
      Header: "Apellido",
      accessor: "surname",
      headerClassName: "col-1 text-left",
      className: " col-1 text-left ",
    },
    {
      Header: "Email",
      accessor: "email",
      headerClassName: "col-1 text-left",
      className: " col-1 text-left ",
    },
  ];

  useEffect(() => {

    (async () => {
      setIsLoading(true);
      await getRegisteredCustomerByrole(
        role, undefined, undefined, undefined, "surname", 100000
      ).then(({ data }) => {
        if (data) {
          setEmployeesList(data);
          //get data for export
          const data_processed = data.map(e => {
            return {
              "FECHA ALTA": (e.date_registered),
              "NOMBRE": (e.name),
              "APELLIDO": (e.surname),
              "EMAIL": (e.email),
            };
          });

          setDataProcessed(data_processed);
        } else {
          return;
        }
      }
      )
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false));
    })();


  }, [role]);

  return (
    <React.Fragment>
      <h3>Detalle Beneficio Corporativo</h3>
      <Container fluid className="p-0">
        <Row className="mco-fade-in">
          <div className="ms-2 d-flex mt-3 fs-4">
            <Breadcrumb>
              <Link to={"/benefits/corporatives"} >
                Corporativos
              </Link>/
              <Breadcrumb.Item active>Detalles empleados</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Col lg="12">
            {
              isLoading
                ? (<SuspensivePointsLoader title="Empleados" />)
                : (
                  <McoAutoPaginatedTable
                    data={employeesList}
                    customPageSize={25}
                    loading={isLoading}
                    columns={tableColumns}
                    dataForExport={dataProcessed}
                    title={`Listado de empleados ${employee_name}`}
                    fileName={`Listado_empleados_${employee_name}`}
                  />
                )
            }
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default McoEmployeesList;
