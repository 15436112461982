import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import packageJson from "../../package.json";

const Footer = () => (
  <footer className="footer">
    <Container fluid>
      <Row className="text-muted">
        <Col xs="6" className="text-start">
          <ul className="list-inline">
            <li className="list-inline-item">
              <a className="text-muted" href="https://minimalart.co">
                Centro de ayuda y soporte
              </a>
            </li>
            <li className="list-inline-item">
              <a className="text-muted" href="https://minimalart.co">
                Terminos y condiciones de uso
              </a>
            </li>
          </ul>
        </Col>
        <Col xs="6" className="text-end">
          <p className="mb-0">
            <span href="/" className="text-muted">
              DEV + DESIGN BY MINIMALART | v{ packageJson.version || "0.0.0"} 
            </span>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
