import { useEffect, useRef, useState, Fragment } from "react";
import { Doughnut } from 'react-chartjs-2';
import { Card, Col, Row } from "react-bootstrap";
import { MCO_CONFIGS } from "../../../../constants";
import usePalette from "../../../../hooks/usePalette";
import { useDispatch, useSelector } from "react-redux";
import { selectBusiness } from "../../../../actions/products";
import BusinessInfoCard from "../products-components/BusinessInfoCards";
import getIncrease from "../../../../components/mco/helpers/getIncrease";



const BusinessPieChart = ({ byBusiness = [], byPrevBusiness, totals }) => {

  const canvas = useRef(null);
  const palette = usePalette();
  const dispatch = useDispatch();
  const { locale } = MCO_CONFIGS;
  const { productsChartMode:{ chartMode } } = useSelector( state => state);
  const [ selectedBusiness, setSelectedBusiness ] = useState({
    business: 'Seleccione un negocio',
    color: palette["gray-300"]
  });
  const [ dataChart, setDataChart ] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        percents: [],
        orders: [],
        units: [],
        backgroundColor: [],
        borderColor: "transparent",
        hoverOffset: 6,
        offset: 5,
        borderWidth: 4,
      },
    ],
  });

  const {
    name,
    icon,
    color,
    iColor,
    unitsCard,
    amountCard,
  } = useSelector( state => state.business );

  let chartHeight = "380px";
  let percentLabelSize = "28px";

  
  useEffect(() => {
    
    if(byBusiness.length > 0 ){

     if(chartMode === 'Amount'){
      byBusiness.sort( (segmentA, segmentB) => parseFloat(segmentB.total_amount) - parseFloat(segmentA.total_amount))

     }else if(chartMode === 'Units'){
      byBusiness.sort( (segmentA, segmentB) => parseFloat(segmentB.total_units_sold) - parseFloat(segmentA.total_units_sold))
     }

      let increment = [];
    
      if ( typeof byPrevBusiness.prevBusiness === 'object') {

        if(chartMode === 'Amount'){
          const curr = parseFloat(byBusiness[0].total_amount);

          const prevItemSelected = (byPrevBusiness.prevBusiness.filter(elm => elm.business === byBusiness[0].business));

          const prev = parseFloat(prevItemSelected[0].total_amount);

          (increment) = getIncrease(curr, prev);

        }else {
          const curr = parseFloat(byBusiness[0].total_units_sold);

          const prevItemSelected = (byPrevBusiness.prevBusiness.filter(elm => elm.business === byBusiness[0].business));

          const prev = parseFloat(prevItemSelected[0].total_units_sold);

          (increment) = getIncrease(curr, prev);

        }
        
        
      } else {
        return
      }

      dispatch(
        selectBusiness({
        business:  (byBusiness[0].business),
        icon:      (byBusiness[0].color[0].icon),
        amount:    (byBusiness[0].total_amount),
        iconColor: (byBusiness[0].color[0].color),
        units:     (byBusiness[0].total_units_sold),
        color:     (byBusiness[0].color[0].colorRGBA2),
      }));

      setSelectedBusiness({
        ...selectedBusiness,
        index: 0,
        incremet: (increment).toLocaleString(locale,{
          maximumFractionDigits:1,
          minimumFractionDigits:1
        }) || 0,
        average: chartMode === "Amount"? byBusiness[0].percent_amount : byBusiness[0].percent_units
      });

     
      setDataChart({
        ...dataChart,
        labels: byBusiness.map(labels => labels.business),
        datasets: [
          {
            ...dataChart.datasets[0],
            data: byBusiness.map(business => {
              if(chartMode === 'Amount'){
                return parseFloat(business.total_amount);
              }else {
                return parseFloat(business.total_units_sold);
              }
              
            }),
            orders: byBusiness.map(business => (business.total_orders)),
            units: byBusiness.map(business => (business.total_units_sold)),
            amount: byBusiness.map(business => parseFloat(business.total_amount)),
            percents: byBusiness.map(business => (business.percents)),
            backgroundColor: byBusiness.map(business => (business.color[0]?.colorRGBA )),
            borderColor: palette.white,
            hoverOffset: 13,
            borderWidth: 1,
            hoverBackgroundColor: byBusiness.map(business =>  (business.color[0]?.color))
          }
        ]
      });
    }else {
      return;
    }

  }, [byBusiness, byPrevBusiness, chartMode]);

  
  const percent = {
    id: 'percent',
    beforeDraw( chart, args, options ){
      const { ctx, chartArea:{ right, bottom } } = chart;
      ctx.save();
      ctx.font = `${percentLabelSize} Poppins`;
      ctx.textAlign = 'center';
      ctx.fillStyle = options.fontColor;
      if(options.percent){
        if(parseFloat(options.percent) > 0){
          ctx.fillText( '+' + (options.percent) + '%',
          (right/2), (bottom/2) + 15);
        }else {
           ctx.fillText((options.percent) + '%',
          (right/2), (bottom/2) + 15);
        }
      }
    },
  };

  const labelChartCenter = {
    id: 'labelChartCenter',
    beforeDraw( chart, args, options ){
      const { ctx, chartArea:{ right, bottom } } = chart;
      ctx.save();
      ctx.font = '17px Poppins';
      ctx.textAlign = 'center';
      if(options.percent !== 0){

        ctx.fillStyle = palette.secondary;
        ctx.fillText(' vs ' + (options.prevMonth),
        (right/2), ((bottom/2)+48));

      } else {
        ctx.fillStyle = palette.secondary;
        ctx.fillText('No hubo ordenes en' ,
        ((right/2)), ((bottom/2)));
      
        ctx.fillStyle = palette.secondary;
        ctx.fillText((options.prevMonth),
        ((right/2)), ((bottom/2) + 40));
      }
    }
  };
  
  const labelChartOut = {
    id: 'labelChartOut',
    beforeDraw( chart, args, options ){
      const { ctx, data, chartArea:{ width, height } } = chart;
      ctx.save();

      const halfWidth = width / 2;
      const halfHeight = height / 2;
      
      ctx.font = '20px Poppins';
      ctx.textAlign = 'center';
      ctx.textBaseLine = 'middle';
      
      if(chart.getDatasetMeta(0).data.length > 0){
        const { x, y } = chart.getDatasetMeta(0).data[options.index].tooltipPosition();
        const xLine = x >= halfWidth? x + 15 : x - 15;
        const yLine = y >= halfHeight? y + 25 : y - 25;
        const extraLine = x >= halfWidth? 15 :  - 15;
        const textWidth = ctx.measureText(options.average).width;
        const textPosition = x >= halfWidth? textWidth : -textWidth;

        if(options.percent !== 0){
          ctx.fillStyle = options.fontColor;
          ctx.strokeStyle = data.datasets[0].hoverBackgroundColor[options.index]; 
          ctx.beginPath();
          ctx.moveTo(x, y);
          ctx.lineTo(xLine, yLine);
          ctx.lineTo(xLine + extraLine, yLine );
          ctx.stroke();

          ctx.fillText((options.average) + ' %', xLine + extraLine + textPosition - 3, yLine + 6);
        } 
    }

      }
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    radius: "73%",
    cutout: "86%",
    animation: {
      animateScale: true,
      animateRotate: true,
    },
    onHover (e, chartElement) {
      if(chartElement.length === 1){
        e.native.target.style.cursor = 'pointer';
      }else {
        e.native.target.style.cursor = '';
      }
    },
    onClick(e, chartElement) {
      
      if(chartElement.length > 0){
      const { index } = chartElement[0];

      const { prevBusiness } = byPrevBusiness;

      let increment;
      
      if(prevBusiness.length > 0){
        if(chartMode === 'Amount'){
        const curr = parseFloat(dataChart.datasets[0].data[index]);

        const prevItemSelected = (prevBusiness.filter(elm => elm.business === dataChart.labels[index]));

        const prev = parseFloat(prevItemSelected[0].total_amount);

        increment = getIncrease(curr, prev);

        } else {
          const curr = parseFloat(dataChart.datasets[0].data[index]);

          const prevItemSelected = (prevBusiness.filter(elm => elm.business === dataChart.labels[index]));

          const prev = parseFloat(prevItemSelected[0].total_units_sold);

          increment = getIncrease(curr, prev);
        }

      }else {
        increment = 0;
      }
      
      dispatch(
        selectBusiness({
          business:  dataChart.labels[index],
          icon:      byBusiness[index].color[0].icon,
          units:     dataChart.datasets[0].units[index],
          iconColor: byBusiness[index].color[0].color,
          amount:    dataChart.datasets[0].amount[index],
          color:     byBusiness[index].color[0].colorRGBA2,
      }));
      

      setSelectedBusiness({
        ...selectedBusiness,
        index,
        incremet: (increment).toLocaleString(locale,
        { 
          maximumFractionDigits:1,
          minimumFractionDigits:1
        }),
        average: chartMode === "Amount" && byBusiness.length  > 0? byBusiness[index].percent_amount : byBusiness[index].percent_units
      })
        
      }
    },
    plugins:{
      legend: {
        display: false,
      },
      tooltip:{
        intersect: true,
        displayColors: true,
        titleFont: {
          family:'Poppins',
        },
        titleMarginBottom:7,
        titleAlign: 'center',
        interaction:{
          mode:'index'
        },
        callbacks:{
          label: function(tooltipItem) {
            return (' ' + tooltipItem.label + " ");
          },
        },
      },
      percent: {
       fontColor: selectedBusiness &&
       selectedBusiness.iconColor,
       percent: selectedBusiness &&
       selectedBusiness.incremet
      },
      labelChartCenter:{
        prevMonth: byPrevBusiness.prevMonthName
      },
      labelChartOut:{
        index: selectedBusiness.index,
        average: selectedBusiness.average && (selectedBusiness.average).toFixed(1),
        fontColor: iColor
      }

    },
    
  };

  if(window.screen.width > 1600 && window.screen.height > 800){
    chartHeight = "480px";
    percentLabelSize = "30px";
  }

  return (
    <Fragment>
      {
        byBusiness.length > 0 ?
        ( <>
            <Row className="d-flex justify-content-center align-items-evenly mco-fade-in p-0" > 
              <Col
                lg={ 7 } 
                className="scrollable-element p-0"
                style={{
                  "marginLeft": "0px"
                }}
              >
                <Card.Title className="text-center mb-5">
                  <span className="mco-business-card__title--size">
                    Grafica en funcion de 
                    {
                      chartMode === 'Amount' ?
                        (<span className="text-success" >
                        {'  '}  Monto Facturado
                        </span>)
                        :(<span className="text-primary" >
                        {' '} Unidades Vendidas
                        </span>)
                    }
                  </span>
                </Card.Title>
                <div className="">
                  <Doughnut
                    ref={canvas}
                    data={dataChart}
                    options={options}
                    height={chartHeight}
                    className="zindex-4 "
                    plugins={[percent, labelChartCenter, labelChartOut]}
                  />    
                </div>
              </Col>

              <Col
                lg={ 4 }
                className="d-flex flex-column align-items-end"
              >
                  <BusinessInfoCard 
                    data={name}
                    color={color}
                    icon={icon}
                    iColor={iColor}
                    title="Negocio"
                  />
                  <BusinessInfoCard 
                    data={amountCard && amountCard.amount}
                    color={color}
                    percent={true}
                    total={totals.amount}
                    icon={amountCard && amountCard.amountIcon}
                    iColor={iColor}
                    title="Monto Facturado"
                    prefix={ amountCard && amountCard.prefix}
                  />
                  <BusinessInfoCard 
                  data={unitsCard && unitsCard.units}
                  color={color}
                  percent={true}
                  total={totals.units}
                  icon={unitsCard && unitsCard.unitsIcon}
                  iColor={iColor}
                  title="Unidades Vendidas"
                  />
              </Col>                  
            </Row>
          </>)
          :(
            <Card.Body className="d-flex align-items-center mb-2 me-2">
              <div className="d-flex mco-fade-in justify-content-center align-items-center mx-auto w-75 mb-2">
                <div className="py-3 px-4 badge-soft-danger rounded-lg  badge">
                  <span className="fs-4">
                      No hay datos para mostrar
                  </span>
                </div>
              </div>
            </Card.Body>
        )
      }
    </Fragment>
  )
}

export default BusinessPieChart