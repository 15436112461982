import { useContext } from "react";

import {
  DistributorsContext
} from "../contexts/DistributorsContext";


const useDistributorsData = () => {
  const DistriContext = useContext(DistributorsContext);

  if (!DistriContext)
    throw new Error("DistributorsContext must be placed within AuthProvider");

  return DistriContext;
};

export default useDistributorsData;
