import React from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";

import {McoDiscountsOverview} from "./McoDiscountsOverview";

const McoDiscounts = () => {

  return (<React.Fragment>
    <Helmet title="Reportes de Descuentos" />
    <Container fluid className="p-0">
      <Row>
        <Col lg="12">
          <McoDiscountsOverview />
        </Col>
      </Row>
    </Container>
  </React.Fragment>
)};

export default McoDiscounts;
