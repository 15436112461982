import { Fragment, useEffect, useState, memo, useCallback, useRef } from "react";
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Legend } from 'chart.js';
import moment from "moment/min/moment-with-locales";
import {
  Card,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { MCO_CONFIGS } from "../../../../constants";
import useDistributorsData from "../../../../hooks/useDistributorsData";
import usePalette from "../../../../hooks/usePalette";
import { ProgressBarLoader } from "../../../../components/mco/components/McoLoaders";
import OrdersTable from "../distributors-components/OrdersTable";


ChartJS.register(ArcElement, Legend);

const DistributorsCharts = ({ currDistriData, prevDistriData, currentMonth }) => {

  const {
    loading,
    subtractMonthString,
    getIncrease,
  } = useDistributorsData();

  const {
    currMonthName,
    prevMonthName
  } = subtractMonthString(currentMonth);
  
  const chartRef = useRef(null);

  const palette = usePalette();
  const { locale } = MCO_CONFIGS;

  const [ incrementPercents, setIncrementPercents ] = useState([]);
  const [ currentData, setCurrentData ] = useState([]);
  const [ orderPercent, setOrderPercent ] = useState(0);
  const [ progressBar, setProgressBar ] = useState(0);
  const [ prevMonth, setPrevMonth ] = useState(
    moment(currentMonth.split('=')[1] || currentMonth)
    .subtract(1, 'months').locale("es")
    .format('MMMM')
  );
  const [ configChart, setConfigChart ] = useState({
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        data: [],
        backgroundColor: [
        ],
        borderWidth: 4,
        borderColor: palette.white,
        hoverOffset: 10,
      },
    ],
  });

  const processingDistributorsData = (
    currData = currDistriData,
    prevData = prevDistriData
  ) => {

    setProgressBar(prev => prev + 15);

    setCurrentData(currData);

    setConfigChart({
      ...configChart,
      labels: currData.map( labels => labels.label),
      datasets: [
        {
          ...configChart.datasets[0],
          data: currData.map( orders => orders.amount),
          backgroundColor: currData.map( states => states.color)
        }
      ]
    });

    setTimeout(() => {
      setProgressBar(prev => prev + 20);
    }, 1500);

    setPrevMonth(prevMonthName);

    let incrementData = [];

    if(currData.length > 0){
      currData.forEach( (currOrders) => {
        if(!prevData.map( orders => orders.label).includes(currOrders.label)){
          incrementData.push({
            label: currOrders.label,
            increment: 0,
            color: currOrders.color
          })
        }else {
          prevData.forEach( prevOrders => {
            currOrders.label === prevOrders.label &&
              incrementData.push(
                {
                  label: prevOrders.label,
                  increment: prevOrders.amount !== 0 &&
                    getIncrease(currOrders.amount, prevOrders.amount),
                  color: currOrders.color
                }
              )
          });
        }
      });

      setProgressBar(prev => prev + 10);
      setOrderPercent({
        name: incrementData[0].label,
        percent: incrementData[0].increment,
        color: incrementData[0].color
      });
      setIncrementPercents(incrementData);

    }
  }

  const processing =  useCallback(
    processingDistributorsData,
    [currDistriData, prevDistriData],
  )

  useEffect(() => {
    processing();
    setProgressBar(prev => prev + 10);
  }, [currDistriData, prevDistriData])

  let chartHeight = '90%';
  if(window.screen.width > 1600 && window.screen.height > 800){
    chartHeight = '60%';
  }

  const percent = {
    id: 'percent',
    beforeDraw( chart, args, options ){
      const { ctx, chartArea:{ right, bottom } } = chart;
      ctx.save();
      ctx.font = '29px Poppins';
      ctx.textAlign = 'center';
      ctx.fillStyle = options.fontColor;
      if(options.percent !== 0){
        if(options.percent > 0){
          ctx.fillText(
          '+' + (options.percent).toLocaleString(locale, {
            maximumFractionDigits:1,
            minimumFractionDigits:1
          }) + '%', (right/2), (bottom/2))
        }else{
          ctx.fillText((options.percent).toLocaleString(locale, {
            maximumFractionDigits:1,
            minimumFractionDigits:1
          }) + '%', (right/2), (bottom/2));
        }
      }else {

      }
    },
  };

  const labelChartCenter = {
    id: 'labelChartCenter',
    beforeDraw( chart, args, options ){
      const { ctx, chartArea:{ right, bottom } } = chart;
      ctx.save();
      ctx.font = '13px Poppins';
      ctx.textAlign = 'center';
      if(options.percent !== 0){
        ctx.fillStyle = options.fontColor;
        ctx.fillText(options.label,
        (right/2), ((bottom/2)+28)
        );
        ctx.fillStyle = palette.secondary;
        ctx.fillText(' vs ' + (options.prevMonth),
        (right/2), ((bottom/2)+48)
        );
      } else {
        ctx.fillStyle = palette.secondary;
        ctx.fillText('No hubo ordenes ' ,
        ((right/2)), ((bottom/2))
        );
        ctx.fillStyle = options.fontColor;
        ctx.fillText( options.label ,
        ((right/2)), ((bottom/2)+20)
        );
        ctx.fillStyle = palette.secondary;
        ctx.fillText(' en ' + (options.prevMonth),
        ((right/2)), ((bottom/2)+40)
        );
      }
    }
  };

  const options = {
    maintainAspectRatio: true,
    responsive: true,
    radius: chartHeight,
    cutout: '83%',
    interactions:{
      hoverOffset: '20px',
      hoverBorderColor: palette.black
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
    onHover: (e, chartElement) => {
      if(chartElement.length === 1){
        e.native.target.style.cursor = 'pointer';
      }else {
        e.native.target.style.cursor = '';
      }
    },
    onClick(click, element, chart){
      if (element[0]) {
        click.native.target.style.transitionTimingFunction = 'ease-out';
        click.native.target.style.transition = '0.3s';
        click.native.target.style.transform = 'translateY(1.5)';
        let orderPercents = {};
        incrementPercents.forEach( ({ label, increment, color }, index) => {
          
          if( element[0].index === index){
            orderPercents = {
            name: label,
            percent: increment,
            color
            };
          }
        })
        setOrderPercent(orderPercents);
        chart.draw();
      }
    },
    plugins: {
      legend:{
        display: false
      },
      tooltip: {
        intersect: true,
        callbacks: {
          label: function(tooltipItem) {
            return (' ' + tooltipItem.label + ": " +
             "$ " + parseFloat(tooltipItem.parsed).toLocaleString(locale));
          },
        },
      },
      percent: {
        fontColor: palette.secondary,
        percent: orderPercent &&
        orderPercent.percent &&
        orderPercent.percent
      },
      labelChartCenter:{
        label: orderPercent && orderPercent.name,
        prevMonth,
        fontColor: orderPercent && orderPercent.color,
        percent: orderPercent &&
        orderPercent.percent &&
        orderPercent.percent
      },
    },
    layout:{
      padding: {
        bottom: 15,
        top:10
      }
    }
  };

  return (
    <Fragment>
     {
        progressBar < 100 &&
          <ProgressBarLoader progress={ progressBar } title="Distribuidores" />
     }
     {
        currentData.length === 0?
        (
          <Fragment>
            {
              progressBar >= 100 &&
                <Row className="mco-fade-in d-flex justify-content-center align-items-center" >
                  <Col lg="5" sm="7" xs="7" md="5">
                    <Card className="shadow-lg d-flex justify-content-center align-items-center">
                      <Card.Header className=" mt-3">
                        <Col className="d-flex justify-content-between ">
                          <Col  className="" >
                            <Card.Title tag="h5" className="mb-0 fs-4 mt-2">
                              <h3>
                                Reporte por distribuidor
                              </h3>
                            </Card.Title>
                          </Col>
                        </Col>
                      </Card.Header>
                      <Card.Body className="d-flex align-items-center mb-2 me-2">
                        <div className="d-flex mco-fade-in justify-content-center align-items-center mx-auto w-75 mb-2">
                          <div className="py-3 px-4 badge-soft-danger rounded-lg  badge">
                            <span className="fs-4">
                                No hubo ordenes en {' '} { currMonthName }
                            </span>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
            }
          </Fragment>

        ):(
          progressBar >= 100 &&
            <Row className="d-flex justify-content-center mco-fade-in">
              <Card className="d-flex">
                <Row className="align-items-center">
                  <Card.Header className="py-0">
                    <Col className="d-flex justify-content-between ">
                      <Col  className="" >
                        <Card.Title tag="h5" className="mb-0 fs-4 mt-4">
                          {
                            loading &&
                              <Spinner
                                animation="grow"
                                variant="dark"
                                type="grow"
                                size="sm"
                                className="mx-3"
                              />
                          }
                        </Card.Title>
                      </Col>
                    </Col>
                  </Card.Header>

                  <Col sm={6} md={7} lg={5} >
                    <Card.Body
                      style={{
                        "paddingLeft": "0px",
                      }}
                    >
                      <div className="align-self-center w-100 mco-fade-in">
                        <div className="mco-fade-in d-flex align-items-center" >
                          <Doughnut
                            ref={chartRef}
                            data={configChart}
                            options={options}
                            plugins={[percent, labelChartCenter]}
                            style={{
                              "height":"600px"
                            }}
                          />
                        </div>
                      </div>
                    </Card.Body>
                  </Col>

                  {/* TABLA */}
                  {
                    progressBar >= 100 &&
                      <OrdersTable currentData={currentData}/>
                  }
                </Row>
              </Card>
            </Row>

        )
      }
    </Fragment>
  )
};

export default memo(DistributorsCharts);
