const locations = [
	{
			"iso_id": "AR-N",
			"name": "MISIONES",
			"iso_name": "Misiones",
			"short_name": "Misiones",
			"latitude": -26.8753965086829,
			"longitude": -54.6516966230371,
			"latLng": [
					-26.8753965086829,
					-54.6516966230371
			]
	},
	{
			"iso_id": "AR-D",
			"name": "SAN LUIS",
			"iso_name": "San Luis",
			"short_name": "San Luis",
			"latitude": -33.7577257449137,
			"longitude": -66.0281298195836,
			"latLng": [
					-33.7577257449137,
					-66.0281298195836
			]
	},
	{
			"iso_id": "AR-J",
			"name": "SAN JUAN",
			"iso_name": "San Juan",
			"short_name": "San Juan",
			"latitude": -30.8653679979618,
			"longitude": -68.8894908486844,
			"latLng": [
					-30.8653679979618,
					-68.8894908486844
			]
	},
	{
			"iso_id": "AR-E",
			"name": "ENTRE RÍOS",
			"iso_name": "Entre Ríos",
			"short_name": "Entre Ríos",
			"latitude": -32.0588735436448,
			"longitude": -59.2014475514635,
			"latLng": [
					-32.0588735436448,
					-59.2014475514635
			]
	},
	{
			"iso_id": "AR-Z",
			"name": "SANTA CRUZ",
			"iso_name": "Santa Cruz",
			"short_name": "Santa Cruz",
			"latitude": -48.8154851827063,
			"longitude": -69.9557621671973,
			"latLng": [
					-48.8154851827063,
					-69.9557621671973
			]
	},
	{
			"iso_id": "AR-R",
			"name": "RÍO NEGRO",
			"iso_name": "Río Negro",
			"short_name": "Río Negro",
			"latitude": -40.4057957178801,
			"longitude": -67.229329893694,
			"latLng": [
					-40.4057957178801,
					-67.229329893694
			]
	},
	{
			"iso_id": "AR-U",
			"name": "CHUBUT",
			"iso_name": "Chubut",
			"short_name": "Chubut",
			"latitude": -43.7886233529878,
			"longitude": -68.5267593943345,
			"latLng": [
					-43.7886233529878,
					-68.5267593943345
			]
	},
	{
			"iso_id": "AR-X",
			"name": "CÓRDOBA",
			"iso_name": "Córdoba",
			"short_name": "Córdoba",
			"latitude": -32.142932663607,
			"longitude": -63.8017532741662,
			"latLng": [
					-32.142932663607,
					-63.801753274166
			]
	},
	{
			"iso_id": "AR-M",
			"name": "MENDOZA",
			"iso_name": "Mendoza",
			"short_name": "Mendoza",
			"latitude": -34.6298873058957,
			"longitude": -68.5831228183798,
			"latLng": [
					-34.6298873058957,
					-68.5831228183798
			]
	},
	{
			"iso_id": "AR-F",
			"name": "LA RIOJA",
			"iso_name": "La Rioja",
			"short_name": "La Rioja",
			"latitude": -29.685776298315,
			"longitude": -67.1817359694432,
			"latLng": [
					-29.685776298315,
					-67.181735969443
			]
	},
	{
			"iso_id": "AR-K",
			"name": "CATAMARCA",
			"iso_name": "Catamarca",
			"short_name": "Catamarca",
			"latitude": -27.3358332810217,
			"longitude": -66.9476824299928,
			"latLng": [
					-27.3358332810217,
					-66.9476824299928
			]
	},
	{
			"iso_id": "AR-L",
			"name": "LA PAMPA",
			"iso_name": "La Pampa",
			"short_name": "La Pampa",
			"latitude": -37.1315537735949,
			"longitude": -65.4466546606951,
			"latLng": [
					-37.1315537735949,
					-65.4466546606951
			]
	},
	{
			"iso_id": "AR-G",
			"name": "SANTIAGO DEL ESTERO",
			"iso_name": "Santiago del Estero",
			"short_name": "S. del Estero",
			"latitude": -27.7824116550944,
			"longitude": -63.2523866568588,
			"latLng": [
					-27.7824116550944,
					-63.2523866568588
			]
	},
	{
			"iso_id": "AR-W",
			"name": "CORRIENTES",
			"iso_name": "Corrientes",
			"short_name": "Corrientes",
			"latitude": -28.7743047046407,
			"longitude": -57.8012191977913,
			"latLng": [
					-28.7743047046407,
					-57.8012191977913
			]
	},
	{
			"iso_id": "AR-S",
			"name": "SANTA FE",
			"iso_name": "Santa Fe",
			"short_name": "S. Fe",
			"latitude": -30.7069271588117,
			"longitude": -60.9498369430241,
			"latLng": [
					-30.7069271588117,
					-60.9498369430241
			]
	},
	{
			"iso_id": "AR-T",
			"name": "TUCUMÁN",
			"iso_name": "Tucumán",
			"short_name": "Tucumán",
			"latitude": -26.9478001830786,
			"longitude": -65.3647579441481,
			"latLng": [
					-26.9478001830786,
					-65.3647579441481
			]
	},
	{
			"iso_id": "AR-Q",
			"name": "NEUQUÉN",
			"iso_name": "Neuquén",
			"short_name": "Neuquén",
			"latitude": -38.6417575824599,
			"longitude": -70.1185705180601,
			"latLng": [
					-38.6417575824599,
					-70.1185705180601
			]
	},
	{
			"iso_id": "AR-A",
			"name": "SALTA",
			"iso_name": "Salta",
			"short_name": "Salta",
			"latitude": -25.111769,
			"longitude": -65.410493,
			"latLng": [
					-25.111769,
					-65.410493
			]
	},
	{
			"iso_id": "AR-H",
			"name": "CHACO",
			"iso_name": "Chaco",
			"short_name": "Chaco",
			"latitude": -26.3864309061226,
			"longitude": -60.7658307438603,
			"latLng": [
					-26.3864309061226,
					-60.7658307438603
			]
	},
	{
			"iso_id": "AR-P",
			"name": "FORMOSA",
			"iso_name": "Formosa",
			"short_name": "Formosa",
			"latitude": -24.894972594871,
			"longitude": -59.9324405800872,
			"latLng": [
					-24.894972594871,
					-59.932440580087
			]
	},
	{
			"iso_id": "AR-Y",
			"name": "JUJUY",
			"iso_name": "Jujuy",
			"short_name": "Jujuy",
			"latitude": -23.3200784211351,
			"longitude": -65.7642522180337,
			"latLng": [
					-23.3200784211351,
					-65.7642522180337
			]
	},
	{
			"iso_id": "AR-C",
			"name": "CIUDAD AUTÓNOMA DE BUENOS AIRES",
			"iso_name": "Ciudad Autónoma de Buenos Aires",
			"short_name": "CABA",
			"latitude": -34.6144934119689,
			"longitude": -58.4458563545429,
			"latLng": [
					-34.6144934119689,
					-58.4458563545429
			]
	},
	{
			"iso_id": "AR-B",
			"name": "BUENOS AIRES",
			"iso_name": "Buenos Aires",
			"short_name": "Buenos Aires",
			"latitude": -36.6769415180527,
			"longitude": -60.5588319815719,
			"latLng": [
					-36.6769415180527,
					-60.5588319815719
			]
	},
	{
			"iso_id": "AR-V",
			"name": "TIERRA DEL FUEGO",
			"iso_name": "Tierra del Fuego",
			"short_name": "Tierra del Fuego",
			"latitude": -82.52151781221,
			"longitude": -50.7427486049785,
			"latLng": [
					-82.52151781221,
					-50.74274860497
			]
	}
];

export { locations };