import { Fragment } from "react";
import { Card, Row, Col, Spinner } from "react-bootstrap";
import { MCO_CONFIGS } from "../../../../constants";

import usePalette from "../../../../hooks/usePalette";
import { Bar } from "react-chartjs-2";
 
const DiscountsCharts = ({ dataState, loading }) => {
  
  const palette = usePalette();
  const { locale } = MCO_CONFIGS;

  let xstate  = [];
  let xdata   = [];
  let xlabels = [];
  let tmpData = [];

  let total_amount=0;
  let total_used=0; 
  let newString = '';
  let start = 0;
  let myname = '';

 //step 1 : extract pure names ej: 13423.caramelos.30% -> caramelos.30% to tmpData
 
 dataState && dataState.forEach((e, i) => {
    xstate = [];        
    if ( e.name.indexOf('empleado-')===-1 ) {

      start = e.name.indexOf('.');
      total_used = e.total_used ; 
      total_amount = e.total_amount_discounted;
  
      if(start>-1){ //find dot => slice
        myname = e.name;
        newString = myname.slice(start+1);
      }else{
        newString = e.name;
      }

      xstate = {'couponName':newString , 'coupons': total_used, 'total': total_amount};
      tmpData.push(xstate);
    }
  });

  if (tmpData!=null){
    let idx;
    //step 2 : reduce array by coupon name 

    const filteredCoupons = tmpData.reduce((acum, item) => {
      idx = acum[0].indexOf(item.couponName)
      if (idx  > -1) {
        acum[1][idx].total += item.total
        acum[1][idx].coupons += item.coupons
      } else {
        acum[0].push(item.couponName);
        acum[1].push(item);
      }

      return acum;
    }, [[],[]]);

    //step 3: order by used coupons
    const filteredAndOrderedCoupons = filteredCoupons[1].sort((a,b) => (b.coupons - a.coupons));
      
    //step 4 : output limited to 10
    let count = 0;
    Object.entries(filteredAndOrderedCoupons).forEach (([e, item])  => {
      if(count<10){
        xstate=[];
        xdata.push (item.coupons); 
        xlabels.push([item.couponName , item.total ? ' $' + Number(item.total).toLocaleString(locale, { maximumFractionDigits: 2 }) : ' $' +0]);
      }
      count++;
      
    });
  }

  const data = {
    labels: xlabels,
    datasets: [
      {
        label: "Cupones usados", 
        data: xdata,
        backgroundColor: [
          palette.primary,
          palette.success,
          palette.danger,
          palette.warning,
          "#5b7dff",
          palette.primary,
          palette.success,
          palette.danger,
          palette.warning,
          "#5b7dff", 
        ],
        barPercentage: 1,
        categoryPercentage: 0.7,
      },
    ]
  }

const options = {
  
  maintainAspectRatio: false,
 
  scales: {
    y: 
      {
        grid: {
          display: true,
        },
        stacked: false,
        ticks: {
          stepSize: 50,
        },
      },
    x: 
      {
        stacked: false,        
        grid: {
          display: false,
          color: "transparent",
        },
      },
  },
  plugins:{
    legend: {
      display: false,
    },
  } 
};

  return (
    <Fragment>
      <Row className="d-flex justify-content-center">
 
        <Col lg={12} sm >
          <Card className="flex-fill flex-sm-fill">
            <Card.Header>
              <Card.Title tag="h5" className="mb-0 fs-4">
                 10 Cupones más usados
                  {
                    loading &&
                      <Spinner
                        animation="grow"
                        variant="dark"
                        type="grow"
                        size="sm"
                        className="mx-3"
                      />
                  }
                   <h6 className="card-subtitle text-muted mt-2">No incluye cupones de empleados</h6>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {
                data.length !== 0  ?
                  (
                    <div className="align-self-center w-100">
                      <div className="py-3">
                        <div className="" >
                          <Bar data={data} options={options} height="500"/>
                        </div>
                      </div>
                     
                    </div>
                  ) :
                  (
                    <div className="d-flex justify-content-center p-2 w-100">
                      <h3>
                        No hay datos para mostrar
                      </h3>
                    </div>
                  )
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>


  );
};

export default DiscountsCharts;
