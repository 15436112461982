import { useEffect, useState } from 'react'
import { types } from '../types/types';
import { useDispatch, useSelector } from 'react-redux';
import McoToken from '../components/McoToken';
import moment from "moment/min/moment-with-locales";
import { ConfigService, OpenAPI } from '../services/openapi';


const { configList } = ConfigService;

export const useSelectDate = () => {

  const dispatch = useDispatch();
  const firstMonth = moment("2020-06");
  const [ data , setData ] = useState([])
  const monthBeforeLast = moment().subtract(1,'month'); // Cambiar primer parametro de subtract a 2 cada vez que se hace la bajada de nueva data 
  const initialCurrentMounth = monthBeforeLast.format("YYYY-MM");
  const [ monthList, setMonthList ] = useState([{
    value: undefined,
    label: undefined
  }]);
  const [ currentMonth, setCurrentMonth ] = useState(initialCurrentMounth);
  // const [ currentMonth, setCurrentMonth ] = useState(null); solucion
  
  const { last_date_formats, selected_date_formats } =  useSelector( state => state.date );

  const getNewMonth = async() => {
    OpenAPI.TOKEN = McoToken();
    try {

      const config = await configList();

      if(config.success){
        return config.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getNewMonth().then((data) => {

      const date = (data.filter( data => data.option === 'show_max_month'));
      
      if (date) {
        setData(date);
        
        const lastM = moment(date[0].values);

        const currMonth = lastM.format("YYYY-MM");

        dispatch({
          type: types.selected_date,
          payload:{
            date: currMonth
          }
        })

        setCurrentMonth(currMonth);

        let selectMonths = [{
          value: lastM.format("YYYY-MM"),
          label: lastM.locale("es").format("MMMM YY")
        }];
  
        while( lastM >= firstMonth ){

          lastM.subtract(1,'month');

          selectMonths.push({
            value: lastM.format("YYYY-MM"),
            label: lastM.locale("es").format("MMMM YY")
          });
        }
        setMonthList(selectMonths)
      }
    }).catch(err => {
      console.log(err);
      setCurrentMonth(initialCurrentMounth);
      setData([]);
    });
  }, [])
  
  const handleCurrentMonth = (e) => {
    setCurrentMonth(e.value);
    dispatch({
      type: types.selected_date,
      payload:{
        date: e.value
      }
    })
  }

  return [
    currentMonth,
    handleCurrentMonth,
    monthList,
    data,
  ]
}
