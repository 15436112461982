import { Card, Col, Row } from "react-bootstrap"

export const McoEmptyDataAlert = () => {
  return (
    <Row className="d-flex justify-content-center">
      <Col lg={4}>
        <Card className="p-3">
          <Card.Body className="text-center">
            <h3 >No hay datos para mostrar</h3> 
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
