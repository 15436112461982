import { useContext } from "react";
import { Pagination, Row, Col, Form } from "react-bootstrap";
import { McoAutoTableContext } from "./McoAutoPaginatedTable";


const McoAutoControlPage = ({loader}) => {

  const {
    pageSize,
    gotoPage,
    nextPage,
    pageCount,
    pageIndex,
    setPageSize,
    pageOptions,
    canNextPage,
    previousPage,
    canPreviousPage,
    customPageSize
  } = useContext(McoAutoTableContext);

  return (
    <Row className="mt-2">
        <Col md="8">
          <span className="mx-2">
            Página{" "}
            <strong>
              {pageIndex + 1} de {pageOptions.length}
            </strong>
          </span>
          <span className="ms-3 me-2">Mostrar:</span>
          <Form.Select
            className="d-inline-block w-auto"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[customPageSize, 30, 40, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Form.Select>

          <span className="ms-3 me-2">Ir a la página:</span>
          <Form.Control
            className="d-inline-block"
            type="number"
            min={1}
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "75px" }}
          />
        </Col>
        <Col md="4">
          <Pagination className="float-end">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
  )
}

export default McoAutoControlPage