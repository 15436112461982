import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTable, useSortBy, useFilters, useGlobalFilter } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
  faCheck,
  faTimes,
  faChartPie
} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  Container,
  Col,
  Row,
  Table,
  Spinner,
  Form,
  NavLink
} from "react-bootstrap";
import { MCO_CONFIGS } from "../../../constants";
import useDistributorsData from "../../../hooks/useDistributorsData";
import { useSelectDate } from "../../../hooks/useSelectDate";
import { ExportCSVButton } from "../../../components/mco/components/ExportCSVButton";
import { SuspensivePointsLoader } from "../../../components/mco/components/McoLoaders";
import DateSelector from "../../../components/mco/components/DateSelector";


function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter, Header },
}) {
  //const count = preFilteredRows.length;
  return (
    <Form.Control
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      onClick={ (e) => {
				e.stopPropagation();
      }}
      placeholder={`Filtrar ${Header}`}
      className=" mt-2"
    />
  );
}

const McoDistributorsTable = ({ columns, data, loading }) => {

		const filterTypes = React.useMemo(
			() => ({
			// Or, override the default text filter to use
			// "startWith"
			text: (rows, id, filterValue) => {
				return rows.filter( row => {
				const rowValue = row.values[id];
				return rowValue !== undefined
					? String(rowValue)
						.toLowerCase()
						.startsWith(String(filterValue).toLowerCase())
					: true;
				});
			},
		}),[]);

		const defaultColumn = React.useMemo(
			() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),[]);

		const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
			columns,
			data,
			filterTypes,
			defaultColumn,
		},
			useFilters, // useFilters!
			useGlobalFilter, // useGlobalFilter!
			useSortBy,
		);

		const [ dataProcessed, setDataProcessed ] = useState([]);

		useEffect(() => {
			const data_processed = data.map((e) => {
			    return {
			        "ID" : e.dist_id,
			        "NOMBRE" : e.dist_name,

			        "CANT. COMPLETADAS" : (e.total_orders && e.total_orders['wc-completed'] && e.total_orders['wc-completed']['total_orders']) ? e.total_orders['wc-completed']['total_orders']: 0,

			        "MONTO FACTURADO" : (e.total_orders && e.total_orders['wc-completed'] && e.total_orders['wc-completed']['total_amount']) ? (parseFloat(e.total_orders['wc-completed']['total_amount'])).toLocaleString("es-AR", { maximumFractionDigits: 3, minimumFractionDigits:3 }) : 0,

			        "CANT. CANCELADAS" : (e.total_orders && e.total_orders['wc-cancelled'] && e.total_orders['wc-cancelled']['total_orders']) ? e.total_orders['wc-cancelled']['total_orders']: 0,

			        "MONTO CANCELADO" : (e.total_orders && e.total_orders['wc-cancelled'] && e.total_orders['wc-cancelled']['total_orders']) ? (parseFloat(e.total_orders['wc-cancelled']['total_amount'])).toLocaleString("es-AR", { maximumFractionDigits: 3, minimumFractionDigits:3 }) : 0,

			        "MERCADO PAGO" : (e.mp_enabled && Number(e.mp_enabled) === 1 )? "SI" : "NO",
			        "LYRA" : (e.mp_enabled && Number(e.lyra_enabled) === 1 )? "SI" : "NO",
			        "CALENDARIO" : (e.mp_enabled && Number(e.calendar_enabled) === 1 )? "SI" : "NO",
			    };
			});
			setDataProcessed( data_processed );
		}, [ data ]);

	return (
		<Card>
			<Card.Header>
				<ExportCSVButton  data={dataProcessed} fileName="DistributorsReport" />
	      <Card.Title tag="h5" className="fs-3">Distribuidores {loading && (<Spinner animation="grow"variant="dark"type="grow"size="sm"className="mx-3 cursor-pointer"/>) }</Card.Title>
	      <h6 className="card-subtitle text-muted">
	      </h6>
	    </Card.Header>
	    <Table responsive striped {...getTableProps()} size="sm">
	      <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} className={column.headerClassName}>
                  {column.render("Header")}
                  {column.accessor? (
								  <span>
				            {column.isSorted ? (
				              column.isSortedDesc ? (
				                <FontAwesomeIcon icon={faSortUp} className="ms-2" />
				              ) : (
				                <FontAwesomeIcon icon={faSortDown} className="ms-2" />
				              )
				            ) : (
				              <FontAwesomeIcon icon={faSort} className="ms-2" />
				            )}
				          </span>) : ""}
									{column.canFilter ? column.render("Filter") : null}
								</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="mco-fade-in">
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps({className: cell.column.className || "" })}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
	    </Table>
	  </Card>
	);
};

const McoDistributorsTableRender = () => {

	const [mcoDistributorsData, setMcoDistributorsData] = useState([]);

	const { locale } = MCO_CONFIGS;

	const [
		currentMonth,
		handleCurrentMonth,
		selectValues
	] = useSelectDate();

	const {
		loading,
		getAllDistributorsListTable
	} = useDistributorsData();


	useEffect(() => {
		let Mounted = true;
		getAllDistributorsListTable(currentMonth)
		.then( ({currDistributorsData}) => {
			setMcoDistributorsData(currDistributorsData);
		});
		 Mounted = false;
	}, [currentMonth])

	const mcoDistributorsColumns = [
		{
			Header: "ID",
			accessor: "dist_id",
      className: "col-1 text-center",
      headerClassName:"text-center ",
		},
		{
			Header: "Nombre",
			accessor: "dist_name",
      className: "w-25",
		},
		{
			Header: "Órdenes Completadas",
      headerClassName: "text-center",
			columns: [
				{
					Header: "Cant",
					accessor: "total_orders.wc-completed.total_orders",
					Filter: false,
					headerClassName: "col-1 text-right py-4",
          className: "col-1 text-right",
					Cell: (props) => {
						return ( props.value || 0 );
					}
				},
				{
					Header: "Facturación",
					Filter: false,
					accessor: "total_orders.wc-completed.total_amount",
          className: "text-right col-1",
          headerClassName:"text-right py-4",
					Cell: (props) => {
						return "$" + ( props.value? parseInt( props.value ).toLocaleString(locale, { maximumFractionDigits: 2 }) : 0 );
					}
				}
			]
		},
		{
			Header: "Órdenes Canceladas",
      headerClassName: "text-center",
			columns: [
				{
					Header: "Cant",
					Filter: false,
					accessor: "total_orders.wc-cancelled.total_orders",
          className: "text-right col-1",
          headerClassName:"text-right py-4",
					Cell: (props) => {
						return ( props.value || 0 );
					}
				},
				{
					Header: "Facturación",
					Filter: false,
					accessor: "total_orders.wc-cancelled.total_amount",
          className: "text-right col-1",
          headerClassName:"text-right py-4",
					Cell: (props) => {
						return "$" + ( props.value? parseInt( props.value ).toLocaleString(locale, { maximumFractionDigits: 2 }) : 0 );
					}
				}
			]
		},
		{
			Header: "M. Pago",
			Filter: false,
			accessor: "mp_enabled",
      className: "text-center",
      headerClassName:"text-center py-4",
			Cell: ( props ) => (
				<span className={ ( Boolean( parseInt( props.value ) ) === true ) ? " text-success " : "text-danger" }>
					<FontAwesomeIcon  icon={ ( Boolean( parseInt( props.value ) ) === true )? faCheck : faTimes } />
				</span>
			)
		},
		{
			Header: "Lyra",
			Filter: false,
			accessor: "lyra_enabled",
      className: "col-1 text-center",
      headerClassName: "text-center py-4",
			Cell: ( props ) => (
				<span className={ ( Boolean( parseInt( props.value ) ) === true ) ? " text-success " : "text-danger" }>
					<FontAwesomeIcon  icon={ ( Boolean( parseInt( props.value ) ) === true )? faCheck : faTimes } />
				</span>
			)
		},
		{
			Header: "Calendario",
			Filter: false,
			accessor: "calendar_enabled",
      className: "text-center col-1",
      headerClassName:"text-center py-4",
			Cell: ( props ) => (
				<span className={ ( Boolean( parseInt( props.value ) ) === true ) ? " text-success " : "text-danger" }>
					<FontAwesomeIcon  icon={ ( Boolean( parseInt( props.value ) ) === true )? faCheck : faTimes } />
				</span>
			)
		},
    {
      Header: "Detalles",
      Filter:false,
      className: "text-center col-1 cursor-pointer zindex-tooltip",
      headerClassName:"text-center py-4",
      Cell: ({row})=> (
			<NavLink
        href={`/distributors/distributors-details/${row.values.dist_id}/?month=${currentMonth}`}
        className="cursor-pointer"
      >
        <FontAwesomeIcon
					id="faChartPie"
					dataId="faChartPieID"
          icon={ faChartPie }
					className="mco-ease-out"
        />
			</NavLink>
      )
    }
	];

return(
  <React.Fragment>
		<Helmet title="Reporte de Distribuidores" />
		<Container fluid className="p-0" >
			<Row className="d-flex justify-content-between">
				<Col xs="auto" className="d-none d-sm-block fs-1">
          <h3>
             Reportes de Distribuidores
          </h3>
       	</Col>
				<DateSelector 
          selectValues={selectValues}
          handleCurrentMonth={handleCurrentMonth}
        />
			</Row>
			{
				loading &&
					<SuspensivePointsLoader title="Lista de Distribuidores" />
			}
			{
				!loading &&
					<Row className="mb-4 mco-fade-in">
						<McoDistributorsTable
							columns={mcoDistributorsColumns}
							data={mcoDistributorsData}
							loading={loading}
						/>
					</Row>
			}
		</Container>
		</React.Fragment>

);
};

export default McoDistributorsTableRender;
