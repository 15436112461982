import { Card, Col, ProgressBar, Row, Spinner } from "react-bootstrap";

const ProgressBarLoader = ({ progress = 0, title = ''}) => {

  return(
    <Row className="mco-fade-in py-3 d-flex justify-content-center align-items-center" >
      <Col xs="5" >
        <Card className="shadow-lg d-flex justify-content-center align-items-center">
          <Card.Header className="py-1 mt-3">
            <Col className="d-flex justify-content-between ">
              <Col  className="" >
                <Card.Title tag="h5" className="mb-0 fs-4 mt-2">
                  <h3>
                    Cargando { title }
                  </h3>
                </Card.Title>
              </Col>
            </Col>
          </Card.Header>
          <div className=" mx-auto w-75 py-3 mt-2 mb-2">
            <ProgressBar
              className="mb-2"
              variant="secondary"
              now={progress}
              label=""
            />
          </div>
          <span className="mb-3 mco-fade-in fs-4 p-2" >
          Obteniendo datos...
          </span>
        </Card>
      </Col>
    </Row>
  )
}

const SuspensivePointsLoader = ({title = '', size = 'sm'}) => {
  return (
    <>
      <div className="text-center">
        <div  className="d-flex p-0 justify-content-between align-items-center mx-auto"
        style={{
          "width": "150px"
        }}
        >
            <div className="text-center mt-6 py-6">
              <Spinner
                animation="grow"
                variant="secondary"
                type="grow"
                size={size}
                className=""
              />
            </div>
            <div className="text-center mt-6 py-6">
              <Spinner
                animation="grow"
                variant="secondary"
                type="grow"
                size={size}
                className=""
              />
            </div>
            <div className="text-center mt-6 py-6">
              <Spinner
                animation="grow"
                variant="secondary"
                type="grow"
                size={size}
                className=""
              />
            </div>
        </div>
        <div className="mb-3 mco-fade-in p-0 fs-4 text-center mt-0" >
          Obteniendo datos de {title}...
        </div>
      </div>
    </>
  )
}

const PointLoader = (size="sm") => {
  return(
      <Spinner
        animation="grow"
        variant="secondary"
        type="grow"
        size="sm"
        className=""
      />
  )
}

const RingLoader = ({size="sm", variant='secondary'}) => {
  return(
    <Spinner 
      animation="border"
      variant={variant}
      className="border-3"
      size={size}
    />
  )
}


export {
  ProgressBarLoader,
  SuspensivePointsLoader,
  PointLoader,
  RingLoader
}