import { types } from "../types/types"

const initialState = {
  show: 25
}

export const reportsReducer = (state = initialState, action) => {
 
  switch (action.type) {
    case types.few:
      return {
        ...state,
        show: action.payload
      }

    case types.medium:
      return {
        ...state,
        show: action.payload
      }

    case types.many:
      return {
        ...state,
        show: action.payload
      }  
    default:
      return state;
  }
}