/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrdersService {

    /**
     * Get orders make by an specific role
     * This can only be done by the logged in user
     * @param perPage per_page
     * @param status status
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @param page page
     * @param _export export
     * @param roleName role_name
     * @returns any Success
     * @throws ApiError
     */
    public static getOdersByRole(
        perPage?: any,
        status: 'FALLIDO' | 'REEMBOLSADO' | 'COMPLETADO' | 'LISTO PARA ENTREGAR' | 'EN ARMADO' | 'PROCESANDO' | 'CANCELADO' | 'PENDIENTE' | 'EN ESPERA' | 'SOLIC. CANCELACIÓN' | 'SOLIC. DEVOLUCIÓN' | 'DEVUELTO' = '',
        startDate?: any,
        page?: any,
        _export: 0 | 1 = 0,
        roleName?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/get-orders-by-role/{role_name}',
            path: {
                'role_name': roleName,
            },
            query: {
                'per_page': perPage,
                'status': status,
                'start_date': startDate,
                'page': page,
                'export': _export,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get Orders List
     * This can only be done by the logged in user
     * @param orderId order_id
     * @param status status
     * @param invoiceNumber invoice_number
     * @param distId dist_id
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static getOrdersList(
        orderId?: number,
        status: 'FALLIDO' | 'REEMBOLSADO' | 'COMPLETADO' | 'LISTO PARA ENTREGAR' | 'EN ARMADO' | 'PROCESANDO' | 'CANCELADO' | 'PENDIENTE' | 'EN ESPERA' | 'SOLIC. CANCELACIÓN' | 'SOLIC. DEVOLUCIÓN' | 'DEVUELTO' = 'COMPLETADO',
        invoiceNumber?: string,
        distId?: number,
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders',
            query: {
                'order_id': orderId,
                'status': status,
                'invoice_number': invoiceNumber,
                'dist_id': distId,
                'per_page': perPage,
                'page': page,
                'export': _export,
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get Ranking Old Orders
     * This can only be done by the logged in user
     * @param orderId order_id
     * @param status status
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @param notDistIds not_dist_ids
     * @returns any Success
     * @throws ApiError
     */
    public static getOldOrdersList(
        orderId?: number,
        status: 'EN ARMADO' | 'EN ESPERA' | 'PENDIENTE' | 'LISTO PARA ENTREGAR' | 'PROCESANDO' = 'PROCESANDO',
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
        startDate?: any,
        notDistIds?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/ranking-old-orders',
            query: {
                'order_id': orderId,
                'status': status,
                'per_page': perPage,
                'page': page,
                'export': _export,
                'start_date': startDate,
                'not_dist_ids': notDistIds,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get orders totals grouped by status
     * Return total orders and total orders amount grouped by status between two dates. This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static ordersByStatus(
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/ranking-orders-by-status',
            query: {
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Return ranking orders and total orders amount grouped by dates between two dates
     * This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static ordersByDates(
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/ranking-orders-by-dates',
            query: {
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get orders totals grouped by business
     * Return total orders and total orders amount grouped by business between two dates. This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static ordersByBusiness(
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/ranking-orders-by-business',
            query: {
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get orders totals grouped by segments
     * Return total orders and total orders amount grouped by segments between two dates. This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static ordersBySegments(
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/ranking-orders-by-segments',
            query: {
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get units sold, net sales and average ticket between two dates
     * This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static ordersTotals(
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/get-totals',
            query: {
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get units sold, net sales and average ticket between two dates
     * This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static ordersTotalUnits(
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/get_total_units_sold',
            query: {
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Ranking of the days of the week to find out which is the day where the most are produced completed orders and billing volume
     * This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @param orderBy Order by ASC to DESC param
     * @returns any Success
     * @throws ApiError
     */
    public static ordersRankingByDays(
        startDate?: any,
        orderBy: 'orders_completed' | 'date' | 'total_amount' | 'average_ticket' = 'orders_completed',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/get-orders-ranking-by-days',
            query: {
                'start_date': startDate,
                'orderBy': orderBy,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Number of orders grouped by state and periods
     * This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @param status status
     * @param perPage per_page
     * @param page page
     * @returns any Success
     * @throws ApiError
     */
    public static ordersState(
        startDate?: any,
        status: 'FALLIDO' | 'REEMBOLSADO' | 'COMPLETADO' | 'LISTO PARA ENTREGAR' | 'EN ARMADO' | 'PROCESANDO' | 'CANCELADO' | 'PENDIENTE' | 'EN ESPERA' | 'SOLIC. CANCELACIÓN' | 'SOLIC. DEVOLUCIÓN' | 'DEVUELTO' = 'COMPLETADO',
        perPage?: any,
        page?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/group-by-states-and-period',
            query: {
                'start_date': startDate,
                'status': status,
                'per_page': perPage,
                'page': page,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

}