import React from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";

import McoDistributorsTable from "./McoDistributorsTable";


const McoDistributors = () => {

  return (<React.Fragment>
    <Helmet title="Reportes de Distribuidores mco-fade-in" />
    <Container fluid className="p-0 mco-fade-in">
      <Row>
        <Col lg="12">
          <McoDistributorsTable />
        </Col>
      </Row>
    </Container>
  </React.Fragment>
)};

export default McoDistributors;
