import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async"; 
import { MCO_CONFIGS } from "../../../constants";
import { Container, Col, Row } from "react-bootstrap";
import { useSelectDate } from '../../../hooks/useSelectDate';
import useProductsData from "../../../hooks/useProductsData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateSelector from '../../../components/mco/components/DateSelector';
import { SuspensivePointsLoader } from "../../../components/mco/components/McoLoaders";
import McoAutoPaginatedTable from "../../../components/mco/components/McoAutoPaginatedTable";
import {  faExternalLinkAlt, faUnlink } from "@fortawesome/free-solid-svg-icons";



const McoAllProducts = () => { 

const {
  loading,
  productList,
  productData,
  exportProductList,
  dataProcessed:exportProducts,
} = useProductsData();

const [ sort  , setSort] = useState( { accessor:'total_amount' , direction:'DESC'} );
const [ currentMonth, handleCurrentMonth, selectValues ] = useSelectDate();
const { locale } = MCO_CONFIGS;

  useEffect(() => {
    productList({ startDate: currentMonth });
    exportProductList({ startDate: currentMonth });
  }, [ currentMonth ]);


/**Set columns */
  const tableColumns = [
    {
      Header: "Sku",
      accessor: "sku",
      headerClassName:"text-center",
      className: "col-1 text-center",
    },
    {
      Header: "Producto",
      accessor: "name",
      headerClassName:"col-3",
      className: "col-3",
    },
    {
      Header: "Negocio",
      accessor: "business",
      Filter:false,
      sortType: 'basic',
      sortDirection: sort.accessor === 'business' ? sort.direction : 'none',
      headerClassName:"",
      className: "col-1",
    },
    {
      Header: "Segmento",
      accessor: "segment",
      Filter:false,
      sortType: 'basic',
      sortDirection: sort.accessor === 'segment' ? sort.direction : 'none',
      headerClassName:"",
      className: "col-2 ",
    },
    {
      Header: "Facturación",
      accessor: "total_amount",
      headerClassName:"text-right",
      className: "col-1 text-right",
      sortType: 'basic',
      sortDirection: sort.accessor === 'total_amount' ? sort.direction : 'none',
      Cell: ( props ) => {
        return props.value ? "$  " + parseFloat(props.value).toLocaleString(locale, {
          maximumFractionDigits:2,
          minimumFractionDigits:2
        }) : 0;
      },
      Filter: false,
    },
    {
      Header: "U Vendidas",
      accessor: "units_sold",
      headerClassName:"text-right",
      className: "col-1 text-right",
      sortType: 'basic',
      sortDirection: sort.accessor === 'units_sold' ? sort.direction : 'none',
      Cell: ( props ) => {
        return props.value ? props.value.toLocaleString( locale ) : 0;
        
      },
      Filter: false,
    },
    {
      Header: "Link",
      accessor: "url",
      headerClassName:"col-1 text-center",
      className: "col-1 text-center",
      Cell: ( props ) => {
        return props.value ? ( <a target="_blank" rel="noreferrer noopener" href={props.value}> <FontAwesomeIcon className="cursor-pointer" icon={faExternalLinkAlt}/> </a> ) : (  <FontAwesomeIcon className="" icon={faUnlink}/> );
      },
      Filter: false,
    },
    
  ];
  
  return (
  <React.Fragment>
    <Helmet title="Ranking de ventas" />
    <Container fluid className="p-0">
      <Row className="mb-2 mb-xl-3 d-flex justify-content-between">
        <Col xs="auto" className="d-none d-sm-block">
          <h3 className="mb-3">Ranking de ventas</h3>
        </Col>
        <DateSelector 
          selectValues={selectValues}
          handleCurrentMonth={handleCurrentMonth}
        />
      </Row>
      <Row>
        <Col lg="12" className="mco-fade-in"> 
        {
           productData && 
            !productData.data&&
             <SuspensivePointsLoader title="Productos"/>
        }
        {
          productData && 
            productData.data && productData.meta &&
              <McoAutoPaginatedTable
                dataForExport={exportProducts}
                data={productData.data}
                columns={tableColumns}
                customPageSize={25}
                loading={loading}
                title="Productos"
                fileName="Products"
              />
        }
        </Col>
      </Row>
    </Container>
  </React.Fragment>
)};

export default McoAllProducts;
