import { useState } from "react";

import usePalette from "./usePalette";
import McoToken from "../components/McoToken";
import { OpenAPI, ReportsService } from "../services/openapi";
import subtractMonthString from "../components/mco/helpers/subtractMonth";

const {
  getReportCouponsGrouped,
  getReportCouponsSubGrouped,
  getCouponsGroupDistris,
} = ReportsService;


export const useBenefits = () => {

  const palette = usePalette();
  const [ groups, setGroups ] = useState([]);
  const [ prevGroups, setPrevGroups ] = useState([]);
  const [ subGroups, setSubGroups ] = useState([]);
  const [ groupDistributors, setGroupDistributors ] = useState([]);
  const [ loading, setLoading ] = useState(true);


  async function  reportPrevCouponsGrouped (startDate) {

    OpenAPI.TOKEN = McoToken();
    const { prevDate } = subtractMonthString(startDate);

    await getReportCouponsGrouped(null, null, prevDate)
    .then( group => {
      

      const colors = {
        "CORPORATIVOS": palette.primary,
        'CUPONES MASIVOS': palette.success,
        'BANCARIOS': palette.cyan,
        'DESCUENTOS POR CATEGORÍA': palette.strong_green,
        'OTROS': palette.warning
      }

      let separated = [];

      let statuses = {
        "wc-completed" : "Completadas",
        "wc-ready-to-delivery" : "Listo para entregar",
        "wc-in-assembly" : "En armado",
        "wc-processing" : "Procesando"
      }


      group.forEach(function(e){
          if( Object.keys( statuses ).includes( e.order_status ) ){
              if(!separated[e.group]){
                  separated[e.group] = [];
              }
              if(!separated[e.group][e.order_status]){
                  separated[e.group][e.order_status] = [];
              }

              separated[e.group][e.order_status].push(e);   
          }
      });

      let data = Object.keys( separated ).map( ( g ) => {
          let values = Object.values(separated[g]);
          return {
              group: g,
              cant: values.reduce( ( a, b ) => {
                  return a + ( b[0].quantity || 0 );
              } ,0 ),
              total_discounted: values.reduce( ( a, b ) => {
                  return a + ( b[0]["total-discounted"]|| 0 );
              } ,0 ),
              color: colors[g]
          };
      });

      const TOTAL_DISCOUNTED_PREV = data.reduce((a, b) => a + b.total_discounted, 0);
      const TOTAL_CANT_PREV = data.reduce((a, b) => a + b.cant, 0);
      
      setPrevGroups ({
        prev_data: data,
        TOTAL_DISCOUNTED_PREV,
        TOTAL_CANT_PREV
      })

      setLoading(false)

    })
    .catch( error => {
      console.log(error);
      setPrevGroups([]);
      setLoading(false)
    })
  }
  

  const reportCouponsGrouped = async({group, orderBy, startDate}) => {
    
    OpenAPI.TOKEN = McoToken();
    setLoading(true);

    await reportPrevCouponsGrouped(startDate)
    
    await getReportCouponsGrouped(null, null, startDate)
    .then( group => {

      const colors = {
        "CORPORATIVOS": palette.primary,
        'CUPONES MASIVOS': palette.success,
        'BANCARIOS': palette.cyan,
        'DESCUENTOS POR CATEGORÍA': palette.strong_green,
        'OTROS': palette.warning
      }

      let separated = [];

      let statuses = {
        "wc-completed" : "Completadas",
        "wc-ready-to-delivery" : "Listo para entregar",
        "wc-in-assembly" : "En armado",
        "wc-processing" : "Procesando"
      }


      group.forEach(function(e){
          if( Object.keys( statuses ).includes( e.order_status ) ){
              if(!separated[e.group]){
                  separated[e.group] = [];
              }
              if(!separated[e.group][e.order_status]){
                  separated[e.group][e.order_status] = [];
              }

              separated[e.group][e.order_status].push(e);   
          }
      });

      let data = Object.keys( separated ).map( ( g ) => {
          let values = Object.values(separated[g]);
          return {
              group: g,
              cant: values.reduce( ( a, b ) => {
                  return a + ( b[0].quantity || 0 );
              } ,0 ),
              total_discounted: values.reduce( ( a, b ) => {
                  return a + ( b[0]["total-discounted"]|| 0 );
              } ,0 ),
              color: colors[g]
          };
      });

      

      const TOTAL_DISCOUNTED = data.reduce((a, b) => a + b.total_discounted, 0);
      const TOTAL_CANT = data.reduce((a, b) => a + b.cant, 0);
    

      setGroups({
        data_groups: data,
        TOTAL_DISCOUNTED,
        TOTAL_CANT,
      });

      setLoading(false);

    })
    .catch( error => {
      console.log(error);
      setGroups([]);
      setLoading(false); 
    })
  }

  
  const reportCouponsSubGrouped = async({group, subGroup, orderBy, startDate}) => {
    OpenAPI.TOKEN = McoToken();
    setLoading(true)
    await getReportCouponsSubGrouped( group, null, null, startDate )
    .then( subGroup => {
      
      subGroup && setSubGroups(subGroup)

      setLoading(false)
    })
    .catch( error => {
      console.log(error);
      setSubGroups([]);
      setLoading(false);
    })
  }
  
  const couponsGroupDistris = async(startDate) => {
    OpenAPI.TOKEN = McoToken();
    await getCouponsGroupDistris(
      null,
      null,
      startDate,
      null,
      null
    )
    .then( resp => {

      if (resp) {

        let data = resp.map( e => ({
          dist: e.dist_name,
          dist_id : e.dist_id,
          total_disc: e["total-discount"]
        }))

        setGroupDistributors(data)
      }
      
    } )
    .catch( error => {
      setGroupDistributors([]);
      console.log(error);
    })
  }

  return {
    loading,
    groups,
    subGroups,
    prevGroups,
    groupDistributors,
    reportCouponsGrouped,
    reportCouponsSubGrouped,
    couponsGroupDistris
  }
}

