import { types } from "../types/types"

const initialState = {
  chartMode: 'Amount'
}

export const productsChartModeReducer = (state = initialState, action) => {
 
  switch (action.type) {
    case types.productsByAmount:
      return {
        ...state,
        chartMode: 'Amount'
      }
    case types.productsByUnits:
      return {
        ...state,
        chartMode: 'Units'
      }  
      
    default:
      return state;
  }
}
