import { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import { Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";

import { amountChart, unitsChart } from "../../../actions/products";



const McoSwitch = () => {

  const [ switchValue, setSwitchValue ] = useState(false);
  const switchMode = useRef(null);
  const dispatch = useDispatch();
  const { productsChartMode:{ chartMode } } = useSelector(state => state);

  useEffect(() => {
   if(switchValue){
    dispatch(unitsChart());
   }else{
    dispatch(amountChart());
   }
  }, [switchValue])
  
  return (
    <Col sm={3} className='d-flex align-items-center justify-content-evenly'
    ref={switchMode}
    style={{
      "maxHeight": "10em;",
      "paddingTop": "0px;",
      "maxWidth":"4.3em;",
    }}
    >

      <OverlayTrigger
        placement="top"
        transition
        overlay={
          <Tooltip id="tooltip-top">
            Modo Facturacion
          </Tooltip>
        }
      > 
        <span>
          <FontAwesomeIcon 
            icon={faDollarSign}
            className={`${chartMode === 'Amount' ? "text-success fs-5  mt-0" : "fs-5  mt-0"}`}
          />
        </span>
      </OverlayTrigger>

      <Form.Check
        type="switch"
        id="exampleCustomSwitch"
        name="customSwitch"
        className=''
        style={{
          fontSize: '13px'
        }}
        onChange={() => setSwitchValue(!switchValue)}
        value={switchValue}
      />

      <OverlayTrigger
        placement="bottom"
        transition
        overlay={
          <Tooltip id="tooltip-top">
            Modo Unidades
          </Tooltip>
        }
      >
        <div>
          <FontAwesomeIcon
            icon={faShoppingBasket}
            className={`${chartMode === 'Units' ? "text-primary fs-5 mb-0" : "fs-5 mb-0"}`}
          />   
        </div>
      </OverlayTrigger>

    </Col>
  )
}

export default McoSwitch
