import { MCO_CONFIGS } from "../../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getPercent } from "../../../../components/mco/helpers/getPercent";


const BusinessInfoCard = ({ 
  data=0,
  color,
  percent=false,
  total,
  icon='',
  iColor,
  title="",
  prefix='' ,
}) => {
  
  const locale = MCO_CONFIGS;


  return (
    <Card 
      className="flex-fill mco-fade-in mco-info-card__width mco-info-card__height w-lg"
    >
      <Card.Header className="d-flex justify-content-between align-items-center" >
        <Col xs="auto">
          <h5 
            className="fs-4 mco-info-card__title--size mb-0 ms-3"
          >
            {title}
          </h5>
        </Col>
        <Col xs="auto">
          <div className="mco-fade-in d-flex justify-content-center align-items-center"
            style={{
              "backgroundColor":color,
              "width": "40px",
              "height":"40px",
              "borderRadius":"100%"
            }}
          >
            <FontAwesomeIcon 
              icon={icon}
              style={{
                "color":iColor,
              }}
            />
          </div>
          {
            percent &&
            (
              <OverlayTrigger
                placement="top"
                transition
                overlay={
                  <Tooltip id="tooltip-top">
                    Porcentaje sobre el total
                  </Tooltip>
                }
              >
                <div className="position-absolute mt-3 text-success fs-5 cursor-pointer">
                  { 
                    (getPercent((data), total)).toLocaleString(locale, {
                      maximumFractionDigits: 1,minimumFractionDigits: 1 }) + '%' 
                  }
                </div>
              </OverlayTrigger>
            )
          }
        </Col>
      </Card.Header>
      <Card.Body className="d-flex justify-content-start align-items-center ms-3" >
        <span className="fs-2 h1 d-inline-block mb-5 mco-fade-in mco-fs__font--size" >
           {
              typeof data === 'string' ?
              ( <span>{ data }</span> )
              :( <span>{ prefix + ' ' + (data).toLocaleString(locale) }</span> )
           }
        </span>
       
      </Card.Body>
    </Card>
  )
}

export default BusinessInfoCard