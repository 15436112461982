import { useSelector } from "react-redux";
import { roles } from "../../types/types";
import SidebarNav from "./SidebarNav";
import {
  Settings,
  Clock,
  Calendar,
  Users
} from "react-feather";

const configSuperRoutes = [{
  pages: [{
    href: "/settings",
    title: "Configuración",
    icon: Settings,
    children: [
      {
        href: "/settings/users",
        title:"Usuarios",
        icon:Users,
      },
      {
        href: "/settings/last_month",
        title:"Ultimo mes",
        icon: Calendar
      },
      {
        href: "/settings/crones",
        title:"Crones",
        icon: Clock
      }
    ]
  }],
  title: ""
}]


const SidebarFooter = () => {
  const { user } = useSelector( state => state.jwt );

  return (
    <>
      <div className="sidebar-nav">
      <hr/>
        <SidebarNav items={user && user.role === roles.superAdmin && configSuperRoutes } />
      </div> 
    
    </>
  );
};

export default SidebarFooter;
