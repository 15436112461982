import { useContext } from 'react'
import { McoAutoTableContext } from './McoAutoPaginatedTable'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "react-bootstrap";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";

const McoAutoTable = () => {
  const {
    // rows,
    page,
    hover,
    prepareRow,
    headerGroups,
    getTableProps,
    getTableBodyProps,
  } = useContext(McoAutoTableContext);
  
  return (
    <Table responsive striped  {...getTableProps()} size="sm" className="overflow-hidden" hover={hover}>
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th {...column.getHeaderProps(column.getSortByToggleProps())} className={column.headerClassName}>
              {column.render("Header")}
              {column.accessor? (
              <span>
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                  ) : (
                    <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                  )
                ) : (
                  <FontAwesomeIcon icon={faSort} className="ms-2" />
                )}
              </span>) : ""}
              {column.canFilter ? column.render("Filter") : null}
            </th>
          ))}
        </tr>
      ))}
    </thead>
    <tbody {...getTableBodyProps()} className="mco-fade-in">
      {page.map((row, i) => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()}>
            {row.cells.map((cell) => {
              return (
                <td {...cell.getCellProps({className: cell.column.className || "" })}>
                  {cell.render("Cell")}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  </Table>
  )
}

export default McoAutoTable