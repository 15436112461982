import { Fragment, useContext, useEffect } from "react"

import { Row, Col } from "react-bootstrap";

import { BenefitsContext } from "./layout/BenefitsLayout"
import BenefitsInfoCards from "./benefits-components/BenefitsInfoCards";
import { SuspensivePointsLoader } from "../../../components/mco/components/McoLoaders";
import { McoEmptyDataAlert } from "../../../components/mco/components/McoEmptyDataAlert";

export const MassiveBenefits = () => {

  const { reportCouponsSubGrouped, subGroups , currentMonth, isLoading } = useContext(BenefitsContext);


  useEffect(() => {
    let isMounted = true;
    reportCouponsSubGrouped({ group:'massive-coupons', startDate:currentMonth })
    return () =>  isMounted = false;
  }, [currentMonth]);

  return (
    <Fragment>
      <Row className="mb-4">
        {
          !isLoading ?
          (
            subGroups.length ? 
            (
              <Fragment>
                {
                  subGroups.map( (e, index) => { 

                    const total_disc = Object.values(e["orders"]).reduce((a,b) => a + ( b.total_discounted || 0 ) , 0)
                    const total_qty = Object.values(e["orders"]).reduce((a,b) => a + ( b.total_quantity || 0 ) , 0)
                    
                    return (
                      <Col sm={4} className='' >
      
                        <BenefitsInfoCards
                          key={index}
                          subGroup={e.sub_group}
                          discount={total_disc}
                          qty={total_qty}
                          orders={ e["orders"] }
      
                        />
      
                      </Col>
                    )
                  })
                }
              </Fragment>
            ) : ( <McoEmptyDataAlert /> )

          ) : ( <SuspensivePointsLoader title="beneficios" /> )
        }
      </Row>
    </Fragment>
  )
}
