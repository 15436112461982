import { Fragment, useContext, useEffect, useState } from "react";

import { Doughnut } from "react-chartjs-2";
import { Col, Card } from "react-bootstrap";
import { useSelector } from "react-redux";

import usePalette from "../../../../hooks/usePalette";
import { BenefitsContext } from '../layout/BenefitsLayout';
import BenefitsTable from "../benefits-components/BenefitsTable";
import getIncrease from "../../../../components/mco/helpers/getIncrease";
import  McoSwitch  from "../../../../components/mco/components/McoSwitch";
import subtractMonthString from "../../../../components/mco/helpers/subtractMonth";
import { useTranslation } from "react-i18next";
import { SuspensivePointsLoader } from "../../../../components/mco/components/McoLoaders";



export const BenefitsPieChart = ({ data, loading }) => {

  const palette = usePalette();

  const [t] = useTranslation();

  const { locale, isLoading, currentMonth } = useContext(BenefitsContext);

  const { chartMode } = useSelector( state => state.productsChartMode );

  const [ dataTable, setDataTable] = useState([]);

  const [ prevData, setPrevData ] = useState([]);

  const [ increments, setIncrements ] = useState(0);

  const [ dataChart, setDataChart ] = useState({
   labels:[],
   datasets: [
     {
       data: [],
       backgroundColor: [],
       borderWidth: 4,
       borderColor: palette.white,
       hoverOffset: 5
     },
   ],
  });

  const amount = "Amount";

  const { prevMonthName } = subtractMonthString(currentMonth);

  let percentLabelSize = "18px";
  let titleLabelSize = "10px";
  let monthLabelSize = "10px";
  let chartHeight = '56%';

  useEffect(() => {
    let isMounted = true;

    if ( data && data.data ) {

      const { data:{ groups, prev_groups, increments, mode }, chart } = data;

      if(data.chart){

        setPrevData( prev_groups );
  
          setDataChart({
            ...dataChart,
            labels: groups.map( groups => groups.group ),
            datasets: [{
              ...dataChart.datasets[0],
              backgroundColor: groups.map(e => e.color ),
              data: groups.map(e => e.values)
            }]
          })
  
          setIncrements(increments)
       
          setDataTable([{
            groups,
            mode,
            chart
          }]);
      }

      }

    return () => isMounted = false;
  }, [data, chartMode])




  const percent = {
    id: 'percent',
    beforeDraw( chart, args, options ){
      const { ctx, chartArea:{ right, bottom } } = chart;
      ctx.save();
      ctx.font = `${percentLabelSize} Poppins`;
      ctx.textAlign = 'center';
      ctx.fillStyle = options.fontColor;
      if(options.percent){
        if(parseFloat(options.percent) > 0){
          ctx.fillText( '+' + (options.percent).toLocaleString(locale,
            {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1
            }) + ' %',
          (right/2), (bottom/2.1) + 15);
        }else {
           ctx.fillText((options.percent).toLocaleString(locale,
            {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1
            }) + ' %',
          (right/2), (bottom/2.1) + 15);
        }
      }
    },
  };

  const labelChartCenter = {

    id: 'labelChartCenter',
    beforeDraw( chart, args, options ){
      const { ctx, chartArea:{ right, bottom } } = chart;

      ctx.save();
      ctx.font = ` ${monthLabelSize} Poppins`;
      ctx.textAlign = 'center';

      if(options.percent !== 0){

        ctx.fillStyle = palette.secondary;
        ctx.fillText(' vs ' + (options.month),
        (right/2), ((bottom/2.1)+48));

      }
    }

  };

  const titleChartCenter = {

    id: 'titleChartCenter',
    beforeDraw( chart, args, options ){
      const { ctx, chartArea:{ right, bottom } } = chart;

      ctx.save();
      ctx.font = ` ${titleLabelSize} Poppins`;
      ctx.textAlign = 'center';

      if(options.percent !== 0){

        ctx.fillStyle = options.titleColor;
        ctx.fillText((options.group),
        (right/2), ((bottom/2.1)-25));

      }
    }

  };
  

  const options = {
    maintainAspectRatio: true,
    responsive: true,
    radius: chartHeight,
    cutout: '83%',
    animation: {
      animateScale: true,
      animateRotate: true,
    },
    onHover (e, chartElement) {
      if(chartElement.length === 1){
        e.native.target.style.cursor = 'pointer';
      }else {
        e.native.target.style.cursor = '';
      }
    },
    onClick(click, chartElement){

      if(chartElement.length){
        
        const { index } = chartElement[0];
        
        if ( prevData.length ) {
          
          let curr = dataChart.datasets[0].data[index];

          const prevItemSelected = (prevData.filter(elm => elm.group === dataChart.labels[index]));
          
          let prev;

          if(prevItemSelected.length){

            prev =  chartMode === amount ? (prevItemSelected[0].total_discounted) : (prevItemSelected[0].cant) ;

          } else {
            prev = 0
            curr = 0
          }
          
          
          setIncrements([{
            group: dataChart.labels[index],
            increment: getIncrease(curr, prev),
            color: getIncrease(curr, prev) < 0? palette.danger : palette.success,
            prevMonth: prevMonthName,
            titleColor: dataChart.datasets[0].backgroundColor[index]
          }])
        } 
      }
    },
    plugins: {
      legend:{
        display:false
      },
      tooltip: {
        intersect: true,
        callbacks: {
          label: function(tooltipItem) {

            if (chartMode === amount) {
              return (' ' + tooltipItem.label + ": " +
               "$ " + parseFloat(tooltipItem.parsed).toLocaleString(locale));
              
            } else {
              return (' ' + tooltipItem.label + ": " +
               parseFloat(tooltipItem.parsed).toLocaleString(locale));
            }
          },
        },
      },
      percent: {
        fontColor:  increments.length && increments[0].color,
        percent: increments.length && increments[0].increment
       },
      labelChartCenter:{
        month: increments.length && increments[0].prevMonth,
      },
      titleChartCenter:{
        titleColor: increments.length && increments[0].titleColor,
        group: increments.length && t(increments[0].group),
      }

    },
  };

  if(window.screen.width > 1600 && window.screen.height > 800){
    chartHeight = '74%';
    percentLabelSize = '25px';
    titleLabelSize = "17px";
    monthLabelSize = "17px";
  }

  return (
    <Fragment>
      {
        <Card>
          {
            loading ?
            (
              <SuspensivePointsLoader />
            ):(
              
              data && data.data ?
              (
                <>
                  <Col>
  
                    <div className=" d-flex justify-content-between p-3 ">
                      <h4 className="" > Grupos  </h4>
                      <McoSwitch />
                    </div>
                    <div className=" d-flex justify-content-center" >
                      <Card.Text> <strong> {chartMode === amount ? 'Descuentos Totales' : 'Cantidad de Ordenes' } </strong>  </Card.Text>
                    </div>
                    <Doughnut
                      data={dataChart}
                      options={options}
                      className="cursor-pointer"
                      plugins={[ percent, labelChartCenter, titleChartCenter ]}
                      height={chartHeight}
                    />
                  </Col>
                  <BenefitsTable data={dataTable} loading={isLoading} mode={chartMode} />
                </>
              ):(
                // <McoEmptyDataAlert />
                <SuspensivePointsLoader />
              )
            )
            
          }
        </Card>

}
    </Fragment>
  )
}
