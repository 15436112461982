
export const types = {
  last_date: "[DATE] Last Date",
  selected_date: "[DATE] Select Date",
  business: "[PRODUCTS] Negocio",
  productsByUnits: "[PRODUCTS] UnitsMode",
  productsByAmount: "[PRODUCTS] AmountMode",
  few: "[REPORTS] FewReports",
  medium: "[REPORTS] MediumReports",
  many: "[REPORTS] ManyReports",
  ready: "[CRON] Ready",
  runing: "[CRON] Runing",
  success: "[CRON] Success",
  failed: "[CRON] Failed",
  waiting: "[CRON] Waiting",
  warning: "[CRON] Warning",
  selectedCronData: "[CRON] Cron Data",
  logs: "[LOGS] Show Logs",
  register: "[USER] Register User",
  update_user: "[USER] Update User",
  delete_user: "[USER] Delete User",
  register_success: "[USER] Register Success",
  update_success: "[USER] Update Success",
  action_errors: "[USER] Some Error",
  clean_state: "[USER] Clean State",
  modalOpen: "[MODAL] Modal Open",
  modalClose: "[MODAL] Modal Close",

}

export const configTypes = {
  me: "me",
  roles: "roles",
  users: "users",
  respApi: "respApi",
  croneList: "cronesList",
  optionRoles: "optionRoles",
}

export const roles ={
  superAdmin: 'superAdmin',
  admin: 'admin'
}