import { createContext, useContext } from "react";

import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

import { BenefitsContext } from "../layout/BenefitsLayout";


export const EmployeesCardContext = createContext({});
const { Provider } = EmployeesCardContext;

const BenefitsCards = ( { company = null, currentMonth } ) => {

  const { locale } = useContext(BenefitsContext);
  
  const { 
    total_discounted,
    subtotal_amount,
    original_role,
    total_orders,
    total_amount,
    total_users,
    role,
  } = company;

  const amount = (Object.values(total_amount).reduce((a, b) => parseFloat(a) +  parseFloat(b), 0));
  const discounted = (Object.values(total_discounted).reduce((a, b) => parseFloat(a) +  parseFloat(b), 0));
  const orders = (Object.values(total_orders).reduce((a, b) => parseInt(a) +  parseInt(b), 0));
  const subtotal = (Object.values(subtotal_amount).reduce((a, b) => parseFloat(a) +  parseFloat(b), 0));
  

  return (

    <Provider value={{
      company,
      locale,
    }}>

      <Card className="flex-fill">
        <Card.Body className="py-4">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="mb-3 fs-3">{ role }</h4>
            <div style={{marginTop:-16}}>
              <span className="text-end text-success" title="Porcentaje de compras por cantidad de usuarios"> 
                { 
                  orders && total_users > 0 ?
                  ((orders*100)/total_users).toLocaleString(locale, {
                    maximumFractionDigits:2,
                    minimumFractionDigits:2
                  })
                  : 0
                }%
                </span>
              </div>
          </div>
          
            <div className="mb-1 d-flex align-items-center justify-content-start">
              <span className="me-2 fs-5">
                Neto:
              </span>
              <strong className="fs-5">  ${ 
                (amount).toLocaleString(locale, {
                maximumFractionDigits:2,
                minimumFractionDigits:2
                })
              }
              </strong>
            </div>
            
            <div className="mb-1 d-flex align-items-center justify-content-start">
                <span className="me-2 fs-5">
                  Descuento:
                </span>
                <strong className="fs-5"> $ { 
                  (discounted).toLocaleString(locale, {
                  maximumFractionDigits:2,
                  minimumFractionDigits:2
                })
              }</strong>
            </div>
            <hr/>
            <div className="mb-1 d-flex align-items-center justify-content-start">
                <span className="me-2 fs-5">
                  Bruto:
                </span>
                <strong className="fs-5">  $ { 
                  (subtotal).toLocaleString(locale, {
                  maximumFractionDigits:2,
                  minimumFractionDigits:2
                })
              }</strong>
            </div>

            <div className="mb-1 d-flex align-items-center justify-content-start">
                <span className="me-2 fs-5">
                  Cant. de Compras:
                </span>
                <strong className="fs-5">  { 
                  (orders).toLocaleString(locale, {
                  maximumFractionDigits:0,
                  minimumFractionDigits:0
                })
              }</strong>
            </div>

            <div className="d-flex align-items-center justify-content-start">
                <span className="me-2 fs-5">
                  Cant. de Empleados:
                </span>
                <strong className="fs-5">  { (total_users) }</strong>
            </div>

        </Card.Body>
          
        <hr className="mb-2 mt-0"/>
        <div className="d-flex align-items-center justify-content-between"> 
            <div>
              <Link to={"/benefits/corporatives/customers-employees/"+original_role+"/"+role} className="cursor-pointer nav-link pt-0 float-start">
                Ver Empleados
              </Link>
            </div>

          { 
            amount !== 0 ? 
            (
              <div>
                <Link 
                  to={"/benefits/corporatives/employees-detail/"+original_role+"/"+role+"/?month="+currentMonth} 
                  className="cursor-pointer nav-link pt-0 float-start">
                  Ver Compras
                </Link>
              </div>
            ):(
              <div className="text-gray ms-3 me-3 mb-2 d-flex align-items-center justify-content-end">
                  <span>Sin detalles</span>
              </div>
            )
          }
        </div>
      </Card>
    </Provider>
  );

};

export default BenefitsCards;

