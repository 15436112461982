import { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from "react-helmet-async";
import useReports from '../../../../hooks/useReports';
import { Container, Col, Row } from "react-bootstrap";
import McoPaginatedTable from '../../../../components/mco/components/McoPaginatedTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';



const MailReportsTable = () => {
	//default sort
	const { show } = useSelector(state => state.reports)
	const [ sort  , setSort ] = useState( { accessor:'name' , direction:'DESC'} );


  const  {
  loading,
  pageCount,
  usersMails,
  getUsersMail,
  dataProcessed,
  exportLoading,
  getUserEmailsToExport
  } = useReports();
	 
	useEffect(() => {

		getUsersMail({
      role:'empleado_arcor',
    });

    getUserEmailsToExport();
	}, []);


	const fetchData = useCallback( ({ pageIndex, state })  => {
    getUsersMail({
			surname: state.type === "Apellido"? state.values : undefined,
			name: state.type === "Nombre"? state.values : undefined,
			email: state.type === "Email"? state.values : undefined,
			mainCity: state.type === "Ciudad"? state.values : undefined,
			mainState: state.type === "Provincia"? state.values : undefined,
			perPage: show,
			page: pageIndex+1,
			orderBy: sort.accessor,
		}) 

	}, [sort, show] );
	  
	const columnHeaderClick = (column) => {

		switch (column.sortDirection) {
		case 'none':
			setSort( {accessor: column.id, direction: 'ASC'} );
			break;
		case 'ASC':
			setSort( {accessor: column.id, direction: 'DESC'} );
			break;
		case 'DESC':
			setSort( {accessor: column.id, direction: 'ASC'} );        
			break;
		default:
		}
	};

  const businessReportsColumns = [
    {
      Header: "Nombre",
      accessor: "name",
      width:250,
      className: "col-2",
      headerClassName:"col-2",
      sortType: 'basic',
      sortDirection: sort.accessor === 'name' ? sort.direction : 'none',
    },	
		{
			Header: "Apellido",
			accessor: "surname",
			width:250,
			className: "col-2",
			headerClassName:"col-2",
			sortType: 'basic',
			sortDirection: sort.accessor === 'surname' ? sort.direction : 'none',
		},
		{
			Header: "Provincia",
			accessor: "main_state",
			className: "col-1 text-uppercase",
			headerClassName:"col-1",
			enableColumnFilter:true,
      sortType: 'basic',
			sortDirection: sort.accessor === 'main_state' ? sort.direction : 'none',
			Cell: (props) => { 
				return props.value 
			},
		},
		{
			Header: "Ciudad",
			accessor: "main_city",
			className: "col-1 text-uppercase",
			headerClassName: "col-1",
			sortType: 'basic',
			sortDirection: sort.accessor === 'main_city' ? sort.direction : 'none',
		},
		{
			Header: "Recibe Mails",
			accessor: "allow_receive_comunications",
			className: "col-1 text-center",
			headerClassName: "col-1 text-center",
			Filter: false,
			sortType: 'basic',
			sortDirection: sort.accessor === 'allow_receive_comunications' ? sort.direction : 'none',
			Cell: (props) => (
				Boolean(props.value)?  <FontAwesomeIcon icon={faCheck} className="text-success" /> :  <FontAwesomeIcon icon={faTimes} className="text-danger" />
			)
		},
    {
			Header: "Email",
			accessor: "email",
			className: "col-2",
			headerClassName:"col-2",
			sortType: 'basic',
			sortDirection: sort.accessor === 'email' ? sort.direction : 'none',
		},	

	];

  return (
    <Fragment>
      <Helmet title="Reporte Email" />
      <Container fluid className="p-0" >
        <Row className="d-flex justify-content-between">
          <Col xs="auto" className="d-none d-sm-block fs-1">
            <h3>
              Reporte Mailing
            </h3>
          </Col>
        </Row>
          <Row className="mb-4 mco-fade-in">
            <McoPaginatedTable 
              columns={businessReportsColumns}
              dataForExport={dataProcessed}
              pageCount={pageCount}
              fetchData={fetchData}
              data={usersMails}
              customPageSize={50}
              loading={loading}
              fileName={"reporte_emails"}
              exportLoading={exportLoading}
              onHeaderClick={columnHeaderClick}
              title={`Lista de emails de usuarios`}
            />
          </Row>
      </Container>
    </Fragment>
  )
}

export default MailReportsTable;