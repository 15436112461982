import { createContext, useState } from "react";
import { useSelector } from "react-redux";
import McoToken from "../components/McoToken";
import { 
  OpenAPI,
  ProductsStocksService 
} from "../services/openapi";

  // sku?: string,
  // name?: string,
  // status: 'publish' | 'draft' = 'publish',
  // startDate?: any,
  // perPage?: any,
  // page?: any,
  // _export: 0 | 1 = 0,
  // orderBy: 'date' | 'sku' | 'product_id' | 'name' | 'category' | 'url' | 'price' | 'business' | 'segment' | 'status' | 'units_sold' | 'total_orders' | 'total_amount' | 'dists_has_stock' | 'dists_has_not_stock' = 'product_id',
  // order: 'DESC' | 'ASC' = 'DESC

const ProductsContext = createContext(null);

const ProductsProvider = ({children}) => {
  const { getProductsList } = ProductsStocksService;
  const [ productData, setProductData ] = useState([]);
  const [ dataProcessed, setDataProcessed ] = useState([]);
  const { show } = useSelector(state => state.reports);
  const [ loading, setLoading ] = useState( false );

  //Data para McoAllProducts.js 
  const productList = async ({startDate, sku, name, perPage, page, orderBy='total_amount', order, exportar }) => {
    setLoading(true);

      try{
      OpenAPI.TOKEN = McoToken();
      const products = await getProductsList(
        sku, // sku
        name, // name
        'publish', // status
        startDate, // start_date
        1000000, // limit of data (per_page)
        page, // current page
        exportar, // export 1 or 0 (yes or no),
        orderBy,//orderBy
        order
      );

      if(products){
        setProductData(products);
        setLoading(false);
      }else {
        setProductData([]);
        setLoading(false);
      }
  
      return products;
    }catch (error){
        console.log("error while fetching", error);
    }
  }

  const exportProductList = async({startDate}) => {
     try{
      OpenAPI.TOKEN = McoToken();
      const products = await getProductsList(
        undefined, // sku
        undefined, // name
        undefined, // status
        startDate, // start_date
        undefined, // limit of data (per_page)
        undefined, // current page
        1, // export 1 or 0 (yes or no),
        undefined,//orderBy
        undefined
      );

      if(products){
        const { data } = products;
        const data_processed = data.map(e => ({
          "SKU": e.sku,
          "NOMBRE": e.name,
          "NEGOCIO": e.business,
          "SEGMENTO": e.segment,
          "MONTO FACTURADO": parseFloat(e?.total_amount) || 0,
          "U. VENDIDAS": parseInt(e?.units_sold) || 0,
          "ORDENES":  parseInt(e?.total_orders) || 0,
        }));
        setDataProcessed(data_processed);
        setLoading(false);
      }else {
        setDataProcessed([]);
        setLoading(false);
      }
  
      return products;
    }catch (error){
        console.log("error while fetching", error);
    }
  }


  return (
    <ProductsContext.Provider
      value={{
       productList,
       show,
       productData,
       loading,
       exportProductList,
       dataProcessed,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
}

export { ProductsContext, ProductsProvider };