import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortUp,
  faSortDown,
  faSort
} from "@fortawesome/free-solid-svg-icons";


export const McoTable = ({
  page,
  prepareRow,
  headerGroups,
  getTableProps,
  getTableBodyProps,
  onHeaderClick
}) => {
  
  return (
    <Table striped responsive {...getTableProps()} size="sm" className="overflow-hidden">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())} 
              onClick={() => onHeaderClick&& onHeaderClick(column)}
              className={column.headerClassName}>  
                {column.render("Header")}
                  {
                    column.sortDirection? 
                      (
                        <span>
                          {
                            column.sortDirection !== 'none' ?
                              ( 
                                column.sortDirection === 'ASC' ?
                                  ( <FontAwesomeIcon icon={faSortDown} className="ms-2" />):
                                  ( <FontAwesomeIcon icon={faSortUp} className="ms-2" />) 
                              ) : (
                                  <FontAwesomeIcon icon={faSort} className="ms-2" />
                              )
                          }
                        </span>
                      ) : (" ")
                  }                          
                {
                  column.canFilter ?
                  (
                    column.render("Filter")
                  )
                  : null
                }
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
      {
        page.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps({ className: cell.column.className || "" })} >{cell.render("Cell")}</td>
                );
              })}
            </tr>
          );
        })
      }
      </tbody>
      <tfoot>
        <tr>
        </tr>
      </tfoot>
    </Table>
  )
};
