import { useState, useEffect } from "react";
import { Badge, Col, Card, Row, Spinner } from "react-bootstrap";
//import SimpleCard from '../../../../components/mco/components/McoTotalCard';
import { MCO_CONFIGS } from "../../../../constants";
import McoIcon from "../../../../components/mco/components/McoIcon";
import  getIncrease  from '../../../../components/mco/helpers/getIncrease'

const SimpleCard = ({ title = "", qty = 0, prevqty = 0, subtitle = "", icon = "DollarSign", prefix = "", format = false, is_loading = true }) => {

  const { locale } = MCO_CONFIGS;

  const [ percent, setPercent ] = useState( 0 );

  useEffect(() => {
    setPercent( getIncrease( qty || 0, prevqty ) );
  }, [ qty, prevqty ]);

  return (
    <Card className="flex-fill mco-fade-in">
      <Card.Body>
        <Row>
          <Col className="mt-0">
            <h5 className="card-title">{title} {(is_loading)? (<Spinner animation="grow" size="sm" variant="secondary" className="me-2 border-3 mx-2" />) : ""}</h5>
          </Col>

          <Col xs="auto">
            <div className="stat stat-sm mco-fade-in">
              <McoIcon iconName={icon} />
            </div>
          </Col>
        </Row>
        <span className="h3 d-inline-block mt-1 mb-4 mco-fade-in" >
        { ( !is_loading )? ( ( format )? ( prefix + Number(qty).toLocaleString(locale) ) : ( qty ) ) : ( "" ) }
        </span>
        <div className="mb-0 mco-fade-in">
          { ( !is_loading && subtitle !== "")? (<span className="text-muted"><span className="text-capitalize"> { subtitle } </span> </span>) : "" }
        </div>
      </Card.Body>
    </Card>
  );

};

export default SimpleCard;
