import * as React from "react";
import { Navigate } from "react-router-dom";
import useAppSelector from "../../hooks/useAppSelector";

// For routes that can only be accessed by unauthenticated users
function GuestGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAppSelector((state) => state.jwt);

  if (isInitialized && isAuthenticated) {
    return <Navigate to="/" />;
  } 

  return <React.Fragment>{children}</React.Fragment>;
}

export default GuestGuard;
