
import { useState } from 'react';
import { MCO_CONFIGS } from '../constants';
import McoToken from '../components/McoToken';
import moment from "moment/min/moment-with-locales";
import { OpenAPI, ReportsService } from '../services/openapi';

const { getReportBusiness } = ReportsService;

const useReportBusiness = () => { 
  const { locale } = MCO_CONFIGS;
  const [ loading, setLoading] = useState(false);
  const [ exportLoading, setExportLoading ] = useState(true);
  const [ pageCount, setPageCount ] = useState( 0 );
	const [ businessData, setBusinessData ] = useState([]);
  const [ dataProcessed, setDataProcessed ] = useState([]);


  const getReport = async({
    orderId=null,
    distId, 
    sku=null,
    productName,
    corporateName,
    type=null,
    date,
    limit,
    currentPage,
    orderBy=null,
    sortDirection,
  }) => { 

    setLoading(true);
    OpenAPI.TOKEN = McoToken();

      await getReportBusiness(
        orderId,// orderId?: number,
        distId,// distId?: number, 
        sku,// sku?: string,
        productName,// productName?: string,
        corporateName,
        type, //type
        date,// startDate?: any,
        limit,// perPage?: any,
        currentPage,// page?: any,
        0,// _export: 0 | 1 = 0,
        null,//discounts default = 0
        orderBy,// orderBy:'subtotal'
        sortDirection, // order: 'DESC' | 'ASC' = 'DESC'
      ).then((resp) => {
        setBusinessData(resp.data);
        setPageCount(resp.last_page);  
        setLoading(false);
      }).catch( err => {
        console.log(err)
        setBusinessData([]);
        setPageCount(0);
      }); 

  }

// All data for csv 
  const getAllBusinessReport = async( date ) => {
    
    
    OpenAPI.TOKEN = McoToken();
    setExportLoading(true);

      await getReportBusiness(
        null,// orderId?: number,
        null,// distId?: number,
        null,// sku?: string,
        null,// productName?: string,
        null, // corporateName
        null, //type
        date,// startDate?: any,
        null,// perPage?: any,
        null,// page?: any,
        1,// _export: 0 | 1 = 0,
        null,
        null,// orderBy:'subtotal'
        null // order: 'DESC' | 'ASC' = 'DESC'
      ).then( resp => { 
        const data_processed = resp.map((e) => {
          return {
            "FECHA" : moment( e.order_date ).locale("es").format("DD/MM/YYYY"),          
            "ORDEN ID" : ( e.order_id ),
            "DIST ID" : ( e.dist_id ),
            "DISTRIBUIDOR" : ( e.dist_name ),
            "SKU" : ( e.sku ),
            "PRODUCTO" : ( e.product_name ),
            "CANT" : ( e.quantity )?  parseInt(e.quantity).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0,
            "PRECIO UNIT. LISTA" : ( e.unit_price_list )? parseFloat(e.unit_price_list).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0,
            "PRECIO UNIT. DESC" : ( e.unit_discount_price )? parseFloat(e.unit_discount_price).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0,
            "DESC. UNIDAD": ( e.discount_per_unit )? parseFloat(e.discount_per_unit).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0,
            "SUBTOTAL DESC": ( e.subtotal_discounts_price )? parseFloat(e.subtotal_discounts_price).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0,
            "CORPORATIVO" : ( e.corporate_name? e.corporate_name : " - " ),
            "DESC. CORPORATIVO" : ( e.corporate_discount )? parseFloat(e.corporate_discount).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0, 
            "SUBTOTAL OTROS DESC": ( e.subtotal_other_discounts )? parseFloat(e.subtotal_other_discounts).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0,
            "TOTAL DESC": ( e.total_discounts )? parseFloat(e.total_discounts).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0,
            "TOTAL NETO": ( e.net_total )? parseFloat(e.net_total).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0,
            "TIPO": e.type
          };
        });
  
          setDataProcessed( data_processed );
          setExportLoading(false);
      }).catch( err => {
        console.log(err);
        setDataProcessed([]);
        setExportLoading(false);
      });

  }

  return {
    loading,
    getReport,
    pageCount,
    businessData,
    exportLoading,
    dataProcessed,
    getAllBusinessReport,
  }
}

export default useReportBusiness;