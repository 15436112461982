import { Fragment, useContext, useEffect, useRef } from "react"

import { Row, Col } from "react-bootstrap";

import { BenefitsContext } from "./layout/BenefitsLayout"
import BenefitsInfoCards from "./benefits-components/BenefitsInfoCards";
import { SuspensivePointsLoader } from "../../../components/mco/components/McoLoaders";
import { McoEmptyDataAlert } from "../../../components/mco/components/McoEmptyDataAlert";

export const BankingBenefits = () => {
  
  const {
    isLoading,
    currentMonth,
    subGroups,
    reportCouponsSubGrouped,
  } = useContext(BenefitsContext);


  const isMounted = useRef(true);
  
  useEffect(() => {
    reportCouponsSubGrouped({ group:'bank', startDate:currentMonth });
    return () => {
      isMounted.current = false;
    };
  }, [currentMonth]);

  // Alfabetic order by role
  if (subGroups && subGroups.length > 0) {
    subGroups.sort( (a, b) => {
      const startsWithNumberA = /^\d/.test(a.sub_group);
      const startsWithNumberB = /^\d/.test(b.sub_group);
  
      if (startsWithNumberA && startsWithNumberB) {
        return a.sub_group.localeCompare(b.sub_group);
      }
      if (startsWithNumberA) {
        return 1;
      }
      if (startsWithNumberB) {
        return -1;
      }
  
      return a.sub_group.localeCompare(b.sub_group);
    });
    
  }

  return (
    <Fragment>
      <Row className="mb-4">
        {
          !isLoading ?
          (
            subGroups.length ?
            (

              <Fragment>
                {
                  subGroups.map( (e, index) => {
                    const total_disc = Object.values(e["orders"]).reduce((a,b) => a + ( b.total_discounted || 0 ) , 0)
                    const total_qty = Object.values(e["orders"]).reduce((a,b) => a + ( b.total_quantity || 0 ) , 0)

                    return (
                      <Col sm={4} className='' key={e.sub_group} >
      
                        <BenefitsInfoCards
                          subGroup={e.sub_group}
                          discount={total_disc}
                          qty={total_qty}
                          orders={e["orders"]}
                        />
      
                      </Col>
                    )
                  })
                }
              </Fragment>
            ):( <McoEmptyDataAlert /> )

          ) : ( <SuspensivePointsLoader title="beneficios" /> )
        }
      </Row>
    </Fragment>
  )
}
