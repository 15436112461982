/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConfigService {

    /**
     * List ecommerce allowed roles
     * This can only be done by the logged in user
     * @returns any Success
     * @throws ApiError
     */
    public static configRolesList(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/config/roles',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * List available crones and options
     * This can only be done by the logged in user
     * @returns any Success
     * @throws ApiError
     */
    public static configCronesList(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/config/crones/list',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * List crones logs
     * This can only be done by the logged in user
     * @param status
     * @param commandName
     * @param perPage
     * @param page
     * @returns any Success
     * @throws ApiError
     */
    public static configCronesLogs(
        status: 'pending' | 'processing' | 'processed' = 'processed',
        commandName?: string,
        perPage?: number,
        page?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/config/crones/logs',
            query: {
                'status': status,
                'command_name': commandName,
                'per_page': perPage,
                'page': page,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Run specific cron with options
     * This can only be done by the logged in superadmin user
     * @param cronId The cron id
     * @param subMonths subMonths
     * @returns any Success
     * @throws ApiError
     */
    public static configCronesRun(
        cronId: number,
        subMonths?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/config/crones/run/{cron_id}',
            path: {
                'cron_id': cronId,
            },
            query: {
                'subMonths': subMonths,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * List API config available options and respective values
     * This can only be done by the logged in user
     * @returns any Success
     * @throws ApiError
     */
    public static configList(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/config',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Update API config available options values
     * This can only be done by the logged in user
     * @param requestBody Array of Config option-value pair params wants to update
     * @returns any Success
     * @throws ApiError
     */
    public static configUpdate(
        requestBody: Array<{
            option?: string;
            value?: string;
        }>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/config',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

}