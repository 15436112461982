import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  es: {
    translation: {
      "Sanatorio Las Lomas" : "S. LAS LOMAS",
      "completed": "Completadas",
      "in-progress": "En progreso",
      "failed": "Fallidas",
      "cancelled": "Canceladas",
      "ready-to-delivery": "Listas para entregar",
      "on-hold": "En espera",
      "pending": "Pendientes",
      "refunded": "Reembolsadas",
      "processing": "Procesando",
      "in-assembly": "En armado",
      "cancel-request": "Solicitud de cancelación",
      "return-request": "Solicitud de devolución",
      "returned": "Devuelto",
      "total_amount": "Monto Total",
      "total_orders": "Ordenes Totales",
      "subtotal_amount": "Subtotal",
      "total_discounted": "Descuento",
      "The email exists.": "El email ya existe",
      "User soft deleted successfully.": "El usuario fue eliminado correctamente",
      "others": "OTROS",
      "OTHERS": "OTROS",
      "OTHERS": "OTROS",
      "DESCUENTOS POR CATEGORÍA" : "POR CATEGORIA",
      "CUPONES MASIVOS" : "MASIVOS"
    } 
  },
  en: {
    translation: {
      Search: "Search projects…",
      "Welcome back": "Welcome back",
    },
  },
  fr: {
    translation: {
      Search: "Rechercher…",
      "Welcome back": "Bon retour",
    },
  },
  de: {
    translation: {
      Search: "Suchen…",
      "Welcome back": "Willkommen zurück",
    },
  },
  nl: {
    translation: {
      Search: "Zoeken…",
      "Welcome back": "Welkom terug",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "es",
  interpolation: {
    escapeValue: false,
  },
});
