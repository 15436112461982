import { types } from "../types/types";

export const selectBusiness = ( props ) => ({
  type: types.business,
  payload: {
    name: props.business ,
    icon: props.icon,
    color: props.color,
    iconColor: props.iconColor,
    units: props.units,
    amount: props.amount,
  }
})

export const amountChart = () => ({
  type: types.productsByAmount,
  payload: 'Amount'
});

export const unitsChart = () => ({
  type: types.productsByUnits,
  payload: 'Units'
});