/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DistributorsService {

    /**
     * Get Distributors resume orders grouped by status
     * This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static getDistributorsResume(
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/distributors/resume',
            query: {
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get Distributors List
     * This can only be done by the logged in user
     * @param distId dist_id
     * @param distName dist_name
     * @param distEmail dist_email
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @returns any Success
     * @throws ApiError
     */
    public static getDistributorsList(
        distId?: number,
        distName?: string,
        distEmail?: string,
        startDate?: any,
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/distributors/get-distributors-list',
            query: {
                'dist_id': distId,
                'dist_name': distName,
                'dist_email': distEmail,
                'start_date': startDate,
                'per_page': perPage,
                'page': page,
                'export': _export,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get Distributors Sells
     * This can only be done by the logged in user
     * @param distId dist_id
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @returns any Success
     * @throws ApiError
     */
    public static getDistributorsSells(
        distId?: number,
        startDate?: any,
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/distributors/get-distributors-sells',
            query: {
                'dist_id': distId,
                'start_date': startDate,
                'per_page': perPage,
                'page': page,
                'export': _export,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get One Distributors Sells in all periods
     * This can only be done by the logged in user
     * @param distId The distributor id
     * @param _export export
     * @returns any Success
     * @throws ApiError
     */
    public static getDistributorsSellsById(
        distId: number,
        _export: 0 | 1 = 0,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/distributors/{dist_id}/get-distributors-sells',
            path: {
                'dist_id': distId,
            },
            query: {
                'export': _export,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

}