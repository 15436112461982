import * as React from "react";
import { Navigate } from "react-router-dom";

import useAppSelector from "../../hooks/useAppSelector";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {

  const { isAuthenticated, isInitialized } = useAppSelector(state => state.jwt);

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  } 
   
  return <React.Fragment>{children}</React.Fragment>;
}

  

export default AuthGuard;
