import { Fragment, useEffect, useState, memo } from "react";

import Select from "react-select";
import { Doughnut } from "react-chartjs-2";
import moment from "moment/min/moment-with-locales";
import { Card, Row, Col, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { useSelectDate } from "../../../../hooks/useSelectDate";
import { MCO_CONFIGS } from "../../../../constants";
import usePalette from "../../../../hooks/usePalette";
import useDistributorsData from "../../../../hooks/useDistributorsData";
import { ProgressBarLoader } from "../../../../components/mco/components/McoLoaders";
import OrdersTable from "../distributors-components/OrdersTable";

const DistributorPage = () => {
  
  const {
    loading,
    getCurrentDistributorData,
    getPreviousDistributorData,
    subtractMonthString,
    getIncrease
  } = useDistributorsData();

  const palette = usePalette();

  const [
    currentMonth,
    handleCurrentMonth,
    selectValues
  ] = useSelectDate();

  const { locale } = MCO_CONFIGS;
  const { dist_id } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [ orderPercent, setOrderPercent ] = useState(0);
  const [ incrementPercents, setincrementPercents ] = useState([]);
  const [ currentDistriData, setCurrentDistriData ] = useState([]);
  const [ distName, setDistName ] = useState('');
  const [ progressBar, setProgressBar ] = useState(10);
  const [ prevMonth, setPrevMonth ] = useState(
    moment(search.split('=')[1] || currentMonth)
    .subtract(1, 'months').locale("es")
    .format('MMMM')
  );
  const id = Number(dist_id);
  let month = search.split('=')[1] || currentMonth;


  const getDistributorData = ( date = month ) => {

    getPreviousDistributorData( id, date )
    .then( prevData => {

      const { prevMonthName } = subtractMonthString(date);

      setProgressBar(prev => prev + 59);

      setPrevMonth(prevMonthName);

      if(prevData){

        //CALCULO PARA MES ANTERIOR
        let prev_labels = [];
        let incrementData = [];

        prevData.forEach(e => {
          prev_labels.push(e.label) ;
        })

        getCurrentDistributorData( id, date )
        .then( ({ currOrderData, distName }) => {

          setDistName(distName);

          setCurrentDistriData(currOrderData);

          setProgressBar(prev => prev + 1);

          if(currOrderData.length > 0){
            currOrderData.forEach( (currOrders) => {
              if(!prev_labels.includes(currOrders.label)){
                incrementData.push({
                  label: currOrders.label,
                  increment: 0,
                  color: currOrders.color
                })
              }else {
                prevData.forEach( prevOrders => {
                  currOrders.label === prevOrders.label &&
                    incrementData.push(
                      {
                        label: prevOrders.label,
                        increment: prevOrders.amount !== 0 &&
                          getIncrease(currOrders.amount, prevOrders.amount),
                        color: currOrders.color
                      }
                    )
                });
              }
            });

            setOrderPercent({
              name: incrementData[0].label,
              percent: incrementData[0].increment,
              color: incrementData[0].color
            });
            setincrementPercents(incrementData);
          }
        });
      }
    });
  }

  useEffect(() => {
    getDistributorData();
    subtractMonthString(month);
    setProgressBar(prev => prev + 30 );
  }, []);


  const config = {
    labels: currentDistriData.length > 0?
     currentDistriData.map( orders => orders.label):[],
    datasets: [
      {
        data: currentDistriData.length > 0 ?
         currentDistriData.map( orders => orders.amount):[],
        backgroundColor: currentDistriData.length > 0 ?
         currentDistriData.map( orders => orders.color):[],
        borderWidth: 4,
        borderColor: palette.white,
        hoverOffset: 5
      },
    ],
  };

  const percent = {
    id: 'percent',
    beforeDraw( chart, args, options ){
      const { ctx, chartArea:{ right, bottom } } = chart;
      ctx.save();
      ctx.font = '30px Poppins';
      ctx.textAlign = 'center';
      ctx.fillStyle = options.fontColor;
      if(options.percent !== 0){
        if(options.percent > 0){
          ctx.fillText(
          '+' + (options.percent).toLocaleString(locale, {
            maximumFractionDigits:1,
            minimumFractionDigits:1
          }) + '%', (right/2), (bottom/2))
        }else{
          ctx.fillText((options.percent).toLocaleString(locale, {
            maximumFractionDigits:1,
            minimumFractionDigits:1
          }) + '%', (right/2), (bottom/2));
        }
      }else {

      }
    },
  };

  const labelCharCenter = {
    id: 'labelCharCenter',
    beforeDraw( chart, args, options ){
      const { ctx, chartArea:{ right, bottom } } = chart;
      ctx.save();
      ctx.font = '14px Poppins';
      ctx.textAlign = 'center';
      if(options.percent !== 0){
        ctx.fillStyle = options.fontColor;
        ctx.fillText(options.label,
        (right/2), ((bottom/2)+28)
        );
        ctx.fillStyle = palette.secondary;
        ctx.fillText(' vs ' + (options.prevMonth),
        (right/2), ((bottom/2)+48)
        );
      } else {
        ctx.fillStyle = palette.secondary;
        ctx.fillText('No hubo ordenes ' ,
        ((right/2)), ((bottom/2))
        );
        ctx.fillStyle = options.fontColor;
        ctx.fillText( options.label ,
        ((right/2)), ((bottom/2)+20)
        );
        ctx.fillStyle = palette.secondary;
        ctx.fillText(' en ' + (options.prevMonth),
        ((right/2)), ((bottom/2)+40)
        );
      }
    }
  };

  let chartHeight = '69%';
  if(window.screen.width > 1600 && window.screen.height > 800){
    chartHeight = '59%';
  }

  const options = {
    maintainAspectRatio: true,
    responsive: true,
    radius: chartHeight,
    cutout: '83%',
    animation: {
      animateScale: true,
      animateRotate: true,
    },
    onHover (e, chartElement) {
      if(chartElement.length === 1){
        e.native.target.style.cursor = 'pointer';
      }else {
        e.native.target.style.cursor = '';
      }
    },
    onClick(click, element, chart){
      if (element[0]) {
        let orderPercents = {};
        incrementPercents.forEach( ({ label, increment, color }, index) => {
          
          if( element[0].index === index){
            orderPercents = {
            name: label,
            percent: increment,
            color
            };
          }
        })
        setOrderPercent(orderPercents);
        chart.draw();
      }
    },
    plugins: {
      legend:{
        display:false
      },
      tooltip: {
        intersect: true,
        callbacks: {
          label: function(tooltipItem) {
            return (' ' + tooltipItem.label + ": " +
             "$ " + parseFloat(tooltipItem.parsed).toLocaleString(locale));
          },
        },
      },
      percent: {
        fontColor: palette.secondary,
        percent: orderPercent &&
        orderPercent.percent &&
        orderPercent.percent
      },
      labelCharCenter:{
        label: orderPercent && orderPercent.name,
        prevMonth,
        fontColor: orderPercent && orderPercent.color,
        percent: orderPercent &&
        orderPercent.percent &&
        orderPercent.percent
      }
    },
  };

 
  const { currMonthName } = subtractMonthString(month);

  return (
    <Fragment>
      {
        progressBar < 100 &&
          <ProgressBarLoader
            progress={progressBar}
            title='Reporte por Distribuidor'
          />
      }
      {
          currentDistriData.length === 0 ?
            (
              <Fragment>
                {
                  progressBar >= 100 &&
                  <Row className="mco-fade-in py-3 d-flex justify-content-center align-items-center" >
                    <Col lg="7" sm="7" xs="7" md="5">
                      <Card className="shadow-lg d-flex justify-content-center align-items-center">
                        <Card.Header className="py-1 mt-3">
                          <Col className="d-flex justify-content-between ">
                            <Col  className="" >
                              <Card.Title tag="h5" className="mb-0 fs-4 mt-2">
                                <h3>
                                  Reporte por distribuidor
                                </h3>
                              </Card.Title>
                            </Col>
                          </Col>
                        </Card.Header>
                        <Card.Body className="d-flex align-items-center mb-2 me-2">
                          <div className="ms-2 position-absolute ">
                            <FontAwesomeIcon
                              id="faChevronLeft"
                              icon ={faChevronLeft}
                              className=" fs-3 cursor-pointer  zindex-sticky"
                              onClick={ () => navigate(-1) }
                            />
                          </div>
                          <div className="d-flex mco-fade-in justify-content-center align-items-center mx-auto w-75 mb-2">
                            <div className=" ms-5 py-3 px-4 badge-soft-danger rounded-lg  badge">
                              <span className="fs-4">
                                  No hubo ordenes de
                                  <span className="text-secondary" > { distName } </span>
                                  en {' '} { currMonthName }
                              </span>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                }
              </Fragment>
            ):(

              progressBar >= 100 &&
              <Row className="d-flex justify-content-center mco-fade-in">
                <Row className="d-flex justify-content-between">
                  <Col xs="auto" className="d-none d-sm-block fs-1">
                  <h3>Reporte por Distribuidor</h3>
                  </Col>
                  <Col xs="6" md="2" className="mb-3" >
                    <Select
                    onChange={(e) => {
                      getDistributorData(e.value)
                      handleCurrentMonth(e)
                    }}
                    defaultValue={selectValues.find( e => e.value === month && e )}
                    className="react-select-container text-capitalize cursor-pointer"
                    classNamePrefix="react-select"
                    options={selectValues}
                    />
                  </Col>
                </Row>
                <Card className="d-flex">
                  <Row className="align-items-center">
                    <Card.Header className="py-0">
                      <Col className="d-flex justify-content-between ">
                        <Col  className="" >
                          <Card.Title tag="h5" className="mb-0 fs-4 mt-4 ms-2">
                            <span className="fs-3">
                              {
                                currentDistriData.length > 0 &&
                                currentDistriData[0].dist_name
                              }
                            </span>
                            {
                              loading &&
                                <Spinner
                                  animation="grow"
                                  variant="dark"
                                  type="grow"
                                  size="sm"
                                  className="mx-3"
                                />
                            }
                          </Card.Title>
                        </Col>
                      </Col>
                    </Card.Header>

                    <Col sm={6} md={6} lg={5} >
                      <Card.Body
                        style={{
                          "paddingLeft": "0px",
                        }}
                      >
                        <div className="align-self-center w-100 mco-fade-in">
                          <div className="mco-fade-in d-flex align-items-center">
                            <div className="ms-4 position-absolute zindex-sticky">
                              <FontAwesomeIcon
                                id="faChevronLeft"
                                icon ={faChevronLeft}
                                className=" fs-2 cursor-pointer ease-out"
                                onClick={ () => navigate(-1) }
                              />
                            </div>
                            <Doughnut
                              data={config}
                              options={options}
                              plugins={[percent, labelCharCenter]}
                              className="cursor-pointer "
                              height={chartHeight}
                            />
                          </div>
                        </div>
                      </Card.Body>
                    </Col>

                    {/* TABLA */}
                    {
                      progressBar >= 100 &&
                        <OrdersTable currentData={currentDistriData} />        
                    }
                  </Row>
                </Card>
              </Row>
            )
      }
    </Fragment>
  )
}

export default memo(DistributorPage);

