import { React, Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import useReports from '../../../../hooks/useReports';
import { MCO_CONFIGS } from "../../../../constants";
import moment from "moment/min/moment-with-locales";
import McoAutoPaginatedTable from "../../../../components/mco/components/McoAutoPaginatedTable";

const SimpleTable = ( { period } ) => {

	const {
		loading,
		simpleDataDetails,
		getSimpleDataDetails,
		getAllSimpleDataDetails,
		simpleDataDetailsExport
	} = useReports();

	const [ sort  , setSort] = useState( { accessor:'total_amount' , direction:'DESC'} );
	const { locale } = MCO_CONFIGS;

	/**Set columns */
  	const tableColumns = [
		// {
		// 	Header: "MES",
		// 	accessor: "period",
		// 	headerClassName : "text-center",
		// 	className: " text-center",
		// 	Cell: (props) => {
		// 		return props.value? moment(props.value).format("DD/MM/YYYY") : '-';
		// 	},
		// 	Filter: false,
		// },
		{
			Header: "SKU",
			accessor: "sku",
			headerClassName : "text-center",
			className: " text-center",
			Filter: false,
		},
		{
			Header: "PRODUCTO",
			accessor: "product_name",
			headerClassName : "",
			className: "",
			Filter: false,
		},
		{
			Header: "COMPRADORES",
			accessor: "total_users_buy",
			headerClassName : "text-right",
			className: " text-right",
			Filter: false,
		},
		{
			Header: "ORDENES",
			accessor: "total_orders_count",
			headerClassName : "text-right",
			className: " text-right",
			Filter: false,
		},
		{
			Header: "CANTIDAD",
			accessor: "product_quantity",
			headerClassName : "text-right",
			className: " text-right",
			Filter: false,
		},
		{
			Header: "VALOR",
			accessor: "total_net_revenue",
			headerClassName : "text-right",
			className: " text-right",
			Filter: false,
			Cell: ( props ) => {
			return props.value ? "$  " + parseFloat(props.value).toLocaleString(locale, {
				maximumFractionDigits:2,
				minimumFractionDigits:2
				}) : 0;
			},
		},
	];

	const dataProcessed = [];

	useEffect( () => {
		getSimpleDataDetails( period );
		getAllSimpleDataDetails( period );
	}, [ period ] );

	return (
		<Fragment>
			<Container fluid className="p-0 mco-fade-in" >
				<Row>
					<Col lg="12">
						<McoAutoPaginatedTable
							data={simpleDataDetails}
							customPageSize={25}
							loading={loading}
							columns={tableColumns}
							dataForExport={simpleDataDetailsExport}
							title={`Apertura por Mes y Periodo`}
							fileName={`reporte_aperturas`}
		                  />
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
}

export default SimpleTable;