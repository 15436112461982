/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SummaryService {

    /**
     * Get All metrics from current and last month to show in main page
     * This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static summaryMain(
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/summary/main-page',
            query: {
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get a year Metrics to use in main page charts
     * This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static summaryMainCharts(
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/summary/main-page/charts',
            query: {
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

}