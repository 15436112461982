import { types } from "../types/types"


const initialState = {
  addMode: true,
  delMode: false,
}

export const usersReducer = ( state = initialState, action ) => {

  switch (action.type) {

    case types.register:
      return initialState;

    case types.update_user:
      return { 
        user: action.payload.user,
        addMode: false,
        delMode: false,
      }
    
      case types.delete_user:
        return { ...state }

      case types.register_success:
        return {
          ...state,
          success: true,
          text:    "Usuario registrado con exito ✅",
          bgColor: "bg-success",
          textColor: "text-success",
          addMode: true,
          delMode: false,
        }
      
      case types.update_success:
        return {
          ...state,
          success: true,
          text:    "Los cambios se guardaron con exito ✅",
          bgColor: "bg-success",
          textColor: "text-success",
          addMode: true,
          delMode: false,
        }

      case types.action_errors:
        return {
          ...state,
          action: "Error",
          error:true,
          msg: action.payload.msg || "Hubo un error !"
        }
      
      case types.clean_state:
        return {
          ...state,
          success: false,
          error:false,
        }
    
 
  default:
    return state
  }
}
