import { types } from "../types/types";
import { faPlay, faCheck, faRadiation,faExclamationCircle, faClock , faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

export const initialState = {
  key: 0,
  state: 'ready',
  statusDetails:{
    badgeClass:  "pe-none",
    buttonIcon:  faPlay,
    buttonClass: "cursor-pointer",
    statusColor: "info",
    fontColor:   "text-white",
    buttonColor: "outline-primary",
  },
  loading: false,
  msg: 'Ready to run Cron'
}

export const statusCronReducer = ( state=initialState, action ) => {

  switch (action.type) {

    case types.ready:
      return {
        ...initialState,
        key: action.payload.key
      }

    case types.runing:
      return {
        key:action.payload.key,
        state: 'runing',
        statusDetails:{
          badgeClass:  "pe-none",
          buttonClass: "pe-none",
          variant:     "primary",
          statusColor: "primary",
          fontColor:   "text-white",
          buttonColor: "outline-primary",
        },
        loading: true,
        msg: 'runing cron'
      }
  
    case types.success:
      return {
        key:   action.payload.key,
        state: action.payload.status,
        statusDetails: {
          badgeClass:  "pe-none",
          buttonIcon:  faCheck,
          statusColor: "success",
          buttonClass: "pe-none",
          fontColor:   "text-white",
          buttonColor: "outline-success",
        },
        msg: action.payload.msg,
        loading: false,
      }
    
    case types.failed:
      return {
        key:action.payload.key,
        state: action.payload.status,
        statusDetails: {
          buttonClass: "none",
          statusColor: "danger",
          buttonIcon:  faRadiation,
          fontColor:   "text-white",
          buttonClass: "cursor-pointer",
          badgeClass:  "cursor-pointer", 
          buttonColor: "outline-danger",
          messageIcon: faExclamationCircle,
        },
        loading: false,
        msg: action.payload.msg
      }
    
    case types.waiting:
      return {
        key:action.payload.key,
        state: 'pending',
        statusDetails: {
          buttonClass: "pe-none",
          statusColor: "secondary",
          buttonIcon:  faClock,
          fontColor:   "text-white",
          badgeClass:  "cursor-pointer", 
          buttonColor: "outline-secondary",
        },
        loading: false,
        msg: action.payload.msg
    }

    case types.warning:
      return {
        key:action.payload.key,
        state: 'warning',
        statusDetails: {
          buttonClass: "cursor-pointer",
          statusColor: "warning",
          buttonIcon:  faExclamationTriangle,
          fontColor:   "text-secondary",
          badgeClass:  "cursor-pointer", 
          buttonColor: "outline-warning",
          messageIcon: faExclamationTriangle,
        },
        loading: false,
        msg: action.payload.msg
    }
  
    default:
      return state;
  }
}