import { useContext } from "react";

import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { BenefitsContext } from "../layout/BenefitsLayout";



let statuses = {
  "wc-completed" : "Completadas",
  "wc-ready-to-delivery" : "Listo para entregar",
  "wc-in-assembly" : "En armado",
  "wc-processing" : "Procesando"
}


const BenefitsInfoCards = ({ subGroup, discount, qty, orders }) => {
  
  const { currentMonth } = useContext( BenefitsContext );
  const { pathname } = useLocation();
  const [t] = useTranslation();

  let orderDetails = Object.keys( statuses ).map(s => {
  
    if( orders[s] ){
      return {
        "total_discounted" : orders[s].total_discounted,
        "total_quantity"  : orders[s].total_quantity,
        "label" : statuses[s]
      };
    }
    return {
      "total_discounted" : 0,
      "total_quantity"  : 0,
      "label" : statuses[s]
    };
  })


  return (

      <Card className="flex-fill">
        <Card.Body className="py-4">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="mb-3 fs-3">{ t(subGroup) }</h4>
            <div style={{marginTop:-16}}>
              <span className="text-end text-success" title="Porcentaje de compras por cantidad de usuarios"> 
                
              </span>
              </div>
          </div>
          
            <div className="mb-1 d-flex align-items-center justify-content-start">
              <span className="me-2 fs-5">
                Descuento :
              </span>
              <strong className="fs-4">
                $ { discount.toLocaleString() }
              </strong>
            </div>
            
            <div className="mb-1 d-flex align-items-center justify-content-start">
                <span className="me-2 fs-5">
                  Cantidad:
                </span>
                <strong className="fs-5"> { qty.toLocaleString() }</strong>
            </div>

            <hr/>

            {
              orderDetails.length &&
              (
                orderDetails.map( (e, i)=> (
                  <div key={i} className="mb-1 d-flex align-items-center justify-content-start">
                    <span className="me-2 fs-5">
                      { e["label"] }
                    </span>
                    <span className="me-2 fs-5">
                       <strong>  $ { e["total_discounted"].toLocaleString() } </strong> 
                    </span>
                    <strong className="fs-5"> </strong>
                  </div>
                ))
              )
            }
        </Card.Body>
        <hr className="mb-2 mt-0"/>

        <div className="d-flex align-items-center justify-content-end">          
          { 
            qty !== 0 ? 
            (
              <div  className="ms-3 me-3 mb-2" >
                <Link to={`${pathname}/details/`+subGroup+"/?month="+currentMonth} className="cursor-pointer" > Ver Compras </Link>
              </div>
            ):(
              <div className="text-muted ms-3 me-3 mb-2">
                  <span>Sin detalles</span>
              </div>
            )
          }
        </div>
      </Card>
  );

};

export default BenefitsInfoCards;



