
const getIncrease = (current = 0, prev = 0) => {
    if( parseFloat(prev) === 0.0 ){
      return 100;
    }
    let increase = current - prev;
    let percent = increase / prev * 100;
    return percent;
}

export default getIncrease;