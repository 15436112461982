/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * List users.
     * List all api users with roles and permissions.
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @param name
     * @param email
     * @param role
     * @returns any ok
     * @throws ApiError
     */
    public static listUsers(
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
        name?: string,
        email?: string,
        role?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users',
            query: {
                'per_page': perPage,
                'page': page,
                'export': _export,
                'name': name,
                'email': email,
                'role': role,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Create Or update users.
     * Create or update users data and associated roles.
     * @param requestBody User Data
     * @returns any Success
     * @throws ApiError
     */
    public static craeteUpdateUsers(
        requestBody: {
            id: string;
            name?: string;
            email?: string;
            /**
             * User roles
             */
            roles?: Array<string>;
            password?: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Sof delete a user by ID
     * Soft delete a user by ID.
     * @param id
     * @returns any ok
     * @throws ApiError
     */
    public static softDeleteUsers(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

}