import { useContext } from 'react';
import { ProductsContext } from '../contexts/ProductsContext';

const useProductsData = () => {

  const productsContext = useContext(ProductsContext);

  if (!productsContext)
    throw new Error("ProductsContext must be placed within AuthProvider");
  return productsContext;
};

export default useProductsData;