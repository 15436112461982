import { types } from "../types/types"
import {
  faDollarSign,
  faShoppingBasket
} from "@fortawesome/free-solid-svg-icons";

const initialState = {
  name: '',
  amount: 0,
  units: 0,
  color: '',
  icon: '',
  iColor: ''
}

export const businessReducer = (state=initialState, action) => {
  
  switch (action.type) {

    case types.business:
      return { 
        name:   action.payload.name,
        color:  action.payload.color,
        icon:   action.payload.icon,
        iColor: action.payload.iconColor,
        amountCard:{
          amount: Number(action.payload.amount),
          amountIcon: faDollarSign,
          prefix: '$'
        },
        unitsCard:{
          units: Number(action.payload.units),
          unitsIcon: faShoppingBasket
        }, 
      }

    default:
      return state;
  }
}
