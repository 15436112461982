import { Fragment, useContext, useEffect, useState } from 'react'

import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import usePalette from '../../../../hooks/usePalette';
import useEmployees from '../../../../hooks/useEmployees';
import { BenefitsContext } from "../layout/BenefitsLayout";
import { BenefitsBarChart } from '../charts/BenefitsBarChart'
import { BenefitsPieChart } from '../charts/BenefitsPieChart';
import subtractMonthString from '../../../../components/mco/helpers/subtractMonth';
import { SuspensivePointsLoader } from '../../../../components/mco/components/McoLoaders';
import getIncrease from '../../../../components/mco/helpers/getIncrease';
import { getPercent } from '../../../../components/mco/helpers/getPercent';

const initialState = {
  data:[],
}

export const BenefitsCharts = () => {

  const { 
    groups,
    subGroups,
    prevGroups,
    isLoading,
    currentMonth,
    reportCouponsGrouped,
    reportCouponsSubGrouped,
  } = useContext(BenefitsContext);

  const palette = usePalette();

  const [ dataBarChart, setDataBarChart ] = useState([]);

  const [ dataGroupsChart, setDataGroupsChart ] = useState([]);

  const [ dataRankingPieChart, setDataRankingsPieChart] = useState([]);

  const { prevMonthName } = subtractMonthString(currentMonth);

  const { chartMode } = useSelector( state => state.productsChartMode );

  
  const amount = "Amount";
  
  const { 
    isLoading:loading,
    customerCompanies,
    fetchNewBuyersEmployees
  } = useEmployees();
  
  
  const { data_groups, TOTAL_DISCOUNTED, TOTAL_CANT } = groups;

  
  useEffect(() => {
    let isMounted = true;

    fetchNewBuyersEmployees(currentMonth);
    reportCouponsGrouped({startDate: currentMonth});
    reportCouponsSubGrouped({ group:'massive-coupons', startDate:currentMonth });

    return () => isMounted = false;
  }, [currentMonth]);



  useEffect(() => {

    let isMounted = true;

    let corporativesData = [];
    let massivesData = [];
    
    if (customerCompanies.length) {
      const companiesData = customerCompanies.map( e => ({
        sub_group: e.role,
        cant: Object.values(e.total_orders).reduce((a, b) => parseInt(a) +  parseInt(b), 0),
        total_discounted: Object.values(e.total_discounted).reduce((a, b) => parseFloat(a) +  parseFloat(b), 0),
      }))
      
      companiesData.sort((a, b) =>  b.total_discounted - a.total_discounted )

      corporativesData = (companiesData.filter(e => e.total_discounted !== 0));

    
      
    }

    

    if(subGroups.length){ 

      const data = subGroups.map(e => ({
        group: e.group,
        sub_group: e.sub_group,
        cant: Object.values(e["orders"]).reduce((a,b) => a + ( b.total_quantity || 0 ) , 0),
        total_discounted: Object.values(e["orders"]).reduce((a,b) => a + ( b.total_discounted || 0 ) , 0),
      }))

      massivesData = data.sort(( a, b ) => b.total_discounted - a.total_discounted);

    }

    setDataBarChart([
      {
        data: corporativesData,
        colorChart: palette.primary,
        benefit: 'Corporativos'
      },
      {
        data: massivesData,
        colorChart: palette.success,
        benefit: 'Masivos'
      },
      
    ]);

    return () =>  isMounted = false;

  }, [customerCompanies, subGroups]);

  useEffect(() => {
    let isMounted = true;

   
  
    return () =>  isMounted = false;
  }, [subGroups])
  

  useEffect(() => {
    let isMounted = true;

      if ( prevGroups && data_groups && data_groups.length ) {

        let labelDiscountsCenterChart = [];
        let labelQtyCenterChart = [];

        let discountInc = 0;
        let qtyInc = 0;

        if(chartMode === amount){

          const prevData = prevGroups.prev_data

          const sortByDiscount = data_groups.sort( ( a, b ) =>  b.total_discounted - a.total_discounted );
          const sortPrevByDiscount = prevData.sort( ( a, b ) =>  b.total_discounted - a.total_discounted );

          let currDiscount = ( sortByDiscount[0].total_discounted || 0 );
          let prevDiscount = 0;

          const prevItem = ( sortPrevByDiscount.filter( elm => elm.group === sortByDiscount[0].group) );

          if(prevItem.length){
            prevDiscount =  ( prevItem[0].total_discounted )
          } else {
            prevDiscount = 0
          }

          discountInc = getIncrease( currDiscount, prevDiscount )

          
          labelDiscountsCenterChart.push({
            group: sortByDiscount[0].group,
            prevMonth: prevMonthName,
            increment: discountInc,
            color: discountInc < 0 ? palette.danger : palette.success,
            titleColor: sortByDiscount[0].color
          })

         
          
          setDataGroupsChart (
            {
              chart: "groups",
              data:{
                mode: "Amount",
                groups: sortByDiscount.map( e => ({
                  ...e,
                  values: e.total_discounted,
                  percent: getPercent(e.total_discounted, TOTAL_DISCOUNTED)
                })),
                prev_groups: sortPrevByDiscount,
                increments: labelDiscountsCenterChart,
              }
              
            }
          )

        } else {

          const prevData = prevGroups.prev_data

          const sortByQty = data_groups.sort( ( a, b ) =>  b.cant - a.cant );
          const sortPrevByQty = prevData.sort( ( a, b ) =>  b.cant - a.cant );

          let currQty = ( sortByQty[0].cant || 0 );
          let prevQty = 0;
  
          const prevItem = ( sortPrevByQty.filter( elm => elm.group === sortByQty[0].group) );


          if(prevItem.length){
            prevQty = ( prevItem[0].cant )
          } else {
            prevQty = 0
          }          
  
     
          qtyInc = getIncrease( currQty, prevQty )
  
  
          labelQtyCenterChart.push({
            group: sortByQty[0].group,
            prevMonth: prevMonthName,
            increment: qtyInc,
            color: qtyInc < 0 ? palette.danger : palette.success,
            titleColor: sortByQty[0].color
          })

          setDataGroupsChart (
            {
              chart: "groups",
              data:{
                mode: "Units",
                groups: sortByQty.map( e => ({
                  ...e,
                  values: e.cant,
                  percent: getPercent(e.cant, TOTAL_CANT)
                })),
                prev_groups: sortPrevByQty,
                increments: labelQtyCenterChart,
              }
              
            }
          )

        }
        
      } 

    return () => isMounted = false;
  }, [ groups, chartMode ])


  return (
    <Fragment>
      {
        !isLoading ?
        (
          <Fragment>
            <Row className='mb-0'>
              <Col lg={8}>
                {
                  dataBarChart.length && dataBarChart.map( e => (
                    <BenefitsBarChart 
                      data={e.data}
                      color={e.colorChart}
                      isLoading={loading}
                      benefit={e.benefit}
                    />
                  ))
                }
              </Col>
              <Col lg={4}>
                <BenefitsPieChart data={dataGroupsChart} loading={isLoading} />
              </Col>
            </Row>
          </Fragment>
        ):
        (<SuspensivePointsLoader title={'graficas'} />)
      }
    </Fragment>
  )
}
