import { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from "react-helmet-async";
/* import useReports from '../../../../hooks/useReports'; */
import useReportBuyXGetY from '../../../../hooks/useReportBuyXGetY';
import { Container, Col, Row } from "react-bootstrap";
import McoPaginatedTable from '../../../../components/mco/components/McoPaginatedTable';
import { useSelectDate } from '../../../../hooks/useSelectDate';
import DateSelector from '../../../../components/mco/components/DateSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import moment from "moment/min/moment-with-locales";



const BuyXgetYReportsTable = () => {
	//default sort
	const { show } = useSelector(state => state.reports)
	const [sort, setSort] = useState({ accessor: 'name', direction: 'DESC' });


	const {
		loading,
        pageCount,
        wdrData,
        exportLoading,
        dataProcessed,
        getReportWDR,
        getExportReportWDR,
		dataResp
	} = useReportBuyXGetY();

	const [
		currentMonth,
		handleCurrentMonth,
		selectValues
	] = useSelectDate();

	useEffect(() => {

		getReportWDR({
			start_date: currentMonth,
		});

		getExportReportWDR({
			start_date: currentMonth,
		});
	}, [currentMonth]);


	const fetchData = useCallback(({ pageIndex, state }) => {

		getReportWDR({
			start_date: currentMonth,
			order_id: state.type === "Id de Orden" ? state.values : undefined,
			product_id: state.type === "Id" ? state.values : undefined,
			//product_name: state.type === "Nombre" ? state.values : undefined,
			order_status: state.type === "Estado" ? state.values : undefined,
			type: state.type === "Tipo" ? state.values : undefined,
			
			page: pageIndex + 1,
			order_by: sort.accessor,
		})

	}, [sort, currentMonth, show]);


	const columnHeaderClick = (column) => {

		switch (column.sortDirection) {
			case 'none':
				setSort({ accessor: column.id, direction: 'ASC' });
				break;
			case 'ASC':
				setSort({ accessor: column.id, direction: 'DESC' });
				break;
			case 'DESC':
				setSort({ accessor: column.id, direction: 'ASC' });
				break;
			default:
		}
	};

	const buyXgetYColumns = [
		{
			Header: "Id de Orden",
			accessor: "order_id",
			className: "col-1 text-uppercase",
			headerClassName: "col-1",
			sortType: 'basic',
			sortDirection: sort.accessor === 'order_id' ? sort.direction : 'none',
		},
		{
			Header: "Id Producto",
			accessor: "product_id",
			width: 250,
			className: "col-1",
			headerClassName: "col-1",
			sortType: 'basic',
			sortDirection: sort.accessor === 'product_id' ? sort.direction : 'none',
		},
		{
			Header: "Producto",
			accessor: "product_name",
			width: 250,
			className: "col-2",
			headerClassName: "col-2",
			sortType: 'basic',
			sortDirection: sort.accessor === 'product_name' ? sort.direction : 'none',
		},
		{
			Header: "Cant",
			accessor: "product_qty",
			width: 250,
			className: "col-2",
			headerClassName: "col-2",
			sortType: 'basic',
			sortDirection: sort.accessor === 'product_qty' ? sort.direction : 'none',
		},
		{
			Header: "Precio con Descuento",
			accessor: "discount_price",
			className: "col-1 text-uppercase",
			headerClassName: "col-1",
			sortType: 'basic',
			sortDirection: sort.accessor === 'discount_price' ? sort.direction : 'none',
		},
		
		{
			Header: "Fecha de Orden",
			accessor: "order_date",
			className: "col-2",
			headerClassName: "col-2",
			sortType: 'basic',
			sortDirection: sort.accessor === 'order_date' ? sort.direction : 'none',
			Cell: (props) => {
				return props.value? moment(props.value).format("DD/MM/YYYY") : '-';
			}
		},
		{
			Header: "Tipo",
			accessor: "type",
			className: "col-2",
			headerClassName: "col-2",
			sortType: 'basic',
			sortDirection: sort.accessor === 'type' ? sort.direction : 'none',
		},

	];

	return (
		<Fragment>
			<Helmet title="Reporte compro X llevo Y" />
			<Container fluid className="p-0" >
				<Row className="d-flex justify-content-between">
					<Col xs="auto" className="d-none d-sm-block fs-1">
						<h3>
							Reporte compro X llevo Y
						</h3>
					</Col>
					<DateSelector
						selectValues={selectValues}
						handleCurrentMonth={handleCurrentMonth}
					/>
				</Row>

				<Row className="mb-4 mco-fade-in">
					<McoPaginatedTable
						columns={buyXgetYColumns}
						dataForExport={dataProcessed}
						pageCount={pageCount}
						fetchData={fetchData}
						data={wdrData}
						customPageSize={50}
						loading={loading}
						fileName={"reporte_buyXgetY"}
						/* exportLoading={exportLoading} */
						onHeaderClick={columnHeaderClick}
						title={`Ordenes con promoción "compro X llevo Y"`}
					/>
				</Row>
			</Container>
		</Fragment>
	)
}

export default BuyXgetYReportsTable;