import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";

import { OpenAPI, PromosDiscountsService } from "../../../services/openapi";
import { Container, Col, Row } from "react-bootstrap";
import McoToken from "../../../components/McoToken";
import { MCO_CONFIGS } from "../../../constants";

import McoAutoPaginatedTable from "../../../components/mco/components/McoAutoPaginatedTable";
import moment from "moment/min/moment-with-locales";

const { getTotalCouponsPeriod } = PromosDiscountsService;

const McoDiscountsMoreUsedTableRender = ({currentMonth}) => { 
  const { locale } = MCO_CONFIGS;
  const customPageSize = 15;
  // const [ startMonth, setStartMonth ] = useState( moment(currentMonth).startOf('month').format('YYYY-MM-DD') );
	const [ data, setData ] = useState( [] );
	const [ is_loading, setLoading ] = useState( false );
		
	const getDataFromAPI = async ({ startMonth }) => {
		// const endMonth = moment(startMonth).endOf('month').format('YYYY-MM-DD');
        try{
            OpenAPI.TOKEN = McoToken();
              const discounts = await getTotalCouponsPeriod(
                undefined, 
                'coupon',
                'total_used',
                'DESC',
                moment(currentMonth).startOf('month').format('YYYY-MM-DD'),
                10000 // all
              );
              return discounts;
		}catch (error){
    		console.log("error while fetching", error);
		}
	};

    useEffect(() => {
        ( async () => {
          setLoading(true);
          await getDataFromAPI( currentMonth )
          .then( ({ data }) => {
            if( data ){
                setData( data );
            }else {
                return;
            }
          }
        )
          .catch(error => console.log(error))
          .finally(() => setLoading(false));
        })();
    
      }, [ currentMonth ]);

  const tableColumns = [
      {
        Header: "Cupón",
        accessor: "name",
      },
      {
        Header: "Cupones Usados",
        accessor: "total_used",
      },
      {
        Header:   "Acumulado descuentos",
        accessor: "total_amount_discounted",
        Cell: (props) => {
          return (<span> ${props.value ? Number(props.value).toLocaleString(locale, { maximumFractionDigits: 2 }) : 0}</span>);
        }
      },
  ];

	//console.log(data);
	return (
		<React.Fragment>

    <Helmet title="Reportes de Distribuidores mco-fade-in" />
      <Container fluid className="p-0 mco-fade-in">
        <Row>
          <Col lg="12">
            <McoAutoPaginatedTable
                columns={tableColumns}
                data={data}
                title='Cupones'
                subtitle='Listado completo de cupones'
                is_loading={is_loading}
                customPageSize = {customPageSize}
            />
            </Col>
          </Row>
			</Container>
		</React.Fragment>
	);
};
export default McoDiscountsMoreUsedTableRender;