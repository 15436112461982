import { createContext, useState, useCallback } from "react";
import { DistributorsService, OpenAPI  } from "../services/openapi";
import usePalette from "../hooks/usePalette";
import McoToken from "../components/McoToken";
import moment from "moment/min/moment-with-locales";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DistributorsContext = createContext(null);
const { getDistributorsList, getDistributorsResume } = DistributorsService;

function DistributorsProvider({children}) {

  const [ loading, setLoading ] = useState(true);
  const palette = usePalette();
  const [ t ] = useTranslation();
  const { search } = useLocation();

  const orderStructure = [
    {
      key: "wc-completed",
      color: palette.success,
      position: 0
    },
    {
      key: "wc-ready-to-delivery",
      color: palette.cyan,
      position: 1
    },
    {
      key: "wc-processing",
      color: palette.strong_green,
      position: 2
    },
    {
      key: "wc-in-assembly",
      color: palette.warning,
      position: 3
    },
    {
      key: "wc-on-hold",
      color: palette.orange,
      position: 4
    },
    {
      key: "wc-pending",
      color: palette.purple,
      position: 5
    },
    {
      key: "wc-cancel-request",
      color: palette["gray-600"],
      position: 6
    },
    {
      key: "wc-refunded",
      color: palette.brown,
      position: 7
    },
    {
      key: "wc-cancelled",
      color: palette.danger,
      position: 8
    },
    {
      key: "wc-failed",
      color: palette.dark_red,
      position: 9
    },
    {
      key: "wc-in-progress",
      color: palette.orange,
      position: 10
    },
    {
      key: "wc-returned",
      color: palette.pink,
      position: 11
    },
    {
      key: "wc-return-request",
      color: palette["gray-300"],
      position: 12
    },
  ];

  const subtractMonthString = (month = search.split('=')[1]) => {

    const currMonthName = (moment(month).locale("es").format('MMMM'));

    const prevMonthName = (moment(month)
    .subtract(1, 'months').locale("es")
    .format('MMMM'));

    let subtract = moment(month).subtract(1, 'months');
    const prevDate = moment(subtract).format('YYYY-MM');

    return {
      prevMonthName,
      prevDate,
      currMonthName
    }
  }

  const getIncrease = (current = 0, prev = 0) => {
    if( parseFloat(prev) === 0.0 ){
      return 100;
    }
    let increase = current - prev;
    let percent = increase / prev * 100;
    return percent;
  }

  const fillArray = ( data ) => {

    let orderType = [];
    let keys = [];
    let values = [];
    let distName = [];

    data.forEach(e => {
      distName = e.dist_name? e.dist_name : '';
      keys = e.total_orders? Object.keys(e.total_orders) : [];
      values = e.total_orders? Object.values(e.total_orders) : [];

      values.forEach(( s, i ) => {
        if(typeof keys[i] != 'undefined'){
          if(!orderType[keys[i]]){
              orderType[keys[i]] = {
                total_amount: s.total_amount,
                total_orders: s.total_orders
              };
          }else{
            orderType[keys[i]]['total_amount'] += s.total_amount;
            orderType[keys[i]]['total_orders'] += s.total_orders;
          }
        }
      });
    });

    return{
      distName,
      orderType,
      keys,
      values
    };
  }

  // TABLA DETALLE DE DISTRIBUIDORES
  const getAllDistributorsListTable = async ( currentMonth, exportable = 0 ) => {
    setLoading(true);
    let currDistributorsData;
    OpenAPI.TOKEN = McoToken();
      try{
        const currDistributors = await getDistributorsList(
          undefined, // distId
          undefined, // distName
          undefined, // distEmail
          currentMonth, // startDate
          300, // perPage
          undefined, // page
          exportable //  export
        );
        setLoading(false);
        currDistributorsData = (currDistributors.data);
      }catch (error){
        console.log(error);
        //throw new Error(error);
      }
      if(currDistributorsData && currDistributorsData.length > 0){
      setLoading(false);
      const cDistributorsData = fillArray(currDistributorsData);
      const { orderType } = cDistributorsData;
      const TOTAL_AMOUNT = Object.values(orderType).reduce((a, b) => a + b.total_amount,0);
      let currOrderDistributorsData = [];
      orderStructure.forEach( state  => {
        Object.keys(orderType).forEach( k => {
          if(state.key === k){
            currOrderDistributorsData.push(
              {
                key: k,
                label: t((state.key).replace('wc-', '')),
                amount: orderType[state.key].total_amount || 0,
                orders: orderType[state.key].total_orders || 0,
                percent: (orderType[state.key].total_amount / TOTAL_AMOUNT * 100) || 0,
                color: state.color
              }
            );
          }
        });
      });
      const distriCurrData = currOrderDistributorsData.filter( orders => orders.percent > 1 );
      return {
        distriCurrData,
        currDistributorsData
      };
    } else {
      setLoading(false);
      return {
        distriCurrData: [],
        currDistributorsData: [],
      }
    }
  };

  //? TODOS LOS DISTRIBUIDORES CON DATA DEL MES ACTUAL - GRAFICA RESUMEN
  const getAllDistributorsList = async ( currentMonth, exportable = 0 ) => {

    setLoading(true);

    OpenAPI.TOKEN = McoToken();

    return (

      await getDistributorsResume( currentMonth )
      .then(({ data }) => {
  
        if( data.length ){
  
        const TOTAL_AMOUNT = data.reduce((a, b) => parseFloat(a) + parseFloat(b.total_amount) || 0 ,0);
  
        let currOrderDistributorsData = [];
  
        orderStructure.forEach( state  => {
          data.forEach( k => {
  
            if(state.key === k.order_status){
              currOrderDistributorsData.push(
                {
                  key: k.order_status,
                  label: t((state.key).replace('wc-', '')),
                  amount: k.total_amount,
                  orders: k.total_orders,
                  percent:k.total_amount/TOTAL_AMOUNT * 100 || 0 ,
                  color: state.color
                }
              );
            }
          });
        });
  
        const distriCurrData = currOrderDistributorsData.filter( orders => orders.percent > 1 );
  
        setLoading(false);
  
        return {
          distriCurrData,
          data
        }
  
        } else {
  
          setLoading(false);
          return {
            distriCurrData: [],
            currDistributorsData: [],
          }
  
        }
  
      })
      .catch( err => {
          setLoading(false);
          console.log(err)
          throw new Error(err);
      })
    )


    

  };

  // TODOS LOS DISTRIBUIDORES CON DATA DEL MES ANTERIOR - GRAFICA RESUMEN
  const getAllDistributorsPrevList = async ( currentMonth ) => {

    setLoading(true);

    const { prevDate } = subtractMonthString(currentMonth);

    OpenAPI.TOKEN = McoToken();

    return (

      await getDistributorsResume( prevDate )
      .then(({data}) => {

        if( data.length ){
    
          let prevOrderDistributorsData = [];
    
          orderStructure.forEach( state  => {
            data.forEach( k => {
    
              if(state.key === k.order_status){
                prevOrderDistributorsData.push(
                  {
                    key: k.order_status,
                    label: t((state.key).replace('wc-', '')),
                    amount: k.total_amount || 0,
                    orders: k.total_orders || 0,
                    color: state.color
                  }
                );
              }

            });
          });

          setLoading(false);
          return prevOrderDistributorsData;
    
        }else {
          setLoading(false);
          return [];
        }

      })
      .catch( err => {
        setLoading(false);
        console.log(err);
        throw new Error(err);
      })
    )   
  };

  //! DISTRIBUIDOR CON DATA DEL MES ACTUAL - GRAFICA DETALLE DE DISTRIBUIDOR
  const getCurrentDistributorData = async (id, month) => {

    setLoading(true);

    OpenAPI.TOKEN = McoToken();

    return (
      await getDistributorsList(
        id, // distId
        undefined, // distName
        undefined, // distEmail
        month, // startDate
        1, // perPage
        undefined, // page
        undefined //  export

      ).then ( ({ data }) => {

        if( data.length ){

          const currDistriData = fillArray(data);
    
          const { values, keys, orderType, distName } = currDistriData;
    
          const TOTAL_AMOUNT = values.reduce((a, b) => a + b.total_amount, 0);
    
          let currOrdersData = [];
    
          orderStructure.forEach( state  => {
            keys.forEach( k => {
    
              if(state.key === k){
                currOrdersData.push(
                  {
                    dist_name: distName,
                    key: k,
                    label: t((state.key).replace('wc-', '')),
                    amount: orderType[state.key].total_amount || 0,
                    orders: orderType[state.key].total_orders || 0,
                    percent: (orderType[state.key].total_amount / TOTAL_AMOUNT * 100),
                    color: state.color
                  }
                );
              }
            });
          });
    
          const currOrderData = currOrdersData.filter(orders => orders.percent > 1);
    
          setLoading(false);
    
          return {
            currOrderData,
            distName
          };

        }else{

          setLoading(false);

          return {
            currOrderData: [],
            distName: []
          };
        }

      }).catch( err => {
        console.log(err);
        setLoading(false);
        throw new Error(err);
      })
    )

  };

  //** DISTRIBUIDOR CON DATA DEL MES ANTERIOR - GRAFICA DETALLE DE DISTRIBUIDOR
  const getPreviousDistributorData = async (id, month) => {

    setLoading(true);

    OpenAPI.TOKEN = McoToken();

    const { prevDate } = subtractMonthString( month );
    
    return (
      
      await getDistributorsList(
        id, // distId
        undefined, // distName
        undefined, // distEmail
        prevDate, // startDate
        1, // perPage
        undefined, // page
        undefined //  export
      ).then( ({ data }) => {

        let prevOrderData = [];

        if( data.length ){
    
          const prevDistriData = fillArray(data);

          const { keys, orderType } = prevDistriData;

          
          orderStructure.forEach( state  => {
            keys.forEach( k => {    
              
              if( state.key === k ){
                prevOrderData.push({
                    label: t((state.key).replace('wc-', '')),
                    amount: orderType[state.key].total_amount || 0,
                    color: state.color
                })
              }

            });
          });

          setLoading(false);
          return prevOrderData;
          
        }else {
          setLoading(false);
          return [];
        }
      }).catch( err => {
        setLoading(false);
        console.log(err);
        throw new Error(err);
      })

    )

  };


  return (
    <DistributorsContext.Provider
      value={{
        getAllDistributorsListTable,
        getAllDistributorsList,
        getAllDistributorsPrevList,
        getCurrentDistributorData,
        getPreviousDistributorData,
        loading,
        orderStructure,
        subtractMonthString,
        getIncrease,
      }}
    >
      {children}
    </DistributorsContext.Provider>
  );

}

export { DistributorsContext, DistributorsProvider };
