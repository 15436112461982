import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Col, Container, Row } from 'react-bootstrap'
import BuyXgetYReportsTable from './reports-components/BuyXgetYReportsTable'


const BuyXgetYReports = () => {
  return (
    <React.Fragment>
    <Helmet title="Reportes" />
    <Container fluid className="p-0 mco-fade-in">
      <Row>
        <Col lg="12">
          <BuyXgetYReportsTable />
        </Col>
      </Row>
    </Container>
    </React.Fragment>
  )
}

export default BuyXgetYReports