import { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { Container, Row, Col } from "react-bootstrap";
import { useSelectDate } from '../../../hooks/useSelectDate';

import BusinessPieChart from './charts/BusinessPieChart';
import useBusiness from '../../../hooks/useBusiness';
import { SuspensivePointsLoader } from '../../../components/mco/components/McoLoaders';
import {
  faDollarSign,
  faShoppingBasket
} from "@fortawesome/free-solid-svg-icons";
import VerticalSwitcher from './products-components/VerticalSwitcher';
import DateSelector from '../../../components/mco/components/DateSelector';
import McoAutoPaginatedTable from '../../../components/mco/components/McoAutoPaginatedTable';
import { MCO_CONFIGS } from '../../../constants';

export const BusinessOverview = () => {

  const [ currentMonth, handleCurrentMonth, selectValues ] = useSelectDate();
  const { byBusiness, byPrevBusiness, loading, totals } = useBusiness(currentMonth);
  const { locale } = MCO_CONFIGS;

  const businessColumns = [
    
    {
      Header: "Negocio",
      accessor: "business",
      headerClassName:"col-2",
      className: "col-2",
    },
    {
      Header: "Cantidad de Ordenes",
      Filter:false,
      accessor: "total_orders",
      headerClassName:"col-1 text-right",
      className: "col-1 text-right",
      Cell: props => (
        parseInt(props.value).toLocaleString( locale )
      )
    },
    {
      Header: "Unidades Vendidas",
      Filter:false,
      accessor: "total_units_sold",
      headerClassName:"col-1 text-right",
      className: "col-1 text-right",
      Cell: props => (
        parseInt(props.value).toLocaleString( locale )
      )
    },
    {
      Header: "% Vendidas",
      Filter:false,
      accessor: "percent_units",
      headerClassName:"col-1 text-right",
      className: "col-1 text-right text-success",
      Cell: props => (
        (props.value).toLocaleString( locale, { maximumFractionDigits:1 , minimumFractionDigits: 1 } ) + " %"
      )
    },
    {
      Header: "Monto Facturado",
      Filter:false,
      accessor: "total_amount",
      headerClassName:"col-1 text-right",
      className: "col-1 text-right",
      Cell: props => (
         '$ ' + parseInt(props.value).toLocaleString( locale )
      )
    },

    {
      Header: "% Facturado",
      Filter:false,
      accessor: "percent_amount",
      headerClassName:"col-1 text-right",
      className: "col-1 text-right text-success",
      Cell: props => (
         (props.value).toLocaleString(locale, { maximumFractionDigits:1 , minimumFractionDigits: 1 }) + " %"
      )
    },
    
  ];

  const processData = ( arr=[] ) => {

    const data_processed = arr.map((e) => {
      return {

        "NEGOCIO" : ( e.business ),

        "CANTIDAD DE ORDENES" :   parseInt( e.total_orders ).toLocaleString( locale, { maximumFractionDigits:0 , minimumFractionDigits: 0 }),

        "UNIDADES VENDIDAS" :   parseInt(e.total_units_sold ).toLocaleString( locale, { maximumFractionDigits:0 , minimumFractionDigits: 0 }),

        "% VENDIDAS" :  e.percent_units.toLocaleString(locale, { maximumFractionDigits:1 , minimumFractionDigits: 1 }) + " %",
        
        "MONTO FACTURADO" : parseFloat( e.total_amount ).toLocaleString(locale, { maximumFractionDigits:2 , minimumFractionDigits: 2 }),
      
        "% FACTURADO" :   e.percent_amount.toLocaleString(locale, { maximumFractionDigits:1 , minimumFractionDigits: 1 }) + " %",
      };

    });

    return data_processed;
  }

  return (
    <Fragment>
      <Helmet title="Resumen"/>
      <Container fluid >
        <Row className="mb-xl-3 d-flex justify-content-between">
          <Col xs="auto" className="d-none d-sm-block">
            <h3 className="mb-3">Resumen de Negocios</h3>
          </Col>
          <DateSelector 
            selectValues={selectValues}
            handleCurrentMonth={handleCurrentMonth}
          />
        </Row>
      {
        loading &&
          <SuspensivePointsLoader title='Negocios'/>
      }
      {
        !loading &&
        <>
          <Row className='mco-fade-in mt-6' >
            <VerticalSwitcher
              iconUp={faDollarSign}
              iconDown={faShoppingBasket}
            />
            <Col className='d-flex flex-column align-items-between'>
              <BusinessPieChart
                byPrevBusiness={byPrevBusiness}
                byBusiness={byBusiness}
                loading={loading}
                totals={totals}
              />
            </Col>
          </Row>
          <hr/>
          <Row className='mt-5'>
            <McoAutoPaginatedTable
              hover={true}
              loading={loading}
              data={byBusiness}
              customPageSize={15}
              controlPageUp={false}
              fileName={"negocios"}
              controlPageDown={false}
              columns={businessColumns}
              title={"Tabla de Negocios"}
              dataForExport={processData(byBusiness)}
            />
          </Row>
        </>      
      }
      </Container>
    </Fragment>
  )
}

