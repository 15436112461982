/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomersService {

    /**
     * Get a customer employees list
     * This can only be done by the logged in user
     * @param email email
     * @param locality locality
     * @param city city
     * @param state state
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @returns any Success
     * @throws ApiError
     */
    public static getCustomersWithoutDistributors(
        email?: string,
        locality?: string,
        city?: string,
        state?: string,
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customers/without-distributors',
            query: {
                'email': email,
                'locality': locality,
                'city': city,
                'state': state,
                'per_page': perPage,
                'page': page,
                'export': _export,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get a customer employees list
     * This can only be done by the logged in user
     * @param email email
     * @param name name
     * @param surname surname
     * @param employeeId employee_id
     * @param company company
     * @param orderBy Order by ASC to DESC param
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @returns any Success
     * @throws ApiError
     */
    public static getCustomerEmployees(
        email?: string,
        name?: string,
        surname?: string,
        employeeId?: string,
        company?: string,
        orderBy: 'user_id' | 'email' | 'name' | 'surname' | 'employee_id' | 'company' = 'company',
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customers/employees',
            query: {
                'email': email,
                'name': name,
                'surname': surname,
                'employee_id': employeeId,
                'company': company,
                'orderBy': orderBy,
                'per_page': perPage,
                'page': page,
                'export': _export,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get registeered customers by role
     * This can only be done by the logged in user
     * @param role The customer role/ company
     * @param email email
     * @param name name
     * @param surname surname
     * @param orderBy Order by ASC to DESC param
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @returns any Success
     * @throws ApiError
     */
    public static getRegisteredCustomerByrole(
        role: string,
        email?: string,
        name?: string,
        surname?: string,
        orderBy: 'user_id' | 'email' | 'name' | 'surname' | 'role' = 'role',
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customers/registered/{role}',
            path: {
                'role': role,
            },
            query: {
                'email': email,
                'name': name,
                'surname': surname,
                'orderBy': orderBy,
                'per_page': perPage,
                'page': page,
                'export': _export,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get customer employees details
     * This can only be done by the logged in user
     * @param orderId order_id
     * @param name name
     * @param surname surname
     * @param employeeId employee_id
     * @param company company
     * @param orderBy Order by ASC to DESC param
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @returns any Success
     * @throws ApiError
     */
    public static getCustomerEmployeesDetails(
        orderId?: number,
        name?: string,
        surname?: string,
        employeeId?: string,
        company?: string,
        orderBy: 'order_id' | 'name' | 'surname' | 'employee_id' | 'company' | 'subtotal' | 'discount' | 'total_amount' = 'company',
        startDate?: any,
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customers/employees/detail',
            query: {
                'order_id': orderId,
                'name': name,
                'surname': surname,
                'employee_id': employeeId,
                'company': company,
                'orderBy': orderBy,
                'start_date': startDate,
                'per_page': perPage,
                'page': page,
                'export': _export,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Return new customers between two dates
     * This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static getNewCustomers(
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customers/new-customers',
            query: {
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Return new employees between two dates grouped by companies
     * This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static getNewEmployees(
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customers/new-employees',
            query: {
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Number of unique buyers between dates
     * This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static getNewBuyers(
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customers/distinct-customers-by-dates',
            query: {
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Number of unique employees orders between dates grouped by company
     * This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static newBuyersEmployees(
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customers/distinct-employees-by-dates',
            query: {
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Number employees registered grouped by company
     * This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static registeredEmployees(
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customers/employees-registered-by-companies',
            query: {
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Number of customers grouped by state and periods
     * This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @param perPage per_page
     * @param page page
     * @returns any Success
     * @throws ApiError
     */
    public static customersState(
        startDate?: any,
        perPage?: any,
        page?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customers/group-by-states-and-period',
            query: {
                'start_date': startDate,
                'per_page': perPage,
                'page': page,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

}