import { Fragment } from 'react'
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { roles } from '../../types/types';

const SuperAdminGuard = ({ children }) => {
  
  const { user } =  useSelector(state => state.jwt);

  if( user && user.role !== roles.superAdmin ){
    return <Navigate to={-1} />
  }

  return <Fragment>{ children }</Fragment>
}

export { SuperAdminGuard }