function onlyUnique(value, index, self) {     
  return self.indexOf(value) === index;
}

function* generatorGr(arr = []) {
  for(let i = 0; i<arr.length; i++){
    yield arr[i]
  }
}

// WORD to Word
function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toUpperCase() : word.toLowerCase();
  }).replace(/\s+/g, '');
}

export {
  onlyUnique,
  generatorGr,
  camelize
  
}