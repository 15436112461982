/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RolesAndPermissionsService {

    /**
     * List Roles and associated Permissions.
     * List all roles, associated permissions and users with that role.
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @returns any ok
     * @throws ApiError
     */
    public static listRoles(
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/roles-permissions',
            query: {
                'per_page': perPage,
                'page': page,
                'export': _export,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Store,update and assign roles and permissions
     * Store a newly created role in storage if role not exists. Update permissions if role exists. If parameter users ids exists. Assign role to users.
     * @param requestBody Pass user credentials to login
     * @returns any ok
     * @throws ApiError
     */
    public static createUpdateRoles(
        requestBody: {
            role_name: string;
            permissions?: string;
            users_ids?: Array<any>;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/roles-permissions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Deletes a role if does not have users assigned
     * @param roleId Id of the role that need to be deleted
     * @returns any Success
     * @throws ApiError
     */
    public static deleteRole(
        roleId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/roles-permissions/{roleId}',
            path: {
                'roleId': roleId,
            },
            errors: {
                400: `Invalid ID supplied`,
                404: `Role not found`,
            },
        });
    }

}