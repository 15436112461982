import { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { Col, Container, Row } from 'react-bootstrap'

const SettingLayout = ({ children }) => {

  return (
    <Fragment>
        <Helmet title="Configuraciones"/>
        <Row>
          <Col xs="auto" className="d-none d-sm-block fs-1">
            <h3>
              Configuración
            </h3>
          </Col>
        </Row>
        <Container fluid className="p-0 mco-fade-in">
          <Row>
            <Col lg={12} >
              { children }
            </Col>
          </Row>
        </Container>
    </Fragment>
  )
}

export default SettingLayout