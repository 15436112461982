import { useRef } from "react";
import { Badge, Card, ListGroup } from "react-bootstrap"
import { MCO_CONFIGS } from "../../../../constants"



export const SegmentsTooltip = ({chartData={}, dataIndex=0, color="primary"}) => {

  const {locale} = MCO_CONFIGS;
  const tooltipCard = useRef(null);
  
  return(
    <Card 
      className="d-flex zindex-100 justify-content-center p-0 shadow-lg border border-gray mco-tooltip__width tooltip-sm mco-grinding"
      ref={tooltipCard}
      >
        <ListGroup as="ol">
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-center mco-grinding"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold d-flex align-items-center">
                {
                  chartData.labels.length > 0 &&
                  (chartData.labels[dataIndex])
                }
              </div>
            </div>
          </ListGroup.Item>
            <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-center mco-grinding"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold fs-7 d-flex align-items-center">
                Ordenes
              </div>
            </div>
            <Badge bg={color} className="fs-6" pill>
              {
                chartData.datasets[0].orders.length > 0 &&
                (parseFloat(chartData.datasets[0].orders[dataIndex])).toLocaleString(locale)
              }
            </Badge>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-center mco-grinding"
          >
            <div className="ms-4 me-auto">
              <div className="fw-bold fs-7 d-flex align-items-center">
                %
              </div>
            </div>
            <Badge bg="success" className="fs-5 fw-bold" pill>
              {
                chartData.datasets[0].percent.length > 0 &&
                (chartData.datasets[0].percent[dataIndex]).toLocaleString(locale, { maximumFractionDigits: 1, minimumFractionDigits: 1, }) + '%'
              }
            </Badge>
          </ListGroup.Item>
        </ListGroup>
    </Card>
  )
}
