import { types } from "../types/types"
import moment from "moment/min/moment-with-locales";

const initialState = {}

export const dateReducer = (state = initialState, { type, payload }) => {

  switch (type) {

  case types.last_date:
    return { 
      ...state,
      last_date_formats:{
        string_date: moment( payload.date ).locale("es").format("MMMM YY"),
        numb_date: moment( payload.date ).format("MM-YYYY"),
      }
    }
  
  case types.selected_date:
    return { 
      ...state,
      selected_date_formats:{
        string_date: moment( payload.date ).locale("es").format("MMMM YY"),
        numb_date: moment( payload.date ).format("MM-YYYY"),
      }
    }

  default:
    return state
  }
}

