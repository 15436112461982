import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export const OverlayTooltip = ({ children, description='', position="top" }) => {
  return (
    <OverlayTrigger
      placement={ position }
      transition
      overlay={
        <Tooltip id="tooltip-top">
          { description }
        </Tooltip>
      }
    >
      { children }
   </OverlayTrigger>
  )
}
