import {
  Settings,
  ShoppingCart,
  Clock,
  Calendar,
  Sliders,
  FileText,
  Truck,
  Users,
  Award
} from "react-feather";

const mcoMainSection = [
  {
    href: "/",
    title: "Scorecard",
    icon: Sliders
  },
  {
    href: "/products",
    title: "Productos",
    icon: ShoppingCart,
    children: [
      {
        href: "/products-overview/segments", 
        title: "Segmentos",
      },
      {
        href: "/products-overview/business", 
        title: "Negocios",
      },
      {
        href: "/products-sales-ranking",
        title: "Ranking de ventas", //ex Productos más vendidos
      },
    ]
  },
  {
    href: "/distributors",
    title: "Distribuidores",
    icon: Truck,
    children: [
      {
        href: "/distributors/distributors-overview",
        title: "Resumen",
      },
      {
        href: "/distributors/distributors-details",
        title: "Detalles",
      },
    ]
  },
  {
    href: "/customers",
    title: "Clientes",
    icon: Users,
    children: [
      {
        href: "/customers-overview",
        title:"Resumen",
      },
    ]
  },
  {
    href: "/benefits",
    title: "Beneficios",
    icon: Award,
    children: [
      {
        href: "/benefits/overview",
        title:"Resumen"
      },
      {
        href: "/benefits/corporatives",
        title:"Corporativos"
      },
      {
        href: "/benefits/banking-benefits",
        title:"Bancarios"
      },
      {
        href: "/benefits/massives",
        title:"Masivos"
      },
      {
        href: "/benefits/details",
        title:"Detalles"
       }
    ]
  },
  {
    href: "/reports",
    title: "Reportes",
    icon: FileText,
    children: [
      {
        href: "/reports/trade-report",
        title:"Reporte Comercial"
      },
      // {
      //   href: "/reports/buy-x-get-y",
      //   title:"Reporte compro X llevo Y"
      // },
      {
        href: "/reports/simple",
        title:"Reporte SIMPLE"
      },
      {
        href: "/reports/mix-boxes",
        title:"Cajas Surtidas"
      },
      {
        href: "/reports/users-mails",
        title: "Mailing"
      },
    ]
  }
];

const navItems = [
  {
    title: "",
    pages: mcoMainSection
  }
];

export default navItems;
