import { Row } from 'react-bootstrap';
import { Fragment, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { MCO_CONFIGS } from '../../../constants';
import { useSegments } from '../../../hooks/useSegments';
import { SegmentContext } from './layouts/SegmentLayout';
import { SuspensivePointsLoader } from '../../../components/mco/components/McoLoaders';
import McoAutoPaginatedTable from '../../../components/mco/components/McoAutoPaginatedTable';

export const SegmentsTable = ( ) => {

  const locale = MCO_CONFIGS;
  const { currentMonth } = useContext(SegmentContext);
  const { bySegmentTable, loading } = useSegments(currentMonth);
  
  const segmentsColumns = [
    
    {
      Header: "Segmento",
      accessor: "segment",
      headerClassName:"col-2",
      className: "col-2",
    },
    {
      Header: "Cantidad de Ordenes",
      Filter:false,
      accessor: "total_orders",
      headerClassName:"col-1 text-right",
      className: "col-1 text-right",
      Cell: props => (
        parseInt(props.value).toLocaleString( locale )
      )
    },
    {
      Header: "Unidades Vendidas",
      Filter:false,
      accessor: "total_units_sold",
      headerClassName:"col-1 text-right",
      className: "col-1 text-right",
      Cell: props => (
        parseInt(props.value).toLocaleString( locale )
      )
    },
    {
      Header: "% Vendidas",
      Filter:false,
      accessor: "percent_units_sold",
      headerClassName:"col-1 text-right",
      className: "col-1 text-right text-success",
      Cell: props => (
         (props.value).toLocaleString(locale, { maximumFractionDigits:1 , minimumFractionDigits: 1 }) + "%"
      )
    },
    {
      Header: "Monto Facturado",
      Filter:false,
      accessor: "total_amount",
      headerClassName:"col-1 text-right",
      className: "col-1 text-right",
      Cell: props => (
         '$ ' + parseInt(props.value).toLocaleString(locale)
      )
    },
    {
      Header: "% Facturado",
      Filter:false,
      accessor: "percent_amount",
      headerClassName:"col-1 text-right",
      className: "col-1 text-right text-success",
      Cell: props => (
         (props.value).toLocaleString(locale, { maximumFractionDigits:1 , minimumFractionDigits: 1 }) + "%"
      )
    },
    
  ];

  const processData = ( arr=[] ) => {

    const data_processed = arr.map((e) => {
      return {

        "SEGMENTO" : ( e.segment ),

        "CANTIDAD DE ORDENES" :   parseInt( e.total_orders ).toLocaleString( locale, { maximumFractionDigits:0 , minimumFractionDigits: 0 }),

        "UNIDADES VENDIDAS" :   parseInt(e.total_units_sold ).toLocaleString( locale, { maximumFractionDigits:0 , minimumFractionDigits: 0 }),

        "% VENDIDAS" :   (e.percent_units_sold ).toLocaleString( locale, { maximumFractionDigits:1 , minimumFractionDigits: 1 }) + " %",

        "MONTO FACTURADO" : parseFloat( e.total_amount ).toLocaleString(locale, { maximumFractionDigits:2 , minimumFractionDigits: 2 }),

        "% FACTURADO" : ( e.percent_amount ).toLocaleString(locale, { maximumFractionDigits:1 , minimumFractionDigits: 1 }) + " %",
      };

    });

    return data_processed;
  }

  return (
    <Fragment className="">
      <Helmet title="Resumen Segmentos"/>
      {
        loading ?
        ( <SuspensivePointsLoader title="de segmentos"/> )
        :(
            <Row>
              <McoAutoPaginatedTable
                data={bySegmentTable}
                columns={segmentsColumns}
                controlPageDown={false}
                controlPageUp={true}
                title={"Tabla de Segmentos"}
                fileName={"segmentos"}
                loading={loading}
                customPageSize={25}
                hover={true}
                dataForExport={processData(bySegmentTable)}
              />
            </Row>
        )
      }
  </Fragment>
  )
}
