import React, { createContext, useEffect, useReducer } from "react";
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter } from "react-table";
import { Card, Form } from "react-bootstrap";
import { ExportCSVButton } from "./ExportCSVButton";
import { PointLoader } from "./McoLoaders";
import { PageControl } from "../../../pages/minimalart/reports/reports-components/PageControl";
import { McoTable } from "./McoTable";
import { debounce } from "lodash";

export const McoTableContext =  createContext({});
const { Provider } = McoTableContext;

const McoPaginatedTable = ({
  data,
  loading,
  columns,
  fileName,
  fetchData,
  title = "",
  exportLoading,
  onHeaderClick, //6
  subtitle = "",
  dataForExport,
  customPageSize,
  exportable = false,
  isPaginated = true,
  pageCount: controlledPageCount,
}) => {

  const initialState={
    type: undefined,
    value: undefined
  }

  const [ state, dispatch ] = useReducer(reducer, initialState);

  const debouncedSearch = React.useRef(
    debounce(async ( target, header ) => {
      await dispatch({
        type: header,
        header: header,
        payload: {
          values: (target.value).trim()
        }
      });
    }, 600)
  ).current;

  async function handleChange( target, header ) {
    if( target.value.length >= 3 || target.value.length == 0 ){
      debouncedSearch( target, header );
    }
  }

  function reducer(state, action) {

    switch (action.type) {
      case (action.header):

        return {
          type: action.type,
          values: action.payload.values
        }
    
      default:
        return state;
    }
  }

  function DefaultColumnFilter({
    column: { setFilter, Header },
  }) {

    return (
      <Form.Control
        onChange={({ target }) => {
          handleChange( target, Header );
        }}
        onClick={(e) => e.stopPropagation()}
        placeholder={`Filtrar`}
        className="mt-2 mb-1"
      />
    );
  }

  const filterTypes = React.useMemo( () => ({
    // Or, override the default text filter to use
    // "startWith"
    text: (rows, id, filterValue) => {
      return rows.filter( row => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? String(rowValue)
          .toLowerCase()
          .startsWith(String(filterValue).toLowerCase())
        : true;
      });
    },
  }),[]);

  const defaultColumn = React.useMemo(
    () => ({
    Filter: DefaultColumnFilter,
  }),[]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex }, //3
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      defaultColumn,
      onHeaderClick, //5
      initialState: { 
        pageIndex:0,
        pageSize: 25
      },
      manualPagination: true,
      manualSortBy: true, //2
      autoResetPage: false,
      pageCount: controlledPageCount, 
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  useEffect( () => {
    fetchData && fetchData({ pageIndex, state });
  }, [ fetchData, pageIndex, state ]);

  return (
    <Provider value={{
      gotoPage,
      nextPage,
      pageIndex, 
      pageCount,
      canNextPage,
      pageOptions,
      previousPage,
      getTableProps,
      canPreviousPage,
      pageCount: controlledPageCount,
    }}>
      <Card className="mco-fade-in mt-3">
        <Card.Header className="w-100 d-flex justify-content-between align-items-center px-4 pt-4 pb-0">
          <Card.Title tag="h5" className="fs-3">{title}
            {
              loading && 
                (<PointLoader/>)
            } 
          </Card.Title>
          <ExportCSVButton data={dataForExport} fileName={fileName} loading={exportLoading}/>
        </Card.Header>
        <Card.Body>
          <PageControl loader={false} />
          <McoTable
            page={page}
            prepareRow={prepareRow}
            headerGroups={headerGroups}
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            onHeaderClick={onHeaderClick}
          />
          <PageControl loading={loading} />
        </Card.Body>
      </Card>
    </Provider>
  );
};
export default McoPaginatedTable;
