/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PromosDiscountsService {

    /**
     * Get virtual coupons list
     * This can only be done by the logged in user
     * @param orderId order_id
     * @param promoName promo_name
     * @param distId dist_id
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @param orderBy
     * @param order
     * @returns any Success
     * @throws ApiError
     */
    public static getVirtualCouponsList(
        orderId?: number,
        promoName?: string,
        distId?: string,
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
        startDate?: any,
        orderBy: 'order_id' | 'promo' | 'discount' | 'code' | 'date' | 'dist_id' = 'promo',
        order: 'DESC' | 'ASC' = 'DESC',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/discounts/virtual-coupons-list',
            query: {
                'order_id': orderId,
                'promo_name': promoName,
                'dist_id': distId,
                'per_page': perPage,
                'page': page,
                'export': _export,
                'start_date': startDate,
                'order_by': orderBy,
                'order': order,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get total coupon used and total coupon amount by periods
     * This can only be done by the logged in user
     * @param name discount or coupon name
     * @param lineType
     * @param orderBy
     * @param order
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @returns any Success
     * @throws ApiError
     */
    public static getTotalCouponsPeriod(
        name?: string,
        lineType: 'coupon' | 'cart_discount' = 'coupon',
        orderBy: 'date' | 'line_type' | 'name' | 'total_used' | 'total_amount_discounted' = 'total_amount_discounted',
        order: 'DESC' | 'ASC' = 'DESC',
        startDate?: any,
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/discounts/total-by-periods',
            query: {
                'name': name,
                'line_type': lineType,
                'order_by': orderBy,
                'order': order,
                'start_date': startDate,
                'per_page': perPage,
                'page': page,
                'export': _export,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get virtual coupons used with details
     * This can only be done by the logged in user
     * @param orderId order_id
     * @param promoName promo_name
     * @param distId dist_id
     * @param email email
     * @param name name
     * @param surname surname
     * @param city city
     * @param state state
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @param promotionIds promotion_ids
     * @returns any Success
     * @throws ApiError
     */
    public static getVirtualCouponsListWithDetails(
        orderId?: number,
        promoName?: string,
        distId?: string,
        email?: string,
        name?: string,
        surname?: string,
        city?: string,
        state?: string,
        startDate?: any,
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
        promotionIds?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/discounts/virtual-coupons-used-details',
            query: {
                'order_id': orderId,
                'promo_name': promoName,
                'dist_id': distId,
                'email': email,
                'name': name,
                'surname': surname,
                'city': city,
                'state': state,
                'start_date': startDate,
                'per_page': perPage,
                'page': page,
                'export': _export,
                'promotion_ids': promotionIds,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get Coupons and Discounts List
     * This can only be done by the logged in user
     * @param orderId order_id
     * @param discountName discount_name
     * @param distId dist_id
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static getCouponAndDiscountsList(
        orderId?: number,
        discountName?: string,
        distId?: string,
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/discounts/coupon-and-discount-lists',
            query: {
                'order_id': orderId,
                'discount_name': discountName,
                'dist_id': distId,
                'per_page': perPage,
                'page': page,
                'export': _export,
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get total units coupones used between two dates
     * This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static totalUsedCoupons(
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/discounts/total-used-coupons',
            query: {
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

}