import { Fragment } from 'react';
import { CSVLink } from "react-csv";
import { RingLoader } from './McoLoaders';
import moment from "moment/min/moment-with-locales";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

const ExportCSVButton = ({ data=[], fileName='csvFile', loading=false }) => {

  const { selected_date_formats } =  useSelector( state => state.date );
  
  const selected_month = selected_date_formats ? selected_date_formats.string_date : moment().locale("es").format("MMMM YY");

  return (


    <Fragment>
      <div className="card-actions float-end d-flex flex-column">   
        <CSVLink
          data={ data }
          asyncOnClick={true}
          filename={`${fileName}_` + selected_month + ".csv"}
          className="btn btn-info text-white p-2"
          style={{
            "pointerEvents": loading && "none",
            "cursor": loading ? "not-allowed" : "pointer",
            "backdropFilter": loading &&"blur(6px)",
            "backgroundColor": loading && "#1f9bcf85",
            "borderColor": loading && "#1f9bcf85"
          }}
          >
          {
            loading? 
            ( 
              <>
                <span className='bold'>  Generando archivo... </span>
                <RingLoader variant='white'/>
              </>

            )
            : ( 
              (
                <>
                  <span>Exportar CSV</span> 
                  <FontAwesomeIcon
                    icon={faFileDownload}
                    className="fs-2 ms-2" 
                  />
                </>
              )
            )
          }
        </CSVLink>
      </div>
    </Fragment>
  )
}

export { ExportCSVButton }