import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Col, Container, Row } from 'react-bootstrap'
import MailReportsTable from './reports-components/MailReportTable'

export const MailReport = () => {
  return (
    <React.Fragment>
      <Helmet title="E-mails de usuarios" />
      <Container fluid className="p-0 mco-fade-in">
        <Row>
          <Col lg="12">
           <MailReportsTable />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}
