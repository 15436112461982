
import { useState } from 'react';
import { MCO_CONFIGS } from '../constants';
import McoToken from '../components/McoToken';
import moment from "moment/min/moment-with-locales";
import { OpenAPI, ReportsService } from '../services/openapi';

const { getWdrReport } = ReportsService;

const useReportBuyXGetY = () => {
    const { locale } = MCO_CONFIGS;
    const [loading, setLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [wdrData, setWdrData] = useState([]);
    const [dataProcessed, setDataProcessed] = useState([]);


    const getReportWDR = async ({
        start_date,
        order_id,
        product_id,
        order_status,
        type,
        order_by,
        order,
        per_page,
        page
    }) => {

        setLoading(true);
        OpenAPI.TOKEN = McoToken();

        await getWdrReport(
            start_date,
            order_id,
            product_id,
            order_status,
            type,
            order_by,
            order,
            per_page,
            page,
        ).then((resp) => {
            setWdrData(resp.data);
            setPageCount(resp.last_page);
            setLoading(false);
        }).catch(err => {
            console.log(err)
            setWdrData([]);
            setPageCount(0);
        });

    }
    /*To expot */
    const getExportReportWDR = async ({
        start_date,
        order_id,
        product_id,
        order_status,
        type,
        order_by,
        order,
        per_page,
        page
    }) => {


        setLoading(true);
        OpenAPI.TOKEN = McoToken();

        await getWdrReport(
            start_date,
            null,
            null,
            null,
            null,
            null,
            null,
            per_page,
            page,
            1
        ).then((resp) => {

            const data_processed = resp.map((e) => {

                return {
                    "ID ORDEN": e.order_id,
                    "PRODUCTO": (e.product_name),                    
                    "CANTIDAD": e.product_qty,
                    "PRECIO DESCUENTO": e.discount_price,
                    "FECHA ORDEN": moment(e.order_date).locale("es").format("DD/MM/YYYY"),
                    "TIPO": e.type
                };
            });

            setDataProcessed(data_processed);
            setLoading(false);
        }).catch(err => {
            console.log(err)
            setWdrData([]);
            setPageCount(0);
        });

    }


    return {
        loading,
        pageCount,
        wdrData,
        exportLoading,
        dataProcessed,
        getReportWDR,
        getExportReportWDR,
    }
}

export default useReportBuyXGetY;