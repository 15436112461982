import { createContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
//import axios from "../utils/axios";
import axios from "axios";
import { types } from "../types/types";
import McoToken from "../components/McoToken";
import { AuthService } from "../services/openapi";
import moment from "moment/min/moment-with-locales";
import  useAppDispatch  from "../hooks/useAppDispatch";
import { isValidToken, setSession } from "../utils/jwt";
import { ConfigService, OpenAPI } from "../services/openapi";

const { loginApi, userLoggedData } = AuthService;
const { configList } = ConfigService;

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

export const JWTReducer = ( state = initialState, action ) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: action.payload.isInitialized,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        isAuthenticated: false,
        isInitialized: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {

  const [state] = useReducer(JWTReducer, initialState);
  const Dispatch =  useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getNewMonth = async() => {
      OpenAPI.TOKEN = McoToken();
      try {
  
        await configList()
        .then( ({ data }) => {
          const date = (data.filter( data => data.option === 'show_max_month'));
          const lastM = (date[0].values);

          Dispatch({
            type: types.last_date,
            payload:{
              date: lastM,
            }
          });

        }).catch( err => console.log(err));

      } catch (error) {
        console.log(error);
      }
    }

    getNewMonth();
  },[])
 
  useEffect(() => {
    const initialize = async () => {
      try {
       
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {

          setSession(accessToken);

          await userLoggedData()
            .then( ( response ) => {

              const {
                id,
                role,
                name,
                email,
              } = response.data;

              Dispatch({
                type: INITIALIZE,
                payload: {
                  isAuthenticated: true,
                  user:{
                    id,
                    name,
                    email,
                    role: role[0],
                    avatar: "/static/img/avatars/avatar-1.jpg"
                  },
                },
              });

            }).catch( (err) => {
              console.log(err.message);
              setSession(null);
              Dispatch({ type: SIGN_OUT });
              navigate("/auth/sign-in");
            });
        } else {
          setSession(null);
          Dispatch({ type: SIGN_OUT });
          navigate("/auth/sign-in");
        }
      } catch (err) {
        setSession(null);
        Dispatch({ type: SIGN_OUT });
        navigate("/auth/sign-in");
      }
    };

    initialize();
  }, [ Dispatch, navigate ]);

  const signIn = async (email, password) => {

      await loginApi({
        'email' : email,
        'password' : password,
        'device' : 'dashboard-web'
      }).then( ( response ) => {
        const { success } = response;
        if( success ){
          const { access_token, user_data } = response.data;
          const {
            id,
            display_name,
            email
          } = user_data;

          setSession(access_token);
          Dispatch({
            type: SIGN_IN,
            payload: {
              isAuthenticated: true,
              isInitialized: true,
              user: {
                id,
                display_name,
                email,
                //password: "password",
                avatar: "/static/img/avatars/avatar-1.jpg",
              }
            }
          });
        }else{
          // Not logged:
          setSession(null);
          Dispatch({ type: SIGN_OUT });
          navigate("/auth/sign-in");
        }
      })
      .catch( ( err ) => {
        setSession(null);
        Dispatch({ type: SIGN_OUT });
        //navigate("/auth/sign-in");
        throw new Error(err.message);
      }
    );
  };

  const signOut = async () => {
    setSession(null);
    Dispatch({ type: SIGN_OUT });
    navigate("/auth/sign-in");
  };

  const signUp = async (email, password, firstName, lastName) => {
    const response = await axios.post("/api/auth/sign-up", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    Dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const resetPassword = (email) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
