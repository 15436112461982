import { Fragment, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Container, Row, Col } from "react-bootstrap";
import DistributorsCharts from './charts/DistributorsCharts';
import useDistributorsData from '../../../hooks/useDistributorsData';
import { useSelectDate } from '../../../hooks/useSelectDate';
import DateSelector from "../../../components/mco/components/DateSelector";


export const McoDistributorsOverview = () => {

  const [ currDistributorsData, setCurrDistributorsData ] = useState([]);
  const [ prevDistributorsData, setPrevDistributorsData ] = useState([]);

  const [
		currentMonth,
		handleCurrentMonth,
		selectValues,
	] = useSelectDate();

  const {
    getAllDistributorsList,
    getAllDistributorsPrevList,
    loading,
  } = useDistributorsData();

  const getDistributorsList = (date = currentMonth) => {

    getAllDistributorsList( date )
    .then( ({ distriCurrData }) => {
      distriCurrData 
			? setCurrDistributorsData(distriCurrData)
      : setCurrDistributorsData([])
		});

    getAllDistributorsPrevList(date)
    .then( prevData => {
      prevData
      ? setPrevDistributorsData(prevData)
      : setPrevDistributorsData([])
    })
  }

  useEffect(() => {
    let Mounted = true;
    getDistributorsList();
    return () => Mounted = false;
  }, [currentMonth])

  return (
    <Fragment>
      <Helmet title="Resumen"/>
      <Container fluid className="p-0 mco-fade-in">
        <Row className="mb-2 mb-xl-3 d-flex justify-content-between mco-fade-in">
          <Col xs="6" className="d-none d-sm-block">
            <h3 className="mb-3">Resumen Distribuidores</h3>
          </Col>
          <DateSelector 
            selectValues={selectValues}
            handleCurrentMonth={handleCurrentMonth}
          />
        </Row>
          <DistributorsCharts
            currDistriData={ currDistributorsData }
            prevDistriData={ prevDistributorsData }
            currentMonth={ currentMonth }
            loading={ loading }
          />
      </Container>
    </Fragment>
  )
}

