import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";
import Select from "react-select";
import McoArgentinaMap from "../../../components/mco/components/McoArgentinaMap";
import { useSelectDate } from "../../../hooks/useSelectDate";
import McoChartPie from "../../../components/mco/components/McoChartPie";
import { CustomersService, OpenAPI } from "../../../services/openapi"; //, OpenAPI, OpenAPIConfig
import McoToken from "../../../components/McoToken";
import DateSelector from "../../../components/mco/components/DateSelector";

const McoCustomersOverview = () => {
  const { customersState } = CustomersService;
  const [ customersData, setCustomersData ] = useState( [] );
  const [ loadingData, setLoadingData ] = useState( true );

  const [
     currentMonth,
     handleCurrentMonth,
     selectValues,
  ] = useSelectDate();

  const fetchCustomers = async( month ) => {
    OpenAPI.TOKEN = McoToken();
    setLoadingData( true );
    customersState( month ).then( ({data}) => {
      setCustomersData( data );
    }).catch((error) => {
      console.log( error );
    }).finally(() => {
      setLoadingData( false );
    });
    
  }

  useEffect(() => {
    fetchCustomers( currentMonth );
  }, [ currentMonth ]);

  return (<React.Fragment>
    <Helmet title="Reportes de Clientes" />
    <Container fluid className="p-0">
      <Row className="mb-2 mb-xl-3 d-flex justify-content-between">
        <Col xs="auto" className="d-none d-sm-block">
          <h3>Resumen</h3>
        </Col>
        <DateSelector 
          selectValues={selectValues}
          handleCurrentMonth={handleCurrentMonth}
        />
      </Row>
      <Row>
        <Col lg="6" xl="6" className="d-flex">
          <McoArgentinaMap
            title="Distribución geográfica de clientes"
            subtitle=""
            data={customersData}
            loading={loadingData}
            />
        </Col>
        <Col lg="6" xl="6" className="d-flex">
          <McoChartPie
            title="Cantidad de Clientes"
            subtitle="Ranking de clientes por provincias."
            data={customersData}
            loading={loadingData}
            />
        </Col>
      </Row>
    </Container>
  </React.Fragment>
)};

export default McoCustomersOverview;
