/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportsService {

    /**
     * Show diff between business report and wc_order_stats
     * This can only be done by the logged in user
     * @param orderId
     * @param perPage per_page
     * @param page page
     * @returns any Success
     * @throws ApiError
     */
    public static diffBusinessReport(
        orderId?: any,
        perPage?: any,
        page?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reports/test/business',
            query: {
                'order_id': orderId,
                'per_page': perPage,
                'page': page,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Show diff totals between business report and wc_order_stats
     * This can only be done by the logged in user
     * @returns any Success
     * @throws ApiError
     */
    public static diffBusinessReportTotals(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reports/test/business-totals',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get users mail list report
     * This can only be done by the logged in user
     * @param role The user role/ company
     * @param name name
     * @param surname surname
     * @param email email
     * @param mainCity main city
     * @param mainState main state
     * @param allowReceiveComunications allow_receive_comunications
     * @param allowReceiveSamples allow_receive_samples
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @param orderBy Order by ASC to DESC param
     * @returns any Success
     * @throws ApiError
     */
    public static getReportUsersMailList(
        role: string,
        name?: string,
        surname?: string,
        email?: string,
        mainCity?: string,
        mainState?: string,
        allowReceiveComunications?: 0 | 1,
        allowReceiveSamples?: 0 | 1,
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
        orderBy: 'user_id' | 'name' | 'surname' | 'email' | 'role' | 'date_registered' | 'main_city' | 'main_state' | 'allow_receive_comunications' | 'allow_receive_samples' = 'date_registered',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reports/users/mail-list',
            path: {
                'role': role,
            },
            query: {
                'name': name,
                'surname': surname,
                'email': email,
                'main-city': mainCity,
                'main-state': mainState,
                'allow_receive_comunications': allowReceiveComunications,
                'allow_receive_samples': allowReceiveSamples,
                'per_page': perPage,
                'page': page,
                'export': _export,
                'orderBy': orderBy,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get simple product report
     * This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static getReportProductsSimple(
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reports/products/simple',
            query: {
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get simple product report details
     * This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static getReportProductsSimpleDetails(
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reports/products/simple/detail',
            query: {
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get distributors order product discounts and refunds
     * This can only be done by the logged in user
     * @param orderId order_id
     * @param distId dist_id
     * @param sku sku
     * @param productName product_name
     * @param type
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @param discounts discounts
     * @param orderBy
     * @param order
     * @returns any Success
     * @throws ApiError
     */
    public static getReportOrderDiscounts(
        orderId?: number,
        distId?: number,
        sku?: string,
        productName?: string,
        type: 'ORDEN' | 'REEMBOLSO' = 'ORDEN',
        startDate?: any,
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
        discounts: 0 | 1 = 0,
        orderBy: 'period' | 'order_date' | 'order_id' | 'dist_id' | 'dist_name' | 'sku' | 'product_name' | 'quantity' | 'unit_price_list' | 'unit_discount_price' | 'discount_per_unit' | 'subtotal_discounts_price' | 'subtotal_other_discounts' | 'total_discounts' | 'net_total' | 'type' = 'order_date',
        order: 'DESC' | 'ASC' = 'DESC',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reports/distributor/order-discounts',
            query: {
                'order_id': orderId,
                'dist_id': distId,
                'sku': sku,
                'product_name': productName,
                'type': type,
                'start_date': startDate,
                'per_page': perPage,
                'page': page,
                'export': _export,
                'discounts': discounts,
                'order_by': orderBy,
                'order': order,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get business report
     * This can only be done by the logged in user
     * @param orderId order_id
     * @param distId dist_id
     * @param sku sku
     * @param productName product_name
     * @param corporateName corporate_name
     * @param type
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @param discounts discounts
     * @param orderBy
     * @param order
     * @returns any Success
     * @throws ApiError
     */
    public static getReportBusiness(
        orderId?: number,
        distId?: number,
        sku?: string,
        productName?: string,
        corporateName?: string,
        type: 'ORDEN' | 'REEMBOLSO' = 'ORDEN',
        startDate?: any,
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
        discounts: 0 | 1 = 0,
        orderBy: 'period' | 'order_date' | 'order_id' | 'dist_id' | 'dist_name' | 'sku' | 'product_name' | 'quantity' | 'unit_price_list' | 'unit_discount_price' | 'discount_per_unit' | 'subtotal_discounts_price' | 'subtotal_other_discounts' | 'total_discounts' | 'net_total' | 'type' = 'order_date',
        order: 'DESC' | 'ASC' = 'DESC',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reports/business',
            query: {
                'order_id': orderId,
                'dist_id': distId,
                'sku': sku,
                'product_name': productName,
                'corporate_name': corporateName,
                'type': type,
                'start_date': startDate,
                'per_page': perPage,
                'page': page,
                'export': _export,
                'discounts': discounts,
                'order_by': orderBy,
                'order': order,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get Coupons and Discounts List Report
     * This can only be done by the logged in user
     * @param orderId order_id
     * @param discountName discount_name
     * @param promoName promo_name
     * @param distId dist_id
     * @param subGroup sub_group
     * @param orderBy
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static getReportCoupons(
        orderId?: number,
        discountName?: string,
        promoName?: string,
        distId?: string,
        subGroup?: string,
        orderBy: 'period' | 'order_id' | 'date' | 'dist_id' | 'dist_name' | 'discount_name' | 'promo_name' | 'discount_value' = 'date',
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reports/coupons',
            query: {
                'order_id': orderId,
                'discount_name': discountName,
                'promo_name': promoName,
                'dist_id': distId,
                'sub_group': subGroup,
                'order_by': orderBy,
                'per_page': perPage,
                'page': page,
                'export': _export,
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get Coupons and Discounts List grouped by group
     * This can only be done by the logged in user
     * @param group
     * @param orderBy
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static getReportCouponsGrouped(
        group: 'employees' | 'bank' | 'category-discounts' | 'massive-coupons' | 'others' = 'employees',
        orderBy: 'group' | 'discount_value' = 'group',
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reports/coupons/group',
            query: {
                'group': group,
                'order_by': orderBy,
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get Coupons and Discounts List grouped by subgroups
     * This can only be done by the logged in user
     * @param group
     * @param subGroup sub_group
     * @param orderBy
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @returns any Success
     * @throws ApiError
     */
    public static getReportCouponsSubGrouped(
        group: 'employees' | 'bank' | 'category-discounts' | 'massive-coupons' | 'others' = 'employees',
        subGroup?: string,
        orderBy: 'group' | 'sub_group' | 'discount_value' = 'sub_group',
        startDate?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reports/coupons/subgroup',
            query: {
                'group': group,
                'sub_group': subGroup,
                'order_by': orderBy,
                'start_date': startDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get Coupons and Discounts List grouped by distributors
     * This can only be done by the logged in user
     * @param distId distributor id
     * @param distName distributor name
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @param orderBy
     * @param order
     * @returns any Success
     * @throws ApiError
     */
    public static getCouponsGroupDistris(
        distId?: string,
        distName?: string,
        startDate?: any,
        orderBy: 'dist_name' | 'dist_id' | 'total-discounted' = 'total-discounted',
        order: 'DESC' | 'ASC' = 'DESC',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reports/coupons/group-by-distributors',
            query: {
                'dist_id': distId,
                'dist_name': distName,
                'start_date': startDate,
                'order_by': orderBy,
                'order': order,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get boxes mix report
     * This can only be done by the logged in user
     * @param distId distributor id
     * @param promoName promo name
     * @param status status
     * @param orderBy
     * @param order
     * @returns any Success
     * @throws ApiError
     */
    public static getBoxesMixReport(
        distId?: string,
        promoName?: string,
        status: 'FALLIDO' | 'REEMBOLSADO' | 'COMPLETADO' | 'LISTO PARA ENTREGAR' | 'EN ARMADO' | 'PROCESANDO' | 'CANCELADO' | 'PENDIENTE' | 'EN ESPERA' | 'SOLIC. CANCELACIÓN' | 'SOLIC. DEVOLUCIÓN' | 'DEVUELTO' = '',
        orderBy: 'order_id' | 'promo_name' | 'promo_discount' | 'coupon_code' | 'date' | 'dist_id' | 'dist_name' | 'status' = 'order_id',
        order: 'DESC' | 'ASC' = 'DESC',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reports/boxes-mix',
            query: {
                'dist_id': distId,
                'promo_name': promoName,
                'status': status,
                'order_by': orderBy,
                'order': order,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Get x by y reports
     * This can only be done by the logged in user
     * @param startDate Month and Year from where wants to get metrics (format Y-m)
     * @param orderId order id
     * @param productId product id
     * @param orderStatus order status
     * @param type product type
     * @param orderBy
     * @param order
     * @param perPage per_page
     * @param page page
     * @param _export export
     * @returns void
     * @throws ApiError
     */
    public static getWdrReport(
        startDate?: any,
        orderId?: any,
        productId?: any,
        orderStatus?: 'Completa' | 'Procesando',
        type?: 'ORDEN' | 'REEMBOLSO',
        orderBy: 'order_id' | 'product_id' | 'order_status' | 'type' | 'order_date' = 'order_date',
        order: 'DESC' | 'ASC' = 'DESC',
        perPage?: any,
        page?: any,
        _export: 0 | 1 = 0,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reports/wdr',
            query: {
                'start_date': startDate,
                'order_id': orderId,
                'product_id': productId,
                'order_status': orderStatus,
                'type': type,
                'order_by': orderBy,
                'order': order,
                'per_page': perPage,
                'page': page,
                'export': _export,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

}