import { Fragment, useEffect, useRef, useState } from "react";

import { Chart } from "react-chartjs-2";
import { Card, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";

import usePalette from "../../../../hooks/usePalette";
import { PointLoader } from "../../../../components/mco/components/McoLoaders";


export const BenefitsBarChart = ({ data, isLoading, benefit, color }) => {

  const barChart = useRef();

  const palette = usePalette();

  const barChartObj = barChart.current;

  const [ progress, setProgress ] = useState(6);

  const [ dataBarChart, setDataBarChart ] = useState({
    datasets: [{
      data: [],
      borderWidth: 1,
      borderRadius: 9,
      barPercentage:0.25,
      cartegoryPercentage: 0.5,
      backgroundColor: color,
    }],
    labels: []
  });

  let maxProgressBar = 0;



  useEffect(() => {

    let isMounted = true;

    if (data) {

      setDataBarChart({
        ...dataBarChart,
        datasets:[{
          ...dataBarChart.datasets[0],
          data: data.map( subGroup => (subGroup.total_discounted)),
          
        }],
        labels: data.map( subGroup => subGroup.sub_group )
      });

    }
    
    return () =>  isMounted = false;

  }, [data]);
  

  function clickScroll( direction ) {
    
    const labelsLength = barChartObj.data.labels.length;
    const scale = barChartObj.config.options.scales;
    maxProgressBar = labelsLength;
    
    
    if( direction ){
      
      scale.x.min = scale.x.min + 1;
      scale.x.max = scale.x.max + 1;
      
      if ( scale.x.max >= labelsLength ) {

        scale.x.min = labelsLength - 4;
        scale.x.max = labelsLength;
        
      }
      
    }else {
      
      scale.x.min = scale.x.min - 1;
      scale.x.max = scale.x.max - 1;
      
      if( scale.x.min <= 0 ) {

        scale.x.min = 0;
        scale.x.max = 4;

      }

    }
    
    barChartObj.update();

  }
  
  function chartScroller( scroll ) {
    scroll.preventDefault();
    const dataLength = barChartObj.data.labels.length;
    const scale = barChartObj.config.options.scales;

    if ( barChartObj && scroll.deltaY > 0 ) {

      scale.x.min += 1;
      scale.x.max += 1;

      if ( scale.x.max >= dataLength ) {

        scale.x.min = dataLength - 4;
        scale.x.max = dataLength;

      } else {

        scale.x.min += 1;
        scale.x.max += 1;

      }

    } else if( scroll.deltaY < 0 ) {

      if ( scale.x.min <= 0 ) {

        scale.x.min = 0;
        scale.x.max = 4;

      } else {

        scale.x.min -= 1;
        scale.x.max -= 1;

      }

    }

    barChartObj.update();

  }  

  // barChartObj && 
  // barChartObj.canvas.addEventListener('wheel', e => chartScroller( e ), { passive: false })

  
  const options = {
    responsive: true,
    plugins: {
      title: {
        legend:false,
        display: false,
        text: 'Cupones'
      },
      tooltip: {
        intersect: true,
        callbacks: {
          label: function(tooltipItem) {
            return (' ' + tooltipItem.label + ": " +
             "$ " + (tooltipItem.formattedValue));
          },
        },
      },
      legend: false,
    },
    scales: {
      x:{
        min: 0,
        max: 4,
        grid:{
          display:false,
          drawBorder:false
        },

      },
      y: {
        beginAtZero: true,
        
      }
    }
  }
 
  return (
    <Fragment>
      {
        <Card>
          <Card.Header>
            <Card.Title>
                { benefit } { isLoading && <PointLoader /> }
            </Card.Title>
          </Card.Header>        
          <Card.Body>
              <Chart
                type="bar"
                ref={barChart}
                options={options}
                data={dataBarChart}
              />

              <div className="d-flex justify-content-between mt-3 p-1">
                <div>
                  <FontAwesomeIcon
                    id="faChevronLeft"
                    icon={faChevronCircleLeft}
                    className="fs-3 cursor-pointer zindex-50" 
                    onClick={ (e) => {
                      clickScroll( false )
                    }} // false: izquierda 
                  />
                </div>

                <div className="w-25 mt-3">
                
                  <ProgressBar
                  min={6}
                  now={ progress }
                  max={16}
                  variant="primary"
                  style={{ height: "1em", }}                   
                  />

                </div>

                <div>
                  <FontAwesomeIcon
                    id="faChevronLeft"
                    icon={faChevronCircleRight}
                    className="fs-3 cursor-pointer zindex-50"
                    onClick={ (e) =>  {
                      clickScroll( true );
                    } } // true: derecha 
                  />
                </div>

              </div>
          </Card.Body>
        </Card>
      }
    </Fragment>
  )
}
