import thunk from 'redux-thunk'
import { configureStore } from "@reduxjs/toolkit";
import { applyMiddleware, compose } from 'redux';
import { JWTReducer } from "../contexts/JWTContext";
import { reportsReducer } from "../reducers/reportsReducer";
import { statusCronReducer } from "../reducers/statusCronReducer";
import { productsChartModeReducer } from "../reducers/productsChartModeReducer";
import { businessReducer } from '../reducers/businessReducer';
import { usersReducer } from '../reducers/usersReducer';
import { dateReducer } from '../reducers/dateReducer';
import { modalsReducer } from '../reducers/modalsReducer';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = configureStore({
  reducer: {
    jwt: JWTReducer,
    date: dateReducer,
    user: usersReducer,
    reports: reportsReducer,
    modals: modalsReducer,
    business: businessReducer,
    croneStatus: statusCronReducer,
    productsChartMode: productsChartModeReducer,
  },
},
composeEnhancers(
  applyMiddleware(thunk)
));

