import { useEffect, useRef, useState } from "react";
import { Bar } from 'react-chartjs-2';
import { Col } from 'react-bootstrap'
import { Card, Spinner } from "react-bootstrap";
import { MCO_CONFIGS } from "../../../../constants";
import usePalette from "../../../../hooks/usePalette";
import { SegmentsTooltip } from "../products-components/SegmentsTooltip";

export const SegmentsAmountBarChart = ({ bySegmentsAmount = [], loading = true }) => {
  
  const { locale } = MCO_CONFIGS;
  const palette = usePalette();
  const tooltipCard = useRef(null);
  const [ chartData, setChartData ] = useState({
    labels: [],
    datasets: [{
      data: [],
      percent: [],
      orders:[],
      fill:false,
      borderColor:palette["gray-100"],
      backgroundColor:  '#4875bf', //'#1659c7',
      borderWidth: 0,
      borderSkipped: false,
      borderRadius: 6,
      barPercentage: 0.16,
      categoryPercentage: 1.3,
      offset:true,
    }],
  });
  const [ dataIndex, setDataIndex ] = useState();
  const [ tooltipPosition, setTooltipPosition ] = useState({
      top: "0px",
      left: "0px"
  });

  useEffect(() => {
    if(bySegmentsAmount.length > 0){

      setChartData({
        ...chartData,
        labels: bySegmentsAmount.map(elm => elm.segment),
        datasets:[ 
          { 
            ...chartData.datasets[0],
            data: bySegmentsAmount.map(elm => parseFloat(elm.total_amount)),
            percent: bySegmentsAmount.map(elm => parseFloat(elm.percent_amount)),
            orders: bySegmentsAmount.map(elm => (elm.total_orders))
          } 
        ]
      });
    }
  }, [bySegmentsAmount]);
  
  //ProgressBar
  const  progressBar = {
    id:'progressBar',
    beforeDatasetsDraw(chart, args, pluginOptions){
        const { ctx, data, chartArea:{ left, right, width, height },
        scales:{ x , y} } = chart;

        ctx.save();
        const barHeight = height / y.ticks.length * chartData.datasets[0].
        barPercentage * data.datasets[0].categoryPercentage

        data.datasets[0].data.forEach((datapoint, index) => {

        //LabelText
        const fontSizeLabel = 20;
        ctx.font = ` 14px Poppins`;
        ctx.fillStyle = palette.secondary;
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.fillText(
          data.labels[index],
          left + 3,
          y.getPixelForValue(index) - fontSizeLabel
        );

        //Data Label
        const fontSizeDataPoint = 20;
        ctx.font = `bolder 14px Poppins`;
        ctx.fillStyle = palette.secondary;
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(
          '$ ' + (datapoint).toLocaleString(locale),
          right-13,
          y.getPixelForValue(index) - fontSizeDataPoint
        );

        //bg color progressbar
        ctx.beginPath();
        ctx.fillStyle = data.datasets[0].borderColor;
        ctx.fillRect(left, y.getPixelForValue(index) - (barHeight / 2), width , barHeight);
      })
    }
  }
  
  // trigger tooltip
  const externalTooltipHandler = (context) => {
    const { tooltip } = context;

    let element = tooltipCard.current;
    
    if (tooltip.opacity === 0) {
        element.style.opacity = 0;
        return;
    }
    
    // Set caret Position
    if (tooltip.yAlign) {
        setDataIndex(tooltip.dataPoints[0].dataIndex);
        element.classList.add(tooltip.yAlign);
        element.classList.remove('d-none');
    } else {
        element.classList.add('no-transform');
    }

    var position = context.chart.canvas.getBoundingClientRect();

    const { top: mouseX, left:mouseY } = tooltipPosition; 
    
    // Set Text

    element.style.pointerEvents = 'none';
    element.style.opacity = 1;
    element.style.position = 'absolute';

    //!LEFT
    element.style.left =  mouseX - Math.trunc(position.left) - 86 + 'px';
    //? TOP
    element.style.top = mouseY - 195 + 'px'; 

    element.style.padding = tooltip.padding + 'px ' + tooltip.padding + 'px';
    
  }

  const options = {
  indexAxis: 'y',
  responsive:true,
  maintainAspectRatio:false,
  animation: {
    x:{
      type:'number',
      easing:'easeInOutQuint',
      delay: (context) => {
        let delay = 0;
        if (context.type === 'data' ) {
            delay = context.dataIndex  ;
        }
        return delay;
      },
    },
    y:{
      type:'number',
      easing:'easeInOutQuint',
      duration: 0
    },
    
  },
  scales:{
    y: {
      beginAtZero: true,
      grid:{
        display:false,
        drawBorder:false
      },
      ticks: {
        display:false,
      }
    },
    x: {
      beginAtZero: true,
      grid:{
        display:false,
        drawBorder:false
      },
      ticks: {
        display:false,
      }
    },
  },
  onHover:(e, chart) => {
    if(chart.length === 1){
        e.native.target.style.cursor = 'pointer';
      }else {
        e.native.target.style.cursor = '';
      }
  },  
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      enabled: false,
      external: externalTooltipHandler,
      intersect: true,     
    },
    legend:{
      display: false
    },
    progressBar,
  }
  };
  
  return (
    <Card className="shadow-none zindex-3 mco-fade-in">
      <Card.Header>
        <Card.Title tag="h5" className="d-flex justify-content-center">
        <span className="fs-4" >
          Facturación de segmentos
        </span>
        {
          loading &&
            <Spinner
              animation="grow"
              variant="dark"
              type="grow"
              size="sm"
              className="mx-1"
            />
        }
        </Card.Title>
       
      </Card.Header>
         
      <Card.Body id="cardBody" className="overflow-auto" style={{
        "height": "76vh",
        "padding": "25px"
      }}> 
        <Col className="scrollable-element">
          <div className="dropright mco-segment-chart__height mco-segment-chart__width">    
            <>
              <Bar 
                  className="text-secondary"
                  data={chartData}
                  options={options}
                  plugins={[progressBar]}
                  onMouseMove={(e) => {
                    setTooltipPosition({
                      top: (e.pageX ),
                      left: (e.pageY)
                    })
                    tooltipCard.current.classList.remove("visually-hidden");
                  }}
                />    
            </>
          </div>
        </Col> 
      </Card.Body>

      <div ref={tooltipCard} className="visually-hidden" >
        <SegmentsTooltip 
          chartData={chartData}
          dataIndex={dataIndex}
          ref={tooltipCard}
        />
      </div>
    </Card>
  );
};



