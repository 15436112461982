import { Fragment } from 'react';

import { useTranslation } from 'react-i18next';
import moment from "moment/min/moment-with-locales";

import { MCO_CONFIGS } from '../../../../constants';
import McoAutoPaginatedTable from '../../../../components/mco/components/McoAutoPaginatedTable';
import { SuspensivePointsLoader } from '../../../../components/mco/components/McoLoaders';


const MixBoxesReportTable = ({data, loading}) => {

  const {locale}  = MCO_CONFIGS;

  const [t] = useTranslation();

  const processData = ( arr=[] ) => {

    const data_processed = arr.map((e) => {
      return {

        "ORDER ID" : ( e.order_id ),

        "FECHA" : ( e.date ),

        "DIST ID" : ( e.dist_id ),

        "DISTRIBUIDOR" : ( e.dist_name ),

        "PROMO" : ( e.promo_name ),

        "CUPON" : ( e.coupon_code ),

        "DESCUENTO" : ( e.promo_discount ),

        "ESTADO" : ( e.status ),
      };

    });

    return data_processed;
  }

  const mixBoxesColumns = [
    {
      Header: "Orden ID",
			accessor: "order_id",
			className: "col-1 text-center",
			headerClassName:"text-center",
		},
    
    {
      Header: "Fecha",
      accessor: "date",
      className: "col-1 text-center",
      headerClassName:"text-center",
      Cell: (props) => {
        return props.value? moment(props.value).format("DD/MM/YYYY") : '-';
      }
    },
    {
      Header: "Dist ID",
      accessor: "dist_id",
      className: "col-1 text-center",
      headerClassName:"text-center",
      enableColumnFilter:true,
      Cell: (props) => { 
        return props.value 
      },
    },
		{
			Header: "Distribuidor",
			accessor: "dist_name",
			className: "col-2 text-left",
			headerClassName:"text-left",
		},
    {
			Header: "Promo",
			accessor: "promo_name",
			className: "col-2 text-left",
			headerClassName:"text-left",
		},	
    {
			Header: "Cupon",
			accessor: "coupon_code",
			className: "col-2 text-left",
			headerClassName:"text-left",
      Cell: props => t(props.value)
      
		},		
		{
			Header: "Descuento",
			Filter: false,
			accessor: "promo_discount",
			className: "text-right col-1",
			headerClassName:"text-right py-4",
			Cell: (props) => {
				return "$" + ((props.value)? parseFloat( props.value ).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0 );
			}
		},
    {
			Header: "Estado",
			accessor: "status",
			className: "text-center col-1",
			headerClassName:"text-center",
		},
	
	];

  return (
      <Fragment>
        {
          loading ?
          <SuspensivePointsLoader title='cupones' />
          : (
            <McoAutoPaginatedTable 
              loading={loading}
              data={data}
              customPageSize={25}
              columns={mixBoxesColumns}
              dataForExport={processData(data)}
              fileName={"reporte_de_cajas_surtidas"}
              title={`Tabla de Cajas Surtidas`}
            />
          )
        }
      </Fragment>
  )
}

export default MixBoxesReportTable;