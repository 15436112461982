import { types } from "../types/types";

export const showFew = (qty) => ({
  type: types.few,
  payload: qty
});

export const showMedium = (qty) => ({
  type: types.medium,
  payload: qty
});

export const showMany = (qty) => ({
  type: types.many,
  payload: qty
});