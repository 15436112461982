import React, { useEffect } from "react";

import moment from "moment/min/moment-with-locales";
import { Col, Container, Row, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { MCO_CONFIGS } from "../../../../constants";
import useEmployees from "../../../../hooks/useEmployees";
import { SuspensivePointsLoader } from "../../../../components/mco/components/McoLoaders";
import McoAutoPaginatedTable from "../../../../components/mco/components/McoAutoPaginatedTable";

const McoEmployeesOrdersDetails = () => {

  const {
    exportDataOrders,
    getDataOrders,
    dataProcessed,
    orderDetails,
    isLoading
  } = useEmployees();



  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedMonth = searchParams.get('month');
  const { employee_name, role } = useParams();
  const { locale } = MCO_CONFIGS;

  const tableColumns = [
    {   
      Header: "Orden ID",
      accessor: "order_id",
      headerClassName: 'col-1 text-center',
      className: ' col-1 text-center '
    },
    {   
      Header: "Fecha",
      accessor: "date",	
      headerClassName: 'col-1 text-center',
      className: ' col-1 text-center ',
      Cell: (props) => {
        return props.value? (moment(props.value).format("DD/MM/YYYY")) : (' - ')
      }
    },
    {   
      Header: "Dist ID",
      accessor: "dist_id",
      headerClassName: 'col-1 text-center',
      className: ' col-1 text-center '
    },
    {   
      Header: "Distribuidor",
      accessor: "dist_name",
      headerClassName: 'col-2 text-left',
      className: ' col-2 text-left ',
      Cell: props => props.value? props.value : ' - '
    },
    {   
      Header: "Nombre",
      accessor: "name",		
      headerClassName: 'col-2',
      className: ' col-2  ',
    },
    {   
      Header: "Apellido",
      accessor: "surname",
      headerClassName: 'col-2',
      className: ' col-2  ',	
    },
    {
      Header: "Bruto",
      accessor: "order_subtotal",
      Filter: false,
      headerClassName: 'col-1 text-right',
      className: ' col-1 text-right ',
      Cell: (props) => {
        return (props.value ? '$ ' + Number(props.value).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0);
      }
    },		
    {
      Header: "Descuento",
      accessor: "discount",
      Filter: false,
      headerClassName: 'col-1 text-right',
      className: ' col-1 text-right ',
      Cell: (props) => {
        return (props.value ? '$ ' + Number(props.value).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0);
      }
    },
    {
      Header:   "Neto",
      accessor: "order_total",
      Filter: false,
      headerClassName: 'col-1 text-right',
      className: ' col-1 text-right ',
      Cell: (props) => {
        return (props.value ? '$ ' + Number(props.value).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0);
      }
    },
    {
      Header:   "Estado",
      accessor: "status", 
      headerClassName: 'col-1 text-center',
      className: ' col-1 text-center',
    },
  ]; 
 
  useEffect(() => {

  exportDataOrders(
    selectedMonth,
    employee_name
  );

  getDataOrders({
    date: selectedMonth,
    employee: employee_name
  });
  }, [employee_name, selectedMonth]);

  return (
    <React.Fragment>
      <h3>Detalle Beneficio Corporativo</h3>
      <Container fluid className="p-0">
      <Row className="mco-fade-in">
          <div className="ms-2 d-flex mt-3 fs-4">
            <Breadcrumb>
              <Link to={"/benefits/corporatives"} >
                Corporativos
              </Link>/
              <Breadcrumb.Item  active>Detalles descuentos</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Col lg="12">
            {
              isLoading ?
                (<SuspensivePointsLoader title="Empleados" />)
                :(
                  <McoAutoPaginatedTable
                  data={orderDetails}
                  customPageSize={25}
                  loading={isLoading}
                  columns={tableColumns}
                  dataForExport={dataProcessed}
                  title={`Detalle descuentos empleados ${role}`}
                  fileName={`Compras_de_${employee_name}`}
                  />
                )
            }
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
};

export default McoEmployeesOrdersDetails;
