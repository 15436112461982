import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { amountChart, unitsChart } from "../../../../actions/products";

const VerticalSwitcher = ({ iconUp=faDollarSign, iconDown=faDollarSign}) => {

  const [ switchValue, setSwitchValue ] = useState(false);
  const switchMode = useRef(null);
  const dispatch = useDispatch();
  const { productsChartMode:{ chartMode } } = useSelector(state => state);

  useEffect(() => {
   if(switchValue){
    dispatch(unitsChart());
   }else{
    dispatch(amountChart());
   }
  }, [switchValue])
  
  return (
    <Col sm={2} className='bg-white mx-auto shadow-lg py-5 rounded-pill mco-switch mco-switch__width mco-switch__height'
    ref={switchMode}
    >

      <OverlayTrigger
        placement="top"
        transition
        overlay={
          <Tooltip id="tooltip-top">
            Modo Facturacion
          </Tooltip>
        }
      > 
        <span>
          <FontAwesomeIcon 
            icon={iconUp}
            className={`${chartMode === 'Amount' ? "text-success fs-4 mb-4 mt-0" : "fs-4 mb-4 mt-0"}`}
          />
        </span>
      </OverlayTrigger>

      <Form.Check
        type="switch"
        id="exampleCustomSwitch"
        name="customSwitch"
        className='mco-transformed mco-switch__icons--size'
        onChange={() => setSwitchValue(!switchValue)}
        value={switchValue}
      />

      <OverlayTrigger
        placement="bottom"
        transition
        overlay={
          <Tooltip id="tooltip-top">
            Modo Unidades
          </Tooltip>
        }
      >
        <div>
          <FontAwesomeIcon
            icon={iconDown}
            className={`${chartMode === 'Units' ? "text-primary fs-4 mt-3 mb-0" : "fs-4 mt-3 mb-0"}`}
          />   
        </div>
      </OverlayTrigger>

    </Col>
  )
}

export default VerticalSwitcher