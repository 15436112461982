import { faSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { MCO_CONFIGS } from "../../../../constants";


const OrdersTable = ({currentData = []}) => {

  const { locale } = MCO_CONFIGS;

  return (
    <Col sm={6} md={6} lg={7}>
      <Card.Body style={{
        "paddingLeft": "0px",
        "marginRight": "1em",
      }}>
        {
          <div className="align-self-center">
            <Table className="mco-fade-in fs-4" responsive >
              <thead className="">
                <tr>
                  <th className="">Estados</th>
                  <OverlayTrigger
                    placement="top"
                    transition
                    overlay={
                      <Tooltip id="tooltip-top">
                        Monto total por orden
                      </Tooltip>
                    }
                  >
                  <th className="text-end">Totales</th>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    transition
                    overlay={
                      <Tooltip id="tooltip-top">
                        Cantidad de ordenes por estados
                      </Tooltip>
                    }
                  >
                  <th className="text-end">Cant.</th>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    transition
                    overlay={
                      <Tooltip id="tooltip-top">
                        Procentajes sobre el total de ordenes
                      </Tooltip>
                    }
                  >
                  <th className="text-end">%</th>
                  </OverlayTrigger>
                </tr>
              </thead>
              <tbody className="py-6">
                {
                  currentData.map(( order, i) => (

                    <tr key={i} >

                      <td className="p-3">
                        <FontAwesomeIcon icon={faSquare} style={{'color':`${order.color}`}} />
                        {' '}
                        <span>{order.label}</span>
                      </td>

                      <td className="text-end">
                        {
                          "$ " + (parseInt(order.amount)).toLocaleString(locale)
                        }
                      </td>

                      <td className="text-end">
                        {
                          order.orders
                        }
                      </td>

                      <td className="text-end px-1 text-success">
                        {
                          order.percent.toLocaleString(locale,
                          {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1
                          }) + "%"
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </div>
        }
      </Card.Body>
    </Col>
  )
}

export default OrdersTable