
import { useContext } from "react";

import { faSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  Table,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

import { BenefitsContext } from "../layout/BenefitsLayout";
import { SuspensivePointsLoader } from "../../../../components/mco/components/McoLoaders";


const BenefitsTable = ({ data, loading, mode = "Amount" }) => {

  const { locale } = useContext(BenefitsContext);


  return (

      <Card.Body>
        {
          !loading ?
          (
            <div className="align-self-center">
              {
                data.length && 
                (
                  <Table responsive className="mco-fade-in" >
                    <thead className="">
                      <tr>

                        <th className="">Grupo</th>

                        <th className="">Total Descontado</th>

                        <OverlayTrigger
                          placement="top"
                          transition
                          overlay={
                            <Tooltip id="tooltip-top">
                              Cantidad de descuentos aplicados
                            </Tooltip>
                          }
                        >
                        <th className="text-end">Cant.</th>
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="top"
                          transition
                          overlay={
                            <Tooltip id="tooltip-top">
                              Procentajes sobre el total de descuentos
                            </Tooltip>
                          }
                        >
                        <th className="text-end">%</th>
                        </OverlayTrigger>

                      </tr>
                    </thead>
                    <tbody className="py-6">
                      {
                        data[0].groups.map(( order, i) => (

                          <tr key={i} >

                            <td className="p-3">
                              <FontAwesomeIcon icon={faSquare} style={{'color':`${order.color}`}} />
                              {' '}
                              <span>{ order.group }</span>
                            </td>

                            <td className="text-end">
                              {
                                "$ " + ( parseInt( order.total_discounted )).toLocaleString(locale)
                              }
                            </td>

                            <td className="text-end">
                              {  order.cant }
                            </td>

                            <td className="text-end px-1 text-success">
                              {

                                order.percent.toLocaleString(locale,
                                {
                                  minimumFractionDigits: 1,
                                  maximumFractionDigits: 1
                                }) + "%"

                              }
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                )
              }
            </div>

          ):(  <SuspensivePointsLoader /> )
        }
      </Card.Body>

  )
}

export default BenefitsTable