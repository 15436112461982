import { useCallback, useEffect, useState } from 'react'
import McoToken from '../components/McoToken';
import { OpenAPI, OrdersService } from '../services/openapi'
import { getPercent } from '../components/mco/helpers/getPercent';

const { ordersBySegments } = OrdersService;

export const useSegments = (date) => {

  const [ bySegment, setBySegment ] = useState([]);
  const [ bySegmentTable, setBySegmentTable ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [canvasHeight, setCanvasHeight] = useState({
    height: "170vh"
  });

  const segmentsList = async (date, allView = false) => {
    OpenAPI.TOKEN = McoToken();
    await ordersBySegments(
      date
    ).then(({data}) => {
      
      if( data ){

        let dataBySegments = [];
        let segments = [];

        
        //Filtrado y Ordenamiento en forma decreciente por monto facturado
        let sortSegments =  data.filter( segments =>  parseFloat(segments["total-item-amount"]))
        .sort( (segmentA, segmentB) => parseFloat(segmentB["total-item-amount"]) - parseFloat(segmentA["total-item-amount"]) );
        
        const TOTAL_ORDERS = sortSegments
        .map( orders => parseFloat( orders["total-orders"] ))
        .reduce((acc, curr) => acc + curr, 0);

        const TOTAL_UNITS_SOLD = sortSegments
        .map( units => parseFloat( units["total-units-sold"] ))
        .reduce((acc, curr) => acc + curr, 0);

        const TOTAL_AMOUNT = sortSegments
        .map( amounts => parseFloat( amounts["total-item-amount"] ))
        .reduce((acc, curr) => acc + curr, 0);

        sortSegments.forEach( e => {
          if(e.segment) {
            !segments.includes(e.segment) && segments.push(e.segment);
            segments.forEach( segment => {
              if(!dataBySegments[segment]){
                dataBySegments[segment] = {
                  segment: segment,
                  total_amount: parseFloat(e['total-item-amount']),
                  total_orders: parseInt(e['total-orders']) || 0,
                  total_units_sold: parseInt(e["total-units-sold"]),
                  percent_amount: getPercent( parseFloat(e['total-item-amount']),TOTAL_AMOUNT ),
                  percent_units_sold: getPercent( parseFloat(e['total-units-sold']),TOTAL_UNITS_SOLD ),
                }
              }
            })
          }else {
            return;
          }
        });
        
        let segmentsRequests = [];

        if(Object.values(dataBySegments) && Object.values(dataBySegments).length > 0){

          segmentsRequests = Object.values(dataBySegments).slice(0,20);
          
          setCanvasHeight({
            height: "150vh"
          });
          setBySegmentTable(Object.values(dataBySegments))
          setBySegment(segmentsRequests);
          setLoading(false);
        }else {
          setBySegment([]);
          setBySegmentTable([]);
          setLoading(false);
        }
      }else{
        setBySegment([]);
        setLoading(false);
      }
    }).catch( err => console.log(err))
  } 

  const getSegments = useCallback(
  segmentsList,
  []);

  useEffect(() => {
   getSegments(date);
  }, [date])
  

  return {
    bySegmentTable,
    canvasHeight,
    bySegment,
    loading
  }
}
