/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Token } from '../models/Token';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * Register a new user
     * This can only be done by the logged in user
     * @param requestBody New user data
     * @returns any Success
     * @throws ApiError
     */
    public static registerUser(
        requestBody: {
            name: string;
            email: string;
            password: string;
            c_password: string;
        },
    ): CancelablePromise<{
        success?: boolean;
        data?: Array<User>;
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
                404: `not found`,
            },
        });
    }

    /**
     * Login
     * @param requestBody Pass user credentials to login
     * @returns any Success
     * @throws ApiError
     */
    public static loginApi(
        requestBody: {
            email: string;
            password: string;
            device?: string;
        },
    ): CancelablePromise<{
        success?: boolean;
        data?: Array<Token>;
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Refresh a token.
     * @returns any Token refreshed successfully
     * @throws ApiError
     */
    public static refreshToken(): CancelablePromise<{
        success?: boolean;
        data?: Array<Token>;
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/refresh',
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Log the user out (Invalidate the token).
     * @returns any Log User Auth and close session.
     * @throws ApiError
     */
    public static logOut(): CancelablePromise<{
        success?: boolean;
        data?: string;
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/logout',
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Show the authenticated User data.
     * @returns any Authenticated User Data
     * @throws ApiError
     */
    public static userLoggedData(): CancelablePromise<{
        success?: boolean;
        data?: Array<User>;
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/me',
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

}