import { Fragment, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Container, Row, Col } from "react-bootstrap";
import DiscountsCharts from './charts/DiscountsCharts';
import { OpenAPI, PromosDiscountsService } from "../../../services/openapi";
import Select from "react-select";
import moment from "moment/min/moment-with-locales";
import { useSelectDate } from '../../../hooks/useSelectDate';
import McoToken from "../../../components/McoToken";
import McoDiscountsMoreUsedTableRender from './McoDiscountsMoreUsedTableRender';

export const McoDiscountsOverview = () => {

const [ currentMonth,handleCurrentMonth,selectValues ] = useSelectDate();
const [ loading, setLoading ] = useState(true);
const { getTotalCouponsPeriod } = PromosDiscountsService;
const [ dataState, setDataState ] = useState();

  const getDiscountsFromDB = async ( currentMonth ) => {
    OpenAPI.TOKEN = McoToken();
    try{
      const discounts = await getTotalCouponsPeriod(
        undefined, 
        'coupon',
        'total_used',
        'DESC',
        moment(currentMonth).startOf('month').format('YYYY-MM-DD'),
        10000 // toDate
      );
      return discounts;
    }catch (error){
      console.log(error);
      //throw new Error(error);
    }
  };

  useEffect(() => {

    (async () => {
      setLoading(true);
      await getDiscountsFromDB( currentMonth )
      .then( ({ data }) => {
      if( data ){
        setDataState( data );
      }else {
        return;
      }
      })
      .catch(error => console.log(error))
      .finally(() => setLoading(false));
    })();

  }, [ currentMonth ]);


  return (
    <Fragment>
      <Helmet title="Resumen"/>
      <Container fluid className="p-0 mco-fade-in">
        <Row className="mb-2 mb-xl-3 d-flex justify-content-between">
          <Col xs="auto" className="d-none d-sm-block">
            <h3 className="mb-3">Resumen Descuentos</h3>
          </Col>
          <Col xs="6" mb="3" md="2" className="mb-3" >
            <Select
              onChange={handleCurrentMonth}
              defaultValue={selectValues[0]}
              className="react-select-container text-capitalize"
              classNamePrefix="react-select"
              options={selectValues}
            />
          </Col>
        </Row>
        <Row className="mb-2 mb-xl-3 d-flex">
          <Col className='col-xl-6 col-lg-6'>
            <DiscountsCharts dataState={ dataState } loading={ loading } />
          </Col>
        
          <Col className='col-xl-6 col-lg-6'>
            <McoDiscountsMoreUsedTableRender currentMonth={currentMonth}/>
          </Col>
        </Row>
        <Row className="mb-2 mb-xl-3  ">
          <Col>
             {/* <McoDiscountsTableRender currentMonth={currentMonth}/>   */}
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
 
