import { useContext } from 'react';
import { Col, Form, Pagination, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { showFew, showMany, showMedium } from '../../../../actions/reports';
import { PointLoader } from '../../../../components/mco/components/McoLoaders';
import { McoTableContext } from '../../../../components/mco/components/McoPaginatedTable';
export { 
  showFew,
  showMedium,
  showMany
 } from '../../../../actions/reports';

export const PageControl = ({ loader = true, loading }) => {
  //loader: true | false = true
  
  const {
    gotoPage,
    nextPage,
    pageIndex,
    pageCount,
    canNextPage,
    previousPage,
    canPreviousPage,
  } = useContext(McoTableContext);
  
  const dispatch = useDispatch();
  const { show } = useSelector( state => state.reports );

  return (
    <Row>
      <Col md="6">
        <span className="mx-2">
          Página{" "}
          <strong>
            { pageIndex + 1 }{' '}
             de { pageCount } {/* start on 0.. */}
          </strong>
        </span>
        <span className="ms-3 me-2">Mostrar:</span>
        <Form.Select
          className="d-inline-block w-auto"
          value={show}
          onChange={(e) => {
            const page = Number( e.target.value );
            gotoPage( pageIndex );
            page === 25?
              (dispatch(showFew(25)))
            : page === 50?
              (dispatch(showMedium(50)))
            : page === 100 &&
              (dispatch(showMany(100)))
          }}
        >
          {[25, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Form.Select>
 

        <span className="ms-3 me-2">Ir a la página:</span>
        <Form.Control
          className="d-inline-block me-1"
          type="number"
          name="toPage:"
          min={1}
          max={ pageCount }
          value={pageIndex <=0 ? 1 : pageIndex }
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value)  : 0;
            gotoPage(page);
          }}
          style={{ width: "75px" }}
        />
        {
          loader &&
            loading && 
              (<PointLoader/>)
        } 
      </Col>
      <Col md="6">
        <Pagination className="float-end">
          <Pagination.First
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          />
          <Pagination.Prev
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          />
          <Pagination.Next
            onClick={() => nextPage()}
            disabled={!canNextPage}
          />
          <Pagination.Last
            onClick={() => gotoPage(pageCount-1)}
            disabled={!canNextPage}
          />
        </Pagination>
      </Col>
    </Row>
  )
}
 
