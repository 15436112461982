import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";
import { Col, Container, Row, Breadcrumb } from "react-bootstrap";
import {  Link, useParams, useSearchParams } from 'react-router-dom';

import { BenefitsContext } from "../layout/BenefitsLayout";
import { camelize } from "../../../../components/mco/helpers/helperFunctions";
import { SuspensivePointsLoader } from "../../../../components/mco/components/McoLoaders";
import McoAutoPaginatedTable from "../../../../components/mco/components/McoAutoPaginatedTable";

const paths = {
  "MASIVOS": "/benefits/massives",
  "BANCARIOS": "/benefits/banking-benefits"
}

const BenefitsOrdersTable = () => {

  const { locale, getCoupons, couponsData, loading } = useContext(BenefitsContext)


  const [searchParams, setSearchParams] = useSearchParams();
  const selectedMonth = searchParams.get('month');
  const { subGroup } = useParams();
  const [t] = useTranslation()
  
  useEffect(() => {
    let isMounted = true;

    getCoupons( selectedMonth, subGroup )

    return () => isMounted = false;
    
  }, [ subGroup, selectedMonth ]);


  const tableColumns = [
    {   
      Header: "Orden ID",
      accessor: "order_id",
      headerClassName: 'col-1 text-center',
      className: ' col-1 text-center '
    },
    {   
      Header: "Fecha",
      accessor: "date",	
      headerClassName: 'col-1 text-center',
      className: ' col-1 text-center ',
      Cell: (props) => {
        return props.value? (moment(props.value).format("DD/MM/YYYY")) : (' - ')
      }
    },
    {   
      Header: "Dist ID",
      accessor: "dist_id",
      headerClassName: 'col-1 text-center',
      className: ' col-1 text-center '
    },
    {   
      Header: "Distribuidor",
      accessor: "dist_name",
      headerClassName: 'col-2 text-left',
      className: ' col-2 text-left ',
      Cell: props => props.value? props.value : ' - '
    },
    {   
      Header: "Nombre",
      accessor: "firstname",		
      headerClassName: 'col-1',
      className: ' col-1',
    },
    {   
      Header: "Apellido",
      accessor: "surname",
      headerClassName: 'col-1',
      className: ' col-1',	
    },
    {
      Header:   "Promo",
      accessor: "promo_name", 
      headerClassName: 'col-1 text-left',
      className: ' col-1 text-left',
    },
    {
      Header:   "Cupon/Desc",
      accessor: "discount_name", 
      headerClassName: 'col-1 text-left',
      className: ' col-1 text-left',
    },
    {
      Header: "Bruto",
      accessor: "order_subtotal",
      Filter: false,
      headerClassName: 'col-1 text-right',
      className: ' col-1 text-right ',
      Cell: (props) => {
        return (props.value ? '$ ' + Number(props.value).toLocaleString(locale, { maximumFractionDigits: 1, minimumFractionDigits: 1 }) : 0);
      }
    },		
    {
      Header: "Desc",
      accessor: "total_discounted", // total descontado 
      Filter: false,
      headerClassName: 'col-1 text-right',
      className: ' col-1 text-right ',
      Cell: (props) => {
        return (props.value ? '$ ' + Number(props.value).toLocaleString(locale, { maximumFractionDigits: 1, minimumFractionDigits: 1 }) : 0);
      }
    },
    {
      Header:   "Neto",
      accessor: "order_total",
      Filter: false,
      headerClassName: 'col-1 text-right',
      className: ' col-1 text-right ',
      Cell: (props) => {
        return (props.value ? '$ ' + Number(props.value).toLocaleString(locale, { maximumFractionDigits: 1, minimumFractionDigits: 1 }) : 0);
      }
    },
  ]; 

  const data_processed =  couponsData && couponsData.map((e) => {
    return {

      "ORDER ID" : ( e.order_id ),

      "FECHA" : ( e.date ),

      "DIST ID" : ( e.dist_id ),

      "DISTRIBUIDOR" : ( e.dist_name ),

      "NOMBRE" : ( e.firstname ),

      "APELLIDO" : ( e.surname ),

      "PROMO" : ( e.promo_name ),

      "CUPON/DESC" : ( e.discount_name ),

      "BRUTO" : ( e.order_subtotal ),

      "DESC" : ( e.total_discounted ),

      "NETO" : ( e.order_total ),
    };

  });

  return (
    <React.Fragment>
      <Container fluid className="p-0">
      <Row className="mco-fade-in">
        {
          loading ?
          (<SuspensivePointsLoader title="Cupones" />)
          :(
            <>
              <div className="ms-2 d-flex mt-3 fs-4">
                <Breadcrumb>
                  <Link to={couponsData.length && paths[t(couponsData[0].group)] } >
                  { couponsData.length &&  camelize(t(couponsData[0].group))  }
                  </Link> /
                  <Breadcrumb.Item  active> Detalle descuentos </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <Col lg="12">
                <McoAutoPaginatedTable
                data={couponsData}
                customPageSize={25}
                loading={loading}
                columns={tableColumns}
                dataForExport={data_processed}
                title={`Detalles descuento ${subGroup}`}
                fileName={`detalle_de_descuento_${subGroup}`}
                />
              </Col>
            </>
          )
        }
        </Row>
      </Container>
    </React.Fragment>
  )
};

export default BenefitsOrdersTable;


