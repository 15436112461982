import moment from 'moment/min/moment-with-locales';

const subtractMonthString = (month) => {

  if (month) {

    const currMonthName = (moment(month).locale("es").format('MMMM'));

    let prevMonthName = (moment(month)
    .subtract(1, 'months').locale("es")
    .format('MMMM'));

    let subtract = moment(month).subtract(1, 'months');
    let prevDate = moment(subtract).format('YYYY-MM');


    return{
      prevDate,
      prevMonthName,
      currMonthName
    }
  }

}

export default subtractMonthString;