import { Fragment } from 'react'

import { BenefitsCharts } from './benefits-components/BenefitsCharts';


export const BenefitsOverview = () => {
  return (
    <Fragment>
      <BenefitsCharts />
    </Fragment>
  )
}
