import { Col } from "react-bootstrap"
import Select from "react-select"

const DateSelector = ({ selectValues, handleCurrentMonth }) => {

  
  return (
    <Col xs="6" mb="3" md="2" className="mb-3" >
      {
        selectValues[0].value !== undefined &&
          <Select
            onChange={handleCurrentMonth}
            defaultValue={selectValues[0]}
            className="react-select-container text-capitalize"
            classNamePrefix="react-select"
            options={selectValues}
          />

      }
    </Col>
  )
}

export default DateSelector