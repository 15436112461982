import { types } from "../types/types"

const initialState = {
  isOpen: false
}

export const modalsReducer = (state = initialState, { type, payload }) => {

  switch (type) {

  case types.modalClose:
    return initialState;

  case types.modalOpen:
    return {
      isOpen: true,
      data: payload.data
    }

  default:
    return state
  }
}
